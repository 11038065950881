import React, { Component } from "react";
import Table from "../../../components/table/Table";
import { Navigate, Routes } from "react-router-dom";
import moment from "moment";
import { saveAs } from "file-saver";
import SwalServices from "../../../services/swalServices/SwalServices";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import DropdownAction from "../../../components/dropdown/DropdownAction";
import MembershipTaskListServices from "../../../services/axiosServices/apiServices/MembershipTaskListServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MembershipTaskListProcess from "./MembershipTaskListProcess";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import BookingServices from "../../../services/axiosServices/apiServices/BookingServices";
import {
  CommonValidationMessages,
  CommonSuccessMessages,
  SuccessMessage,
} from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import SendForApprove from "./SendApproveModal";
import SendPaymentLink from "./SendPaymentLink";
import SendQuotation from "./SendQuotation";
import SendInvoice from "./SendInvoice";
import EscalateModal from "./EscalateModal";
import SendClarifications from "./SendClarifications";
import {
  yearDifference,
  //, monthDifference
} from "../../../utils/DateUtils";
import { encryptAES } from "../../../utils/Encryption";
import Selector from "../../../components/dropdown/commonDropdown/Selector";


export default class MembershipTaskList extends Component {
  constructor(props) {
    super(props);
    this.TaskListServices = new MembershipTaskListServices();
    this.BookingServices = new BookingServices();
    this.corporateMemberServices = new CorporateMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      historyLogDetail: [],
      isValidStartUpMember: true,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        dateFrom: "",
        dateTo: "",
        filterByStatus: "",
        filterByTransactionType: 0
      },
      totalResultes: "",
      taskList: [],
      isLoading: false,
      isLoadingPayment: false,
      isLoadingReject: false,
      isLoadingApprove: false,
      isLoadingApproveRenew: false,

      isLoadingClarification: false,
      isLoadingSendMail1: false,
      isLoadingSendMail2: false,
      route: null,
      redirect: null,
      clarification: '',
      //Dropdown Arrays Start
      membershipType: [],
      membershipRevenue: [],
      countries: [],
      serviceType: [],
      industriesServed: [],
      associationBenefits: [],
      getToKnow: [],
      associationBenefits: [],
      mrMrs: [],
      designation: [],
      years: [],
      emailCirculars: [],
      servicesTypeData: [],
      industriesServedData: [],
      billingCategories: [],
      associationBenefitsData: [],
      ordinaryEndorsers: [],
      allEndorsers: [],
      ordinaryRepresentative: [],
      allRepresentative: [],
      transactionTypes: [],
      //Dropdown Arrays End

      numberOfChapters: [
        { id: 0, name: "0" },
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" },
        { id: 5, name: "5" },
      ],
      statusList: [
        {
          value: 1,
          label: "Draft",
        },
        {
          value: 2,
          label: "Submitted",
        },
        {
          value: 3,
          label: "Sent For Clarification",
        },
        {
          value: 4,
          label: "Sent For Endorsement",
        },
        {
          value: 5,
          label: "Endorsed",
        },
        {
          value: 6,
          label: "Sent For Payment",
        },
        {
          value: 7,
          label: "Approved",
        },
      ],
      taskDetails: {
        expiryDate: "",
        approvalDate: "",
        taskId: 0,
        memberId: 0,
        contactRoleId: 0,
        billingCategoryId: 0,
        subscriptionStartDate: null,
        subscriptionEndDate: null,
        membershipTaskStatusId: 0,
        transactionTypeId: 0,
        approvalDate: null,
        isEndorsed: false,
        isCouncilApproved: false,

        membershipTypeId: 0,
        organisationName: "",
        organisationUEN: "",
        incorporationDate: null,
        paidUpCapital: 0,
        servicesTypeId: 0,
        industriesServedId: 0,
        associationBenefitsId: 0,
        generalEmailAddress: "",
        generalBusinessTelephone: null,
        statusId: 0,
        endorsersId1: 0,
        endorsersId2: 0,
        representativeId1: 0,
        representativeId2: 0,
        isRejected: 0,
        designation: '',
        firstName: '',
        lastName: '',
        entranceFee: 0,
        annualFee: 0,
        includedChapter: 0,
        additionalChapterPrice: 0,
        subtotalPrice: 0,
        gstPrice: 0,
        discountedPrice: 0,
        totalPrice: 0,
        paymentsCreditsApplied: 0,
        netTotal: 0,
        membersReceiptId: 0,
        subscriptionFee: 0,
        membershipPaymentTypeId: 0,
        transactionId: "",
        voucherCode: "",
        membershipPaymentInvoicePath: '',
        endorsementSatusForEndorser1: 0,
        endorsementSatusForEndorser2: 0,

        committeeApprovalDate: null,
        endorsersId1Name : "",
        endorsersId2Name : "",

        //Reinstatement Field
        reinstatementRequestDate: "",
        reinstatementAmount: 0,
        reinstatementEndDate: 0,
        reinstatementStartDate: 0
      },
      // historyLogDetail: {
      //   taskId: 0,
      //   endorsersRepresentativeId: 0,
      //   companyName: '',
      //   representativeName: '',
      //   endorsementStatus: '',
      //   endorserCategory: '',
      //   issuedDate: null,
      //   endorserComment: '',
      // },
      setSendClarifications: false,
      setDropdownOpen: false,
      membershipTaskListProcessModal: false,
      setAddeditModalOpen: false,
      setPaymentLink: false,
      sendQuotation: false,
      sendInvoice: false,
      sendEscalation: false,
      sendReject: false,
      basePriceTable: [],
      allowableDiscountTable: [],
      percentageDiscountTable: [],
      systemCalculatedTable: [],
      disablePaymentButton: false,
      disableSubscription: true,
      sendEscalate: {
        memberId: 0,
        firstName: "",
        lastName: "",
        organisationName: "",
        organisationUEN: "",
        generalEmailAddress: "",
        generalBusinessTelephone: 0,
        internalNotes: "",
      },
      filterOrdinaryEndorsersList :[],
      filterAllEndorsers:[],
      validationRule: {
        membershipTypeId: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Membership Type'),
          },
        ],

        //Organisation Profile Validation Rules
        organisationName: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Organisation Name'),
          },
        ],
        organisationUEN: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Organisation UEN'),
          },
        ],
        incorporationDate: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Incorporation Date'),
          },
        ],
        subscriptionStartDate: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Commencement Date'),
          },
        ],
        subscriptionEndDate: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Commencement Date'),
          },
        ],
        paidUpCapital: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'Paid Up Capital'),
          },
        ],
        // mailingCountryId: [
        //   {
        //     type: 'require',
        //     message: Messages.CommonValidationMessages.SelectRequired.replace('{0}', 'Country of Mailing Address'),
        //   },
        // ],

        //Other Information Validation Rules
        servicesTypeData: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Types of Services Your Organisation Offers'),
          },
        ],
        industriesServedData: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Types of Industries Your Organisation Serves'),
          },
        ],
        associationBenefitsId: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Your Organisation Benefits from Joining The Association'),
          },
        ],

        //Endorsers
        endorsersId1: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Original Member Endorser'),
          },
        ],
        endorsersId2: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Endorser Company'),
          },
        ],
        representativeId1: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Representative'),
          },
        ],
        representativeId2: [
          {
            type: 'require',
            message: CommonValidationMessages.SelectRequired.replace('{0}', 'Representative'),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
      actionDropdown: ["Process"],
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.getAllDropdownsForTaskList();
    this.getAllDropdownsForEndorsers();
  }

  handleChangeMultiDropdown(selected, identity) {
    if (identity === "ServicesType") {
      const maxSelections = 3;
      const limitedSelections = selected.slice(0, maxSelections);
      this.setState({
        servicesTypeData: limitedSelections
      });
    } else if (identity === "IndustriesServed") {
      const maxSelections = 3;
      const limitedSelections = selected.slice(0, maxSelections);
      this.setState({
        industriesServedData: limitedSelections
      });
    }
    else if (identity === "AssociationBenefits") {
      const maxSelections = 3;
      const limitedSelections = selected.slice(0, maxSelections);
      this.setState({
        associationBenefitsData: limitedSelections
      });
    }
  }

  handleChangecontactinformation = (index, fieldName, value) => {
    const { taskDetails } = this.state;
    let updatedContactInfo = [...taskDetails.contactInformationDetail];
    updatedContactInfo[index] = {
      ...updatedContactInfo[index],
      [fieldName]: value
    };
    this.setState({
      taskDetails: {
        ...taskDetails,
        contactInformationDetail: updatedContactInfo
      }
    });
  };

  getAllTaskList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.TaskListServices.getAllTaskList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.registrationDate = moment(key.registrationDate).format(
            "DD MMM YYYY"
          );
          if (key.generalEmailAddress === null || key.generalEmailAddress === "") {
            key.generalEmailAddress = "-";
          }
          if (key.organisationName === null || key.organisationName === "") {
            key.organisationName = "-";
          }
          if (key.organisationUEN === null || key.organisationUEN === "") {
            key.organisationUEN = "-";
          }
          // if (key.registrationDate === null || key.registrationDate === "") {
          //   key.registrationDate = "-";
          // }
          return key;
        });
        this.setState({ taskList: details });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllDropdownsForTaskList = () => {
    this.TaskListServices.getAllDropdownsForTaskList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let membershipType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipType");

        let membershipRevenue = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MembershipRevenue");

        let countries = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Countries");

        let serviceType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ServicesType");
        serviceType = serviceType.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let associationBenefits = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AssociationBenefits");
        associationBenefits = associationBenefits.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let industriesServed = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "IndustriesServed");
        industriesServed = industriesServed.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        let getToKnow = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CorporateGetToKnowUs");

        // let associationBenefits = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AssociationBenefits");

        let mrMrs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MrMrs");

        let designation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Designation");

        let years = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Years");

        let emailCirculars = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EmailSubscription");

        let billingCategories = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "BillingCategory");
        let transactionTypes = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "TransactionType");
        // membershipArr.map(function (item) {
        //   delete item["dropdownName"];
        //   return item;
        // });
        // transactionArr.map(function (item) {
        //   delete item["dropdownName"]; return item;
        // });
        // billingCategoryArr.map(function (item) {
        //   delete item["dropdownName"]; return item;
        // });
        // membershipRevenueArr.map(function (item) {
        //   delete item["dropdownName"]; return item;
        // });
        // sourceArr.map(function (item) {
        //   delete item["dropdownName"]; return item;
        // });
        // userArr.map(function (item) {
        //   delete item["dropdownName"]; return item;
        // });
        this.setState(
          {
            membershipType: membershipType,
            membershipRevenue: membershipRevenue,
            countries: countries,
            serviceType: serviceType,
            industriesServed: industriesServed,
            getToKnow: getToKnow,
            associationBenefits: associationBenefits,
            mrMrs: mrMrs,
            designation: designation,
            years: years,
            emailCirculars: emailCirculars,
            billingCategories: billingCategories,
            transactionTypes: transactionTypes,
          },
          () => this.getAllTaskList()
        );
      } else {
        this.swalServices.Error(response.message);
        this.getAllTaskList();
      }
      this.setState({ isLoading: false });
    });
  };

  getAllDropdownsForEndorsers(id) {
    this.corporateMemberServices.getAllDropdownsForEndorsers().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let ordinaryEndorsers = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AllEndorsers" || drp.dropdownName === "OrdinaryEndorsers");
        this.setState({ ordinaryEndorsers: ordinaryEndorsers });
        this.setState({ filterOrdinaryEndorsersList: ordinaryEndorsers });
      }
      if (response.statusCode === 200 && response.responseItem != null) {
        let allEndorsers = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AllEndorsers" || drp.dropdownName === "OrdinaryEndorsers");
        allEndorsers = allEndorsers.filter((drp) => drp.id !== id);
        this.setState({ allEndorsers: allEndorsers });
        this.setState({ filterAllEndorsers: allEndorsers });
      }
    });
  }

  getRepresentativesById = (id, identity) => {
    this.corporateMemberServices.getRepresentativesById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.length > 0) {
        if (identity === "OrdinaryMembers") {
          let ordinaryRepresentative = response.responseItem.responseContent
          this.setState({ ordinaryRepresentative: ordinaryRepresentative });
        }
        if (identity === "AllMembers") {
          let allRepresentative = response.responseItem.responseContent
          this.setState({ allRepresentative: allRepresentative }
          );
        }
      } else {
        if (identity === "OrdinaryMembers") {
          this.setState({ ordinaryRepresentative: [] });
        }
        if (identity === "AllMembers") {
          this.setState({ allRepresentative: [] });
        }
      }
    });
  }

  getContactInformationDetailById = (id) => {
    if (id > 0 && id != null) {
      let request = [id]
      this.corporateMemberServices.getContactInformationDetailById(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          let taskDetails = this.state.taskDetails;
          taskDetails.contactInformationDetail = details
          this.setState({ taskDetails: taskDetails });
        }
        else {
          this.swalServices.Error(response.message);
        }

        this.setState({ isLoading: false });
      })
    }
  }


  generatePaymentInvoice = () => {
    let request = { ...this.state.taskDetails }
    this.corporateMemberServices.generatePaymentInvoice(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let taskDetails = response.responseItem.responseContent;

        // Merge new values with existing taskDetails
        // let taskDetails = {
        //   ...this.state.taskDetails,
        //   membershipPaymentInvoicePath: details.membershipPaymentInvoicePath
        // };
        // Update taskDetails in state
        this.setState({ taskDetails: taskDetails }, () => {
          this.addMembershipPaymentData();
        });
        this.getAllTaskList();
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  getEndorsersEmailDetailById = (issendemail, ids) => {

    // if (ids === 1 && this.state.taskDetails.endorsementSatusForEndorser1 === 'Sent') {
    //   this.swalServices.Error("Email is Already Sent");
    //   return;
    // } else if (ids === 2 && this.state.taskDetails.endorsementSatusForEndorser2 === 'Sent') {
    //   this.swalServices.Error("Email is Already Sent");
    //   return;
    // }

    let requestData = {
      memberId: this.state.taskDetails.memberId,
      isSendEmail: true,
      typeId: ids,
      endorserId: 0,
      representativeId: 0,
    };

    if (requestData.memberId > 0 && requestData.memberId != null) {
      let detail = this.state.taskDetails;
      detail.isSendEmail = true;
      this.setState({ taskDetails: detail });

      if (issendemail === true && ids === 1) {
        requestData.endorserId = this.state.taskDetails.endorsersId1;
        requestData.representativeId = this.state.taskDetails.representativeId1;
        this.setState({ isLoadingSendMail1: true });
      } else if (issendemail === true && ids === 2) {
        requestData.endorserId = this.state.taskDetails.endorsersId2;
        requestData.representativeId = this.state.taskDetails.representativeId2;
        this.setState({ isLoadingSendMail2: true });
      }

      this.corporateMemberServices.getEndorsersEmailDetailById(requestData).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          let taskDetails = this.state.taskDetails;
          taskDetails.endorsementSatusForEndorser1 = response.responseItem.responseContent.endorsementSatusForEndorser1;
          taskDetails.endorsementSatusForEndorser2 = response.responseItem.responseContent.endorsementSatusForEndorser2;
          this.setState({ taskDetails: taskDetails }, () => {
            this.getEndorserHistoryLogDetailsByTaskId();
          });
        }
        else {
          this.swalServices.Error(response.message);
        }
        if (issendemail === true, ids === 1) {
          this.swalServices.Success("Email Sent Successfully to Endorser");
          this.setState({ isLoadingSendMail1: false })
        } else if (issendemail === true, ids === 2) {
          this.swalServices.Success("Email Sent Successfully to Endorser");
          this.setState({ isLoadingSendMail2: false })
        }
      })
    }
  }

  refreshTable = () => {
    // Fetch data again or perform any action to refresh the table
  }

  rejectApplicationForMember = () => {
    let id = this.state.taskDetails.memberId;
    let TaskId = this.state.taskDetails.taskId;
    if (id > 0 && id != null) {
      let detail = this.state.taskDetails;

      detail.isRejected = true;
      let isRejected = true;
      this.setState({ taskDetails: detail })

      let request = { ...this.state.taskDetails }
      request.id = this.state.taskDetails.memberId;
      request.isRejected = isRejected
      request.taskId = this.state.taskDetails.taskId;
      request.isAppliedForRenewal = this.state.taskDetails.isAppliedForRenewal


      // let request = [id, isRejected, TaskId]
      this.setState({ isLoadingReject: true });

      this.TaskListServices.rejectApplicationForMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ sendReject: true }, () =>
            this.setState({ setAddeditModalOpen: false })
          );
          window.location.reload();
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingReject: true });
      })
    }
  }

  getTaskByCompanyId = (companyId) => {
    this.TaskListServices.getTaskByCompanyId(companyId).then((response) => {


      let desiredId = response.responseItem.responseContent.membershipTypeId;
      let foundIndex = this.state.membershipType.find(
        (item) => item.id === desiredId
      ) ? this.state.membershipType.find(
        (item) => item.id === desiredId
      ).id : undefined;

      if (desiredId === foundIndex) {
        if (response.statusCode === 200 && response.responseItem != null) {
          response.responseItem.responseContent.transactionTypeId =
            response.responseItem.responseContent.transactionTypeId === null
              ? 1
              : response.responseItem.responseContent.transactionTypeId;

          this.setState(
            { taskDetails: response.responseItem.responseContent },
            () => {
              let data = { ...this.state.taskDetails };
              let date = data.subscriptionStartDate != null ? moment(data.subscriptionStartDate).format("YYYY-MM-DD") : null;
              data.subscriptionStartDate = date;
              let date1 = data.expiryDate !== '1900-01-01T00:00:00' ? moment(data.expiryDate).format("YYYY-MM-DD") : null;
              data.expiryDate = date1;
              if (this.state.taskDetails.industriesServedId && this.state.taskDetails.industriesServedId !== 0 && this.state.taskDetails.industriesServedId !== "0") {
                var array = (this.state.taskDetails.industriesServedId !== null || this.state.taskDetails.industriesServedId !== "") ? this.state.taskDetails.industriesServedId.split(',') : [];
                let industriesServedArray = this.state.industriesServedData;
                const filteredArray = array.filter(value => value !== "0");
                if (filteredArray && filteredArray.length > 0) {
                  filteredArray.forEach(topic => {
                    industriesServedArray.push(this.state.industriesServed.find(topics => topics.value === Number(topic)));
                  });
                }
                this.setState({ industriesServedData: industriesServedArray })
              }

              if (this.state.taskDetails.servicesTypeId && this.state.taskDetails.servicesTypeId !== 0 && this.state.taskDetails.servicesTypeId !== "0") {
                var array = (this.state.taskDetails.servicesTypeId !== null || this.state.taskDetails.servicesTypeId !== "") ? this.state.taskDetails.servicesTypeId.split(',') : [];
                let serviceTypeArray = this.state.servicesTypeData;
                const filteredArray = array.filter(value => value !== "0");
                if (filteredArray && filteredArray.length > 0) {
                  filteredArray.forEach(topic => {
                    serviceTypeArray.push(this.state.serviceType.find(topics => topics.value === Number(topic)));
                  });
                }
                this.setState({ servicesTypeData: serviceTypeArray })
              }
              if (this.state.taskDetails.associationBenefitsId && this.state.taskDetails.associationBenefitsId !== 0 && this.state.taskDetails.associationBenefitsId !== "0") {
                var array = (this.state.taskDetails.associationBenefitsId !== null || this.state.taskDetails.associationBenefitsId !== "") ? this.state.taskDetails.associationBenefitsId.split(',') : [];
                let associationBenefitsDataArray = this.state.associationBenefitsData;
                const filteredArray = array.filter(value => value !== "0");
                if (filteredArray && filteredArray.length > 0) {
                  filteredArray.forEach(topic => {
                    associationBenefitsDataArray.push(this.state.associationBenefits.find(topics => topics.value === Number(topic)));
                  });
                }
                this.setState({ associationBenefitsData: associationBenefitsDataArray })
              }
              this.setState({ taskDetails: data });
              this.getEndorserHistoryLogDetailsByTaskId();
              this.getContactInformationDetailById(data.memberId)
              // this.getRepresentativesById(data.endorsersId1, "OrdinaryMembers");
              // this.getRepresentativesById(data.endorsersId2, "AllMembers");
             

              let updatedTaskDetails = { ...this.state.taskDetails };

              // Set Representatives

              // Process Endorsers
              const processEndorser = (endorserId, endorserType, stateKey) => {
                if (endorserId) {
                  this.getRepresentativesById(endorserId, endorserType);
                  let companies = endorserType === "OrdinaryMembers" ? this.state.ordinaryEndorsers : this.state.allEndorsers;
                  let company = companies.find(x => x.id === endorserId);
                  
                  updatedTaskDetails[`${stateKey}Name`] = company?.name || "";
                  updatedTaskDetails[stateKey] = endorserId;
                }
              };

              processEndorser(data.endorsersId1, "OrdinaryMembers", "endorsersId1");
              processEndorser(data.endorsersId2, "AllMembers", "endorsersId2");

              this.setState({ taskDetails: updatedTaskDetails });


            },
            () => this.setState({ validState: { isValid: true, error: {} } })
          );
          // let details = this.state.taskDetails;
          // details.numberOfChapter= response.responseItem.responseContent.additionalChapter;
          // this.setState({taskDetails : details});
          this.setState({ setAddeditModalOpen: true });
        } else {
          this.swalServices.Error(response.message);
        }
      } else {
        this.swalServices.Error(
          "This membership is currently a non-member. Please update membership type before processing application."
        );
      }

      this.setState({ isLoading: false });
    });
  };

  getEndorserHistoryLogDetailsByTaskId = () => {
    let request = this.state.taskDetails.taskId;
    this.corporateMemberServices.getEndorserHistoryLogDetailsByTaskId(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let historyLogDetail = response.responseItem.responseContent
        this.setState({ historyLogDetail: historyLogDetail });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  setSendClarificationsFlag = () => {
    this.setState({ setSendClarifications: true });
  }

  handleChangeClarificationDetails = (data) => {
    if (typeof data === "string") {
      this.setState({
        clarification: data,
      });
    }
  };

  saveClarificationDetails = () => {
    this.setState({ setSendClarifications: true });
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_SendClarifications.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      ).then((response) => {
        if (response) {
          // let request = {
          //   MemberId: this.state.taskDetails.memberId,
          //   clarificationDetails: this.state.clarification,
          //   // statusId: this.state.SLOTSApplicationDetails.statusId,
          //   firstName: this.state.taskDetails.contactInformationDetail[0].firstName,
          //   lastName: this.state.taskDetails.contactInformationDetail[0].lastName,
          // };

          let request = { ...this.state.taskDetails }
          request.subscriptionStartDate = this.state.taskDetails.subscriptionStartDate === "Invalid date" ? moment(new Date()) : this.state.taskDetails.subscriptionStartDate
          request.isRejected = this.state.taskDetails.isRejected === undefined ? 0 : this.state.taskDetails.isRejected
          request.clarificationDetails = this.state.clarification

          this.setState({ isLoadingClarification: true });
          this.TaskListServices.saveClarificationDetails(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.setState({ setSendClarifications: false });
              this.setState({ clarification: "" });
              this.setState({ sendEscalation: true }, () =>
                this.setState({ setAddeditModalOpen: false })
              );
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoadingClarification: false });
          });
        } else {
          this.setState({ isLoading: false });
          this.setState({ setSendClarifications: false });
        }
      });
  };

  // sendClarificationMailToMember = () => {
  //   var request = this.state.sendEscalate;
  //   request.firstName = this.state.taskDetails.contactInformationDetail.firstName;
  //   request.lastName = this.state.taskDetails.contactInformationDetail.lastName;
  //   request.companyName = this.state.taskDetails.organisationName;
  //   request.companyUEN = this.state.taskDetails.organisationUEN;
  //   request.generalEmailAddress = this.state.taskDetails.generalEmailAddress;
  //   request.contactNo = 12;
  //   request.internalNotes = "Internal Notes";
  //   this.setState({ isLoadingClarification: true });

  //   this.TaskListServices.sendClarificationMailToMember(request).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ sendEscalation: true }, () =>
  //         this.setState({ setAddeditModalOpen: false })
  //       );
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isLoadingClarification: false });
  //   });
  // };

  sendPaymentLink = () => {
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Payment.replace("{0}", "Payment Link"),
        CommonSuccessMessages.Send_Payment_Link.replace("{0}", "Payment Link"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        let resp = response;
        if (resp === true) {
          if (this.state.taskDetails.transactionTypeId === 5) {
            this.addEditReinstatementPaymentDataAndSendPaymentLink();
          } else {
            this.addEditMembershipTaskAndSendPaymentLink();
          }
        }
      });
  };

  addMembershipPaymentData = () => {
    let request = { ...this.state.taskDetails };
    request.corporateId = this.state.taskDetails.memberId
    request.taskId = this.state.taskDetails.taskId;
    request.finalCalculatedPriceId = this.state.taskDetails.finalCalculatedPriceId;
    request.entranceFee = this.state.taskDetails.entranceFee;
    request.subscriptionFee = this.state.taskDetails.subscriptionFee;
    request.subTotalAmount = this.state.taskDetails.subtotalPrice;
    request.gSTAmount = this.state.taskDetails.gstPrice;
    request.discountedAmount = this.state.taskDetails.discountedPrice;
    request.totalAmount = this.state.taskDetails.totalPrice;
    request.invoicePath = this.state.taskDetails.invoicePath;
    request.paymentVia = this.state.taskDetails.paymentVia;
    request.paymentStatus = this.state.taskDetails.paymentStatus;

    request.paymentsCreditsApplied = this.state.taskDetails.paymentsCreditsApplied;
    request.transactionId = this.state.taskDetails.transactionId;
    request.membershipPaymentTypeId = this.state.taskDetails.membershipPaymentTypeId;
    request.voucherCode = this.state.taskDetails.voucherCode;

    request.gstRate = this.state.taskDetails.gstRate;
    // let found = this.state.paymentsMethods.find(x => x.id == this.state.pricingTable.paymentMethodId);
    // if (found && (request.totalAmount !== 0 && request.totalAmount !== 0.00 && request.totalAmount !== "0" && request.totalAmount != "0.00")) {
    //   request.paymentVia = found.name;
    // }
    // else {
    //   request.paymentVia = '';
    // }

    request.paymentStatus = 'Pending';
    request.membershipTenureId = 1;
    request.createdAt = new Date();
    request.createdBy = this.state.corporateMemberId;
    request.createdAppId = 115;
    request.paymentDescription = "New membership payment";
    request.membersReceiptId = this.state.taskDetails.membersReceiptId;
    request.membershipPaymentInvoicePath = this.state.taskDetails.membershipPaymentInvoicePath ? this.state.taskDetails.membershipPaymentInvoicePath : 'invoicepath';

    this.TaskListServices.addMembershipPaymentData(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let taskDetails = response.responseItem.responseContent;
        this.setState({ taskDetails: taskDetails })
      } else {
        this.swalServices.Error(response.message);
        this.setState({ isProceedPayment: false });
      }
      this.setState({ isLoading: false });
    })
  }

  addEditTask = () => {
    let request = { ...this.state.taskDetails }
    this.TaskListServices.addEditTask(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ setAddeditModalOpen: false }, () =>
          this.getAllTaskList()
        );
        this.swalServices.Success("Details Add Successfully..")
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingApprove: false });
    });
  }

  approveRenewMembershipRequest = (
    bookingId,
    corporateId,
    paymentCategoryId,
    financeAdhocInvoiceId
  ) => {

    this.setState({ isLoadingApproveRenew: true });
    if (paymentCategoryId === 3) {
      let acceptTotalMembershipRequest = {};
      if (this.state.taskDetails.approvalDate === undefined || this.state.taskDetails.approvalDate === "" || this.state.taskDetails.approvalDate === null) {
        let currentDate = moment(new Date()).format("yyyy-MM-DD");
        acceptTotalMembershipRequest.approvalDate = currentDate;
      }
      else {
        acceptTotalMembershipRequest.approvalDate = this.state.taskDetails.approvalDate
      }
      acceptTotalMembershipRequest.MemberId = corporateId;
      acceptTotalMembershipRequest.membershipPaymentTypeId = paymentCategoryId;
      acceptTotalMembershipRequest.membershipPaymentId = bookingId;

      this.BookingServices.approveAndUpdatePaymentHistoryForRenewMembershipPayment(acceptTotalMembershipRequest).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            this.setState({ setpaymentApprovalModalOpen: false });
            this.swalServices.Success(
              "Renewal Application Approved successfully."
            );
            this.setState({ route: "/Membership/MembershipRenewalDashboard" })
            this.setState({ isLoadingApproveRenew: false });
          }
        } else {
          this.swalServices.Error(response.message);
        }

      });
    }
  };

  addEditMembershipTaskAndSendPaymentLink = () => {
    if (this.isValidateAllFields) {
      var pricingList = [];
      var request = { ...this.state.taskDetails, ...pricingList };
      request.taskId = this.state.taskDetails.taskId;
      request.memberId = this.state.taskDetails.memberId;
      request.contactRoleId = this.state.taskDetails.contactRoleId;
      request.IsApprovalRequired = true;
      request.IsPaymentLinkSent = false;
      request.generalEmailAddress = request.generalEmailAddress
      request.billingCategoryId = request.billingCategoryId ? request.billingCategoryId : 1
      request.statusId = 9
      request.contactInformationDetail = this.state.taskDetails.contactInformationDetail
      request.membershipPaymentInvoicePath = this.state.taskDetails.membershipPaymentInvoicePath;

      //start end date conditions
      var currentDate = new Date();
      request.subscriptionEndDate = request.transactionTypeId === 3 ? new Date(currentDate.getFullYear() + 1, 11, 32) : new Date(currentDate.getFullYear(), 11, 32);
      request.subscriptionEndDate.setHours(0, 0, 0, 0);
      this.setState({ isLoadingApprove: true });

      request.contactInformationDetail = this.state.taskDetails.contactInformationDetail
      request.membershipPaymentInvoicePath = this.state.taskDetails.membershipPaymentInvoicePath;
      request.subscriptionStartDate = this.state.taskDetails.approvalDate;

      if (this.state.taskDetails.approvalDate === undefined || this.state.taskDetails.approvalDate === "" || this.state.taskDetails.approvalDate === null) {
        let currentDate = new Date();
        request.approvalDate = currentDate;
        request.subscriptionStartDate = currentDate;
      }

      if (this.state.taskDetails.committeeApprovalDate === undefined || this.state.taskDetails.committeeApprovalDate === "" || this.state.taskDetails.committeeApprovalDate === null) {
        let currentDate = new Date();
        request.committeeApprovalDate = currentDate;
      }

      this.setState({ isLoadingApprove: true });
      this.TaskListServices.addEditMembershipTaskAndSendPaymentLink(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let id = response.responseItem.responseContent.keyId
          let taskDetails = this.state.taskDetails
          // taskDetails.taskId = id
          this.setState({ taskDetails: taskDetails }, () => {
            this.generatePaymentInvoice();
          })
          this.setState({ setPaymentLink: true }, () =>
            this.setState({ setAddeditModalOpen: false })
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingApprove: false });
      });
    }
    // }
  };

  addEditReinstatementPaymentDataAndSendPaymentLink = () => {
    if (this.isValidateAllFields) {
      var pricingList = [];
      var request = { ...this.state.taskDetails, ...pricingList };
      request.taskId = this.state.taskDetails.taskId;
      request.memberId = this.state.taskDetails.memberId;
      request.contactRoleId = this.state.taskDetails.contactRoleId;
      request.IsApprovalRequired = true;
      request.IsPaymentLinkSent = false;
      request.generalEmailAddress = request.generalEmailAddress
      request.billingCategoryId = request.billingCategoryId ? request.billingCategoryId : 1
      request.statusId = 9
      request.contactInformationDetail = this.state.taskDetails.contactInformationDetail
      request.membershipPaymentInvoicePath = this.state.taskDetails.membershipPaymentInvoicePath;

      request.reinstatementStartDate = this.state.taskDetails.reinstatementStartDate;
      request.reinstatementEndDate = this.state.taskDetails.reinstatementEndDate;

      if (this.state.taskDetails.committeeApprovalDate === undefined || this.state.taskDetails.committeeApprovalDate === "" || this.state.taskDetails.committeeApprovalDate === null) {
        let currentDate = new Date();
        request.committeeApprovalDate = currentDate;
      }

      this.setState({ isLoadingApprove: true });
      this.TaskListServices.addEditReinstatementPaymentDataAndSendPaymentLink(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let id = response.responseItem.responseContent.keyId
          let taskDetails = this.state.taskDetails
          this.setState({ taskDetails: taskDetails });
          this.setState({ setPaymentLink: true }, () =>
            this.setState({ setAddeditModalOpen: false }),
            this.getAllTaskList()
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoadingApprove: false });
      });
    }
    // }
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.taskDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllTaskList(newPagination);
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.taskDetails;
    if (drpIdentity === "MembershipType") {
      detail["membershipTypeId"] = id;
      this.validateField("membershipTypeId");
    }
    if (drpIdentity === "BillingCategories") {
      detail["billingCategoryId"] = id;
      //this.validateField("billingCategoryId");
    }
    if (drpIdentity === "AssociationBenefits") {
      detail["associationBenefitsId"] = id;
      this.validateField("associationBenefitsId");
    }
    //Endorsers
    if (drpIdentity === "OrdinaryEndorsers") {
      detail["endorsersId1"] = id;
      this.getAllDropdownsForEndorsers(id);
      this.getRepresentativesById(id, "OrdinaryMembers");
      this.validateField("endorsersId1");
    }
    if (drpIdentity === "AllEndorsers") {

      detail["endorsersId2"] = id;
      this.getRepresentativesById(id, "AllMembers");
      this.validateField("endorsersId2");
    }
    if (drpIdentity === "OrdinaryRepresentatives") {
      let ordinaryRepresentative1 = this.state.ordinaryRepresentative.find(rep => rep.id === id);
      detail["representativeId1"] = ordinaryRepresentative1 ? ordinaryRepresentative1.id : null;
      this.validateField("representativeId1");
    }
    if (drpIdentity === "AllRepresentatives") {
      let allRepresentative1 = this.state.allRepresentative.find(rep => rep.id === id);
      detail["representativeId2"] = allRepresentative1 ? allRepresentative1.id : null;
      this.validateField("representativeId2");
    }
    this.setState({ taskDetails: detail });
  };

  setFilterParametersForDesignation = (index, fieldName, id, drpIdentity) => {
    const { taskDetails } = this.state;
    let updatedContactInfo = [...taskDetails.contactInformationDetail];

    if (updatedContactInfo[index]) {
      updatedContactInfo[index] = {
        ...updatedContactInfo[index],
        [fieldName]: id
      };

      // if (drpIdentity === "DesignationFirst") {
      //   let detail = this.state.taskDetails;
      //   detail["designationId"] = id;
      // }
      // if (drpIdentity === "DesignationCEO") {
      //   let detail = this.state.taskDetails;
      //   detail["designationId"] = id;
      // }
      this.setState({
        taskDetails: {
          ...taskDetails,
          contactInformationDetail: updatedContactInfo
        }
      });
    }
  };


  handleChangeDropdown = (index, fieldName, value) => {
    const { taskDetails } = this.state;
    let updatedContactInfo = [...taskDetails.contactInformationDetail];
    updatedContactInfo[index] = {
      ...updatedContactInfo[index],
      [fieldName]: value
    };

    this.setState({
      taskDetails: {
        ...taskDetails,
        contactInformationDetail: updatedContactInfo
      }
    });
  };

  setFilterParametersForList = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "ApplicationStatus") {
      detail["filterByStatus"] = id;
    }
    this.setState({ pagination: { ...detail } });
    this.getAllTaskList();
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    this.setState({ pagination: { ...detail } });
    if (value.length > 2) {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllTaskList();
  };

  handleChangeForPagination(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      if (startLimit < date && date < endLimit) {
        this.getAllTaskList();
      } else if (fulldate === null) {
        this.getAllTaskList();
      }
    });
  }


  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.taskDetails;
    detail[name] = value;

    this.setState({ taskDetails: { ...detail } }, () => {
      if (name === "incorporationDate") {
        let newtenureArr = [];
        if (!isNaN(Date.parse(this.state.taskDetails.incorporationDate))) {
          let sDate = new Date(this.state.taskDetails.incorporationDate);
          let eDate = new Date();
          let yearDifference = eDate.getFullYear() - sDate.getFullYear();

          if (
            this.state.taskDetails.isAppliedForRenewal === true &&
            yearDifference < 5
          ) {
            let eDate = new Date(this.state.taskDetails.subscriptionEndDate);
            let currentDate = new Date();
            let renewYearDiff = eDate.getFullYear() - currentDate.getFullYear();

            for (let i = 1; i <= 5 - yearDifference - renewYearDiff; i++) {
              let tenure = {
                id: i,
                name: `${i} Year`,
              };
              newtenureArr.push(tenure);
            }
            this.setState({ tenureList: newtenureArr }, () =>
              this.getPriceTabelForTaskListProcess()
            );
          } else {
            for (let i = 1; i <= 5 - yearDifference; i++) {
              let tenure = {
                id: i,
                name: `${i} Year`,
              };
              newtenureArr.push(tenure);
            }
            this.setState({ tenureList: newtenureArr }, () =>
              this.getPriceTabelForTaskListProcess()
            );
          }
        } else {
          this.setState({ tenureList: this.state.storeBasetenureList }, () =>
            this.getPriceTabelForTaskListProcess()
          );
        }
      }

    })

    // this.setState({ taskDetails: { ...detail } }, () => {
    //   // Your existing logic for other fields...

    //   // Check if the changed field is 'approvalDate'
    //   if (name === "approvalDate") {
    //     // Create a request object with only the 'approvalDate' field
    //     let request = {
    //       approvalDate: value
    //     };

    //     // Call the API with the updated 'approvalDate' field
    //     this.TaskListServices.addEditMembershipTaskAndSendPaymentLink(request)
    //       .then((response) => {
    //         if (response.statusCode === 200 && response.responseItem != null) {
    //           console.log("Approval date updated successfully");
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Error updating approval date:", error);
    //       });
    //   }
    // });
  }

  onDropDownChange = (index) => {
    this.setState({ setDropdownOpen: true });
    this.setState({ setDropdownIndex: index });
  };

  selectTask = (value, e) => {
    e.stopPropagation();
    if (value > 0) {
      this.getTaskByCompanyId(value);
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }

  actionClick = (index, value, option, event) => {
    if (option === "Process") {
      var id = this.state.taskList[index].taskId;
      this.setState(
        { selectedTaskId: this.state.taskList[index].taskId },
        () => {
          this.selectTask(id, event);
        }
      );
    } else if (option === "Delete") {
      //this.deleteTaskListDetailsByTaskId(value);
    }
  };

  deleteTaskListDetailsByTaskId = (companyId) => {
    this.TaskListServices.deleteTaskListDetailsByTaskId(companyId).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Membership Task List Entry Deleted Successfully."
          );
          let resetpag = {
            pageNo: 1,
            pageSize: 10,
            orderByColumn: "",
            orderFlag: 0,
            searchText: "",
            status: 0,
            dateFrom: null,
            dateTo: null,
            filterByStatus: 0,
          };
          this.setState({ pagination: resetpag }, () => {
            this.getAllTaskList();
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  actions = (element, index, value) => {
    return element !== "memberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            value={value}
            id={index}
            index={index}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  onProcessModalClose = (e) => {
    e.stopPropagation();
    this.setState({ setAddeditModalOpen: false });
  };

  updateCorporateMemberUpdate = (value) => {
    if (value && value > 0) {
      const encryptedValue = encryptAES(value);
      const newUrl = '/CreateCorporateMembers/CorporateMemberId?=' + encryptedValue;
      window.open(newUrl, '_blank');
    }
  }

  handleChangeInDropdown = (selected, drpIdentity) => {
    let page = this.state.pagination;
    if (drpIdentity === "ApplicationStatus") {
      if (selected.value === 0) {
        page.filterByStatus = "";
      } else {
        page.filterByStatus = selected.label;
      }
    }
    if (drpIdentity === "TransactionType") {
      if (selected === 0) {
        page.filterByTransactionType = 0;
      } else {
        page.filterByTransactionType = selected;
      }
    }
    this.setState({ pagination: page });
    this.getAllTaskList();
  };

  getAllDetailsForGeneratePDF = (memberId) => {
    let request = [];
    request.push(memberId);
    this.TaskListServices.getAllDetailsForGeneratePDF(request).then((response) => {
      if (response && response.status === 200) {
        let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
        let filename = `MembershipInformation-${formattedDate}.pdf`;
        let file = new Blob([response.data], { type: "application/pdf" });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
        saveAs(file, filename);
      } else {
        this.swalServices.Error(response.message);
      }
    }).catch((error) => {
      this.swalServices.Error("Failed to fetch PDF");
    });
  }



  handleChangeForSelector = (e, identity) => {
    if (identity === 'OrdinaryEndorsers') {
      let taskDetails = { ...this.state.taskDetails };
      taskDetails.endorsersId1Name= e.target.value;
      let organisationList = taskDetails.endorsersId1Name && taskDetails.endorsersId1Name !== '' ?
      this.state.ordinaryEndorsers.filter(x => x.name.toUpperCase().indexOf(taskDetails.endorsersId1Name.toUpperCase()) > -1)
      : this.state.ordinaryEndorsers;
      this.setState({ taskDetails: taskDetails, filterOrdinaryEndorsersList: organisationList });
    }
    if (identity === 'AllEndorsers') {
      let taskDetails = { ...this.state.taskDetails };
      taskDetails.endorsersId2Name= e.target.value;
      let organisationList = taskDetails.endorsersId2Name && taskDetails.endorsersId2Name !== '' ?
      this.state.allEndorsers.filter(x => x.name.toUpperCase().indexOf(taskDetails.endorsersId2Name.toUpperCase()) > -1)
      : this.state.allEndorsers;
      this.setState({ taskDetails: taskDetails, filterAllEndorsers: organisationList });
    }
  }
  
  
  setFilterParametersForSelector = (id, identity, name) => {
    
    if(identity ==="OrdinaryEndorsers"){
      
      if (id > 0) {
        this.getRepresentativesById(id, "OrdinaryMembers");
        let taskDetails = { ...this.state.taskDetails };
        let companies = [...this.state.ordinaryEndorsers];
        let companyName = companies.find(x => x.id === id);
        
        taskDetails.endorsersId1Name = companyName.name;
        taskDetails.endorsersId1 = id;
        this.setState({taskDetails : taskDetails})
      }
      
    }
    
    
    if(identity ==="AllEndorsers"){
      
      if (id > 0) {
        this.getRepresentativesById(id, "AllMembers");
        let taskDetails = { ...this.state.taskDetails };
        let endorsers = [...this.state.allEndorsers];
        let endorser = endorsers.find(x => x.id === id);
        taskDetails.endorsersId2Name = endorser.name;
        taskDetails.endorsersId2 = id;

        this.setState({taskDetails : taskDetails})
      }

    }

  }



  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="bg-white pl-8 pt-10 pb-12">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <div className="2xl:col-span-5 lg:col-span-6 col-span-12">
                <h2 className="text-xl theme-color">Date</h2>
                <div className="grid grid-cols-12 gap-6 items-center w-full">
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateFrom"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateFrom}
                      onChange={(e) => this.handleChangeForPagination(e)}
                    />
                  </div>
                  <div className=" 2xl:col-span-1 lg:col-span-2 col-span-12 w-full text-center">
                    <h2 className="text-xl theme-color">To</h2>
                  </div>
                  <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                    <input
                      id="default"
                      name="dateTo"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="date"
                      value={this.state.pagination.dateTo}
                      onChange={(e) => this.handleChangeForPagination(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="2xl:col-span-3 lg:col-span-4 col-span-12">
                <h2 className="pl-8 block text-xl font-medium  theme-color">
                  Application Status
                </h2>
                <div className="pl-8 border-l-[1px] border-[#757575]">
                  <Selector
                    options={this.state.statusList}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    value={this.state.statusList.find(
                      (item) =>
                        item.label === this.state.pagination.filterByStatus
                    )}
                    drpIdentity="ApplicationStatus"
                  />
                </div>
              </div>

              <div className="2xl:col-span-3 lg:col-span-4 col-span-12">
                <h2 className="pl-8 block text-xl font-medium  theme-color">
                  Transaction Type
                </h2>
                <div className="pl-8 border-l-[1px] border-[#757575]">
                  <DropdownSelect
                    optionArray={this.state.transactionTypes}
                    setFilterParameters={this.handleChangeInDropdown.bind(this)}
                    allowSelectAll={false}
                    value={this.state.pagination.filterByTransactionType}
                    drpIdentity="TransactionType"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 pt-10 px-8">
            <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
              {`Total:${this.state.totalResultes}`}
            </h2>
            <div className="col-end-7 col-span-3 grid grid-cols-3 items-center">
              <div className="text-[#757575] text-xl">Search by Company </div>
              {/* <input className="col-span-2 text-lg font-bold  h-[40px] w-full text-[#757575]"/> */}
              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div>
                  <div className=" mt-6">
                    <Table
                      columns={[
                        { name: "registrationDate", title: "Registration Date" },
                        { name: "GeneralEmailAddress", title: "Email Address" },
                        { name: "membershipType", title: "Membership Tier" },
                        { name: "OrganisationName", title: "Organisation Name" },
                        { name: "OrganisationUEN", title: "Organisation UEN" },
                        { name: "transactionTypeName", title: "Transaction Type", },
                        { name: "membershipTaskStatusId", title: "Application Status" },
                        { name: "memberId", title: "Action" },
                      ]}
                      pagination={this.state.pagination}
                      rows={this.state.taskList}
                      sortingColumns={["registrationDate", "GeneralEmailAddress", "OrganisationName", "OrganisationUEN", "membershipTaskStatusId", "membershipType", "transactionTypeName"]}
                      isLoading={this.state.isLoading}
                      totalResultes={this.state.totalResultes}
                      totalCount={this.state.totalResultes}
                      setPagination={this.setPagination.bind(this)}
                      customScope={[
                        {
                          column: "memberId",
                          renderTableData: this.actions.bind(this),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div className="member-task-process">
          <MembershipTaskListProcess
            setAddeditModalOpen={this.state.setAddeditModalOpen}
            membershipType={this.state.membershipType}
            membershipRevenue={this.state.membershipRevenue}
            countries={this.state.countries}
            serviceType={this.state.serviceType}
            industriesServed={this.state.industriesServed}
            getToKnow={this.state.getToKnow}
            associationBenefits={this.state.associationBenefits}
            mrMrs={this.state.mrMrs}
            designation={this.state.designation}
            years={this.state.years}
            emailCirculars={this.state.emailCirculars}
            billingCategories={this.state.billingCategories}

            numberOfChapters={this.state.numberOfChapters}
            taskDetails={this.state.taskDetails}
            basePriceTable={this.state.basePriceTable}
            allowableDiscountTable={this.state.allowableDiscountTable}
            percentageDiscountTable={this.state.percentageDiscountTable}
            systemCalculatedTable={this.state.systemCalculatedTable}
            disablePaymentButton={this.state.disablePaymentButton}
            disableSubscription={this.state.disableSubscription}
            validState={this.state.validState}
            setModalOpen={() => this.setState({ setAddeditModalOpen: false })}
            onClose={(e) => { e.stopPropagation(); this.setState({ setAddeditModalOpen: false }); }}
            validateField={this.validateField.bind(this)}
            setFilterParameters={this.setFilterParameters.bind(this)}
            sendPaymentLink={this.sendPaymentLink.bind(this)}
            setSendClarificationsFlag={this.setSendClarificationsFlag.bind(this)}
            setFilterParametersForSelector = {this.setFilterParametersForSelector.bind(this)}
            handleChangeForSelector = {this.handleChangeForSelector.bind(this)}
            closeModalOnBack={() => { this.setState({ setAddeditModalOpen: false }); }}
            isLoading={this.state.isLoading}
            isLoadingApprove={this.state.isLoadingApprove}
            isLoadingApproveRenew={this.state.isLoadingApproveRenew}
            isLoadingClarification={this.state.isLoadingClarification}
            isLoadingReject={this.state.isLoadingReject}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            handleChange={this.handleChange.bind(this)}
            filtered={this.state.filterOrdinaryEndorsersList}
            ordinaryRepresentative={this.state.ordinaryRepresentative}
            allRepresentative={this.state.allRepresentative}
            allEndorsers={this.state.filterAllEndorsers}
            servicesTypeData={this.state.servicesTypeData}
            associationBenefitsData={this.state.associationBenefitsData}
            industriesServedData={this.state.industriesServedData}
            getEndorsersEmailDetailById={this.getEndorsersEmailDetailById.bind(this)}
            rejectApplicationForMember={this.rejectApplicationForMember.bind(this)}
            isLoadingSendMail1={this.state.isLoadingSendMail1}
            isLoadingSendMail2={this.state.isLoadingSendMail2}
            handleChangecontactinformation={this.handleChangecontactinformation.bind(this)}
            setFilterParametersForDesignation={this.setFilterParametersForDesignation.bind(this)}
            updateCorporateMemberUpdate={this.updateCorporateMemberUpdate.bind(this)}
            historyLogDetail={this.state.historyLogDetail}
            transactionTypes={this.state.transactionTypes}
            approveRenewMembershipRequest={this.approveRenewMembershipRequest.bind(this)}
            getAllDetailsForGeneratePDF={this.getAllDetailsForGeneratePDF.bind(this)}
          />
        </div>
        <div className="membership-task-list-process-sec">
          <SendForApprove
            organisationName={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            setSendApproveModal={this.state.setSendApprove}
            setOpenModal={() => this.setState({ setSendApprove: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setSendApprove: false });
            }}
          />
          <div className="send-clarification-model">
            <SendClarifications
              clarificationModal={this.state.setSendClarifications}
              clarification={this.state.clarification}
              setOpenModal={() => this.setState({ setSendClarifications: false })}
              onTinyEceEditorChange={(data) =>
                this.handleChangeClarificationDetails(data)
              }
              // onClose={(e) => {e.stopPropagation();
              //   this.setState({ setSendClarifications: false });
              // }}
              // getClarificationDetails={this.getClarificationDetails.bind(this)}
              saveClarificationDetails={this.saveClarificationDetails.bind(
                this
              )}
            />
          </div>
          <SendPaymentLink
            organisationName={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            paymentLinkModal={this.state.setPaymentLink}
            setOpenModal={() => this.setState({ setPaymentLink: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ setPaymentLink: false });
            }}
          />
          <SendQuotation
            organisationUEN={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            setSendQuotationModal={this.state.sendReject}
            setOpenModal={() => this.setState({ sendReject: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendReject: false });
            }}
          />
          <SendInvoice
            organisationName={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            setSendInvoiceModal={this.state.sendInvoice}
            setOpenModal={() => this.setState({ sendInvoice: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendInvoice: false });
            }}
          />
          <EscalateModal
            organisationName={this.state.taskDetails.organisationName}
            generalEmailAddress={this.state.taskDetails.generalEmailAddress}
            setSendInvoiceModal={this.state.sendEscalation}
            setOpenModal={() => this.setState({ sendEscalation: false })}
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ sendEscalation: false });
              window.location.reload();
            }}
          />
        </div>
      </>
    );
  }
}
