import React, { Component } from "react";
import TextInput from "../../../components/UIComponent/TextInput";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import {
  CommonValidationMessages,
  ErrorMessage,
  SuccessMessage,
} from "../../../utils/Messages";
import { isValidForm, validate } from "../../../utils/validation/CommonValidator";
import ValidationText from "../../../utils/validation/ValidationText";
import { Navigate } from "react-router-dom";

//import api services
import FieldManagementServices from "../../../services/axiosServices/apiServices/FieldManagementServices";
import LeadershipPositionsServices from "../../../services/axiosServices/apiServices/LeadershipPositionsServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import { checkParameters } from "../../../utils/Encryption";
import CKClassicEditor from "../../../components/ckEditor/CKClassicEditor";
import { AddLeadershipPositions } from "../../../services/axiosServices/ApiEndPoints";

export default class AddEditCommitteeMembers extends Component {
  constructor(props) {
    super(props);
    this.LeadershipPositionsServices = new LeadershipPositionsServices();
    this.FieldManagementServices = new FieldManagementServices()
    this.SwalServices = new SwalServices();
    this.state = {
      isLoading: false,
      redirect: null,
      projectDropdownList: [
        {
          id: 1,
          name: "SLA Web",
        },
        {
          id: 2,
          name: "SLA Admin",
        },
      ],

      committeeDetails: {
        committeeTitle: "",
        description: "",
        arrangementPosition: "",
        committeeDetailsId: 0,
      },

      currentArrangementPosition : 0,
   
      validationRule: {
        committeeTitle: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "CommitteeTitle"
            ),
          },
        ],
        // description: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.FieldRequired.replace(
        //       "{0}",
        //       "description"
        //     ),
        //   },
        // ],
        arrangementPosition: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace(
              "{0}",
              "Arrangement Position"
            ),
          },
        ],

      },
      validState: {
        isValid: true,
        error: {},
      },
      createdBy: 0,
      committeeDetailId: 0,
    };
  }

  //Method declaration section start
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let committeeDetailId = params.get("");
    if (committeeDetailId && (committeeDetailId = checkParameters(committeeDetailId, "Number"))) {
      if (committeeDetailId > 0) {
        this.setState({ committeeDetailId: committeeDetailId });
        console.log(committeeDetailId)
        this.getCommitteeDetailsById(committeeDetailId);
      }
    }

    // For Set Created by based on admin login
    let cookie = getAuthProps();
    const adminUserId = cookie?.adminUserId ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ createdBy: adminUserId });
    }
    // END

    // this.getEmailTemplateList();

  }



  addEditCommitteeDetails = () => {
    let request = {};
    let details = this.state.committeeDetails;
  
    request.committeeTitle = details.committeeTitle;
    request.description = details.description;
    request.arrangementPosition = details.arrangementPosition;
    request.committeeDetailsId = this.state.committeeDetailId;
    request.createdBy = this.state.createdBy;
  
    let isValidInput = this.isValidateAllFields();
    if (isValidInput) {
      this.LeadershipPositionsServices.checkIfArrangementPositionExists(request.arrangementPosition).then(
        (res) => {
          
          const value = res.responseItem.responseContent.errorType
            ? res.responseItem.responseContent.errorType
            : ' ';
          
          // Check if the existing value matches the current arrangement position
          let isEdit = this.state.committeeDetailId>0 ? true : false;
          let isValueExists = isEdit && res.responseItem.responseContent.keyId == this.state.committeeDetailId;
          let sameValue = isEdit && this.state.currentArrangementPosition == details.arrangementPosition && details.arrangementPosition == res.responseItem.responseContent.keyId;


          if (sameValue) {

            this.LeadershipPositionsServices.addEditCommitteeDetails(request).then(
              (response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                  this.SwalServices.Success(
                    SuccessMessage.Insert_Success.replace("{0}", "Committee Detail")
                  );
                  this.setState({ redirect: "/ManageCommitteeMembers" });
                } else {
                  this.SwalServices.Error(ErrorMessage.DefaultMessage);
                }
                this.setState({ isLoading: false });
              }
            );
            return;
          }
  
          if (value === "Exists" && !isValueExists) {
            this.SwalServices.Error(
              ErrorMessage.Already_Exist.replace("{0}", "Arrangement Position")
            );
            return;
          }
  
          // Proceed to save
          this.LeadershipPositionsServices.addEditCommitteeDetails(request).then(
            (response) => {

              if (response.statusCode === 200 && response.responseItem != null) {
                this.SwalServices.Success(
                  SuccessMessage.Insert_Success.replace("{0}", "Committee Detail")
                );
                this.setState({ redirect: "/ManageCommitteeMembers" });
              } else {
                this.SwalServices.Error(ErrorMessage.DefaultMessage);
              }
              this.setState({ isLoading: false });
            }
          );
        }
      );
    }
  };



  

  // addEditCommitteeDetails = () => {

  //   let request = {};
  //   let details = this.state.committeeDetails;

  //   request.committeeTitle = details.committeeTitle;
  //   request.description = details.description;
  //   request.arrangementPosition = details.arrangementPosition;
  //   request.committeeDetailsId = this.state.committeeDetailId;
  //   request.createdBy = this.state.createdBy

  //   let isValidInput = this.isValidateAllFields();
  //   if (isValidInput) {


  //     this.LeadershipPositionsServices.checkIfArrangementPositionExists(request.arrangementPosition).then(
  //       (res) => {
  //         const value = res.responseItem.responseContent.errorType ? res.responseItem.responseContent.errorType : ' '

  //         let isValueExists = res.responseItem.responseContent.keyId == details.arrangementPosition;
  //         debugger
      
  //         if(value ==="Exists" && !isValueExists)
  //         {
  //           this.SwalServices.Error(
  //             ErrorMessage.Already_Exist.replace(
  //               "{0}",
  //               "Already Exists"
  //             ))
  //             return;
  //         }

  //         else{
  //           this.LeadershipPositionsServices.addEditCommitteeDetails(request).then(
  //             (response) => {
  //               if (response.statusCode === 200 && response.responseItem != null) {
  //                 this.SwalServices.Success(
  //                   SuccessMessage.Insert_Success.replace(
  //                     "{0}",
  //                     "Details Saved"
  //                   ))
  //                 this.setState({ redirect: "/ManageCommitteeMembers" });
  //               } else {
  //                 this.SwalServices.Error(ErrorMessage.DefaultMessage);
  //               }
  //               this.setState({ isLoading: false });
  //             }
  //           );


  //         }
  //       }
  //     )
  
  //   }
  // };

  getCommitteeDetailsById = (id) => {
    this.LeadershipPositionsServices.getCommitteeDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          committeeDetails: response.responseItem.responseContent,
          currentArrangementPosition : response.responseItem.responseContent.arrangementPosition
        });

      } else {
        this.SwalServices.Error(response.message);
      }
    });
  };

  //project dropdown method
  ;

  // API method declaration end

  // Internal method declaration start
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.committeeDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.committeeDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.committeeDetails;
    detail[name] = value;
    this.setState({ committeeDetails: { ...detail } });
  }



  setVideoDescription = (data) => {
    if (typeof data === 'string') {
      let detail = this.state.committeeDetails;
      // var re = new RegExp('<a href="([^"]+)"', "g");
      // var result = data.replace(
      //   re,
      //   '<a href=\'$1\' target="_blank" rel="noopener noreferrer"'
      // );
      detail["description"] = data;
      this.setState({ committeeDetails: { ...detail } });
    }
  };


  // Method declaration end

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className=" heading-part px-7 py-4 border-b">
            <h4 className="small-title font-bold theme-color">

              {this.state.committeeDetailId && this.state.committeeDetailId> 0 ? 'Update' : 'Add'} Committee Detail

            </h4>
          </div>
          <div className="px-8 pt-10">
            <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 sm:grid-cols-12 gap-6 w-full pb-8">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Committee Title
                  <span className="text-[#C00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="committeeTitle"
                  value={this.state.committeeDetails.committeeTitle}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("committeeTitle")}
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.committeeTitle}
                  />
                </div>
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl theme-color mb-1">
                  Arrangement Position <span className="text-[#C00000]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="number"
                  name="arrangementPosition"
                  value={this.state.committeeDetails.arrangementPosition}
                  handleChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("arrangementPosition")}
                />
                <div>
                  <ValidationText
                    error={this.state.validState.error.arrangementPosition}
                  />
                </div>
              </div>


              <div className="xl:col-span-12 lg:col-span-12 sm:col-span-12">
                <h2 className="text-xl theme-color mb-1">
                  Committee Description:
                  {/* <span className="text-[#C00000]">*</span> */}
                </h2>
                <div className="ck-editor-sec">
                  <CKClassicEditor
                    key={"fullPageCKEditor"}
                    onChange={(data) => {
                      this.setVideoDescription(data);
                    }}
                    data={this.state.committeeDetails.description}
                    // onBlur={(event, editor) => {
                    //   this.validateField("description");
                    // }}
                  />
                  <div>
                    {/* <ValidationText
                      error={this.state.validState.error.description}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
            {/*Footer Button End*/}
            <div className="flex flex-wrap justify-between space-x-10 mt-12 pb-8">
              <div>
                <button
                  className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white"
                  onClick={() =>
                    this.setState({ redirect: "/ManageCommitteeMembers" })
                  }
                >
                  Back
                </button>
              </div>
              <div>
                {this.state.committeeDetailId &&
                  this.state.committeeDetailId > 0 ? (
                  <button
                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                    onClick={() => this.addEditCommitteeDetails()}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="w-32 px-7 py-2 btn-sm btn-red text-lg text-white"
                    onClick={() => this.addEditCommitteeDetails()}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
            {/*Footer Button End*/}
          </div>
        </div>
      </div>
    );
  }
}