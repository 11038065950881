import {
    axiosGetAuthorize, axiosGetFileAuthorizeblob, axiosPostAuthorize, axiosPostWithoutEncryption,
    //  axiosGetMultiParams,
    axiosGetAuthorizeMultiParams,
    axiosGetMultiParams,
    axiosPostFileAuthorizeblob
} from '../AxiosRequests';
import {GetMTOInformationById,GenerateAllMemberTrackingReport,
    CancelInvoiceAndRegenerateRenewalInvoice, GetAllCompanyMemberReportList, LoginToIndividualMemberForCorporateMember, StoreBase64Image, AddImagePathForMembers,
    GetEndorsersEmailDetailById, GetAllCorporateMemberList, GetCorporateMembersById, DeleteCorporateMembers, GetPurchaseChapterDataById, AddEditCorporateMembers, DeleteChapters, GetAllDropdownsForCorporateMember, AddEditCorporateSIISAccountSegment, GetCorporateBasicSIISInformation, AddEditCorporateEngagementTracking, GetCorporateEngagementTrackingList, AddEditCorporateChapter,
    // GetCorporateChapter, 
    GetEmployeeInfomationForCorporateMemberAdmin, GetCorporateEngagementDetailsById, GetAllCorporateOrganisationRole, GetChapterCommitteeRoles, AddEditEmployeeInformation, GetEmployeeInformationById, AddEditCorporateOrganisationRole, DeleteCorporateOrganisationRole, GetCorporateOrganisationRoleById, GetChapterListByCorporateId, AddEditEvenBookingData, AddEditFinancialDetail, AddEditOrganisationEmployees, DeleteChapterById, JoinChapterById, AddEditOrganisationKeyRoles, AddEditSGTechIntrest, AddEditOrganisationBussinessDetails, AddEditOrganisationProfile, AddEditChapterInformation, AddEditCorporateMembershipSubscription,
    //  AddEditGovernmentProgramme, 
    AddEditFinanceCardDetails, AddEditProspectInformation, AddEditEngagementTrackingList, AddEditSIISCorporateAccountInformation, AddEditOrganisationBasicInformation, GetAllCorporateSectionsById, GetSIISCorporateAccountInformationById, GetOrganisationBasicInformationById, GetChapterInformationById, GetOrganisationProfileById, GetOrganisationBussinessDetailsById, GetCorporateMembershipSubscriptionById, AddEditCorporateProspect, GetProspectInformationById, GetSGTechIntrestById, AddEditCorporateCommunication, GetCommunicationById, AddEditGovernmentPrograme, GetGovernmentProgrammeDetailByProjectId, GetCorporateFinancialInfo, GetCorporateFinanceReceiptInvoice, RenewCorporateMember, SearchIndividualMemberByEmailForOrgRole, MarkIndivisualMemberAsNonMember, GetAllAvailableChapterListForUpcomingTenure, GetCorporateMemberDirectoryList, GetContactInformationDetailById, GetAllDropdownsForEndorsers, GetRepresentativesById, GeneratePaymentInvoice, GetEndorsersDetailById, GetEndorserHistoryLogDetailsByTaskId,
    //UpgradeChange
    GetAllUpgradeChangeRequestList,UpdateMTOInformation, GetUpgradeChangeRequestDetailById, RejectUpgradeChangeRequestById, ApproveUpgradeChangeRequest, ChangeRequestSendClarificationMail, GetAllRenewalList, DownloadExcelForRenewalMembersRecord, GetAllRepresentativeDetails, DownloadCompanyRepresentativeReport, DownloadExcelForCompanyMemberReport, AddEditReinstatementMembershipDetails, GetAllDropdownsForAllMemberReport, GetAllMembershipTrackingReportList, DownloadSheetForMembershipTrackingReport, GetAllSLAMembershipTrackingReportList, GetMembershipTrackingReportList, DownloadSheetForMembershipReport, GetAllSLAMembersReportList, GetAllMembersReportsDetail
} from '../ApiEndPoints'

export default class CorporateMemberServices {

    async getAllCorporateMemberList(request) {
        return axiosPostAuthorize(GetAllCorporateMemberList, request)
    }

    async getEndorserHistoryLogDetailsByTaskId(request) {
        return axiosGetAuthorize(GetEndorserHistoryLogDetailsByTaskId, request)
    }

    async getCorporateMembersById(request) {
        return axiosGetAuthorize(GetCorporateMembersById, request)
    }

    getAllDropdownsForEndorsers() {
        return axiosGetAuthorize(GetAllDropdownsForEndorsers);
    }

    getRepresentativesById(request) {
        return axiosGetAuthorize(GetRepresentativesById, request);
    }

    async deleteCorporateMembers(request) {
        return axiosPostAuthorize(DeleteCorporateMembers, request)
    }

    async addEditCorporateMembers(request) {
        return axiosPostAuthorize(AddEditCorporateMembers, request)
    }
    // async addEditOrganisationBasicInformation(request){
    //     return axiosPostAuthorize(AddEditOrganisationBasicInformation,request);
    // }
    async getAllDropdownForCorporateMember(request) {
        return axiosGetAuthorize(GetAllDropdownsForCorporateMember, request);
    }
    async getAllCompanyMemberReportList(request) {
        return axiosGetAuthorize(GetAllCompanyMemberReportList, request);
    }
    async addEditCorporateSIISAccountSegment(request) {
        return axiosPostAuthorize(AddEditCorporateSIISAccountSegment, request)
    }
    async cancelInvoiceAndRegenerateRenewalInvoice(request) {
        return axiosGetAuthorizeMultiParams(CancelInvoiceAndRegenerateRenewalInvoice, request);
    }
    async getCorporateBasicSIISInformation(request) {
        return axiosGetAuthorize(GetCorporateBasicSIISInformation, request);
    }
    async getEngagementTrackingList(request) {
        return axiosGetAuthorize(GetCorporateEngagementTrackingList, request);
    }
    async getEngagmentDetailsById(request) {
        return axiosGetAuthorize(GetCorporateEngagementDetailsById, request)
    }
    async getBasicInformation(request) {
        return axiosGetAuthorize(GetCorporateBasicSIISInformation, request)
    }
    async addEditEngagementTracking(request) {
        return axiosPostAuthorize(AddEditCorporateEngagementTracking, request)
    }
    async addEditChapters(request) {
        return axiosPostAuthorize(AddEditCorporateChapter, request);
    }
    // async getChapters(request){
    //     return axiosGetAuthorize(GetCorporateChapter,request);
    // }
    async getChapters(request) {
        return axiosGetAuthorize(GetChapterListByCorporateId, request);
    }
    async getEmployeeList(request) {
        return axiosGetAuthorizeMultiParams(GetEmployeeInfomationForCorporateMemberAdmin, request);
    }
    async getOrganisationRoleList(request) {
        return axiosGetAuthorize(GetAllCorporateOrganisationRole, request);
    }
    async getChapterCommitteeRoles(request) {
        return axiosGetAuthorize(GetChapterCommitteeRoles, request);
    }
    async addEditEmployeeInformation(request) {
        return axiosPostAuthorize(AddEditEmployeeInformation, request);
    }
    async getEmployeeInfoById(request) {
        return axiosGetAuthorize(GetEmployeeInformationById, request);
    }
    async addEditOrganisation(request) {
        return axiosPostAuthorize(AddEditCorporateOrganisationRole, request);
    }
    async deleteCorporateOrganisationRole(request) {
        return axiosPostAuthorize(DeleteCorporateOrganisationRole, request);
    }
    async getCorporateOrganisationRoleById(request) {
        return axiosGetAuthorize(GetCorporateOrganisationRoleById, request);
    }
    async deleteChapters(request) {
        return axiosPostAuthorize(DeleteChapters, request);
    }

    //New services for create corporate members
    async addEditSIISCorporateAccountInformation(request) {
        return axiosPostAuthorize(AddEditSIISCorporateAccountInformation, request)
    }
    async addEditEngagementTrackingList(request) {
        return axiosPostAuthorize(AddEditEngagementTrackingList, request)
    }
    async addEditProspectInformation(request) {
        return axiosPostAuthorize(AddEditProspectInformation, request)
    }
    async addEditCorporateProspect(request) {
        return axiosPostAuthorize(AddEditCorporateProspect, request)
    }
    async addEditFinanceCardDetails(request) {
        return axiosPostAuthorize(AddEditFinanceCardDetails, request)
    }
    async addEditGovernmentProgramme(request) {
        return axiosPostAuthorize(AddEditGovernmentPrograme, request)
    }
    async addEditCorporateMembershipSubscription(request) {
        return axiosPostAuthorize(AddEditCorporateMembershipSubscription, request)
    }
    async addEditChapterInformation(request) {
        return axiosPostAuthorize(AddEditChapterInformation, request)
    }
    async addEditOrganisationBasicInformation(request) {
        return axiosPostAuthorize(AddEditOrganisationBasicInformation, request)
    }
    async addEditOrganisationProfile(request) {
        return axiosPostAuthorize(AddEditOrganisationProfile, request)
    }
    async addEditOrganisationBussinessDetails(request) {
        return axiosPostAuthorize(AddEditOrganisationBussinessDetails, request)
    }
    async addEditSGTechIntrest(request) {
        return axiosPostAuthorize(AddEditSGTechIntrest, request)
    }
    async addEditOrganisationKeyRoles(request) {
        return axiosPostAuthorize(AddEditOrganisationKeyRoles, request)
    }
    async joinChapterById(request) {
        return axiosPostAuthorize(JoinChapterById, request)
    }
    async deleteChapterById(request) {
        return axiosPostAuthorize(DeleteChapterById, request)
    }
    async addEditCorporateCommunication(request) {
        return axiosPostAuthorize(AddEditCorporateCommunication, request)
    }
    async addEditOrganisationEmployees(request) {
        return axiosPostAuthorize(AddEditOrganisationEmployees, request)
    }
    async addEditFinancialDetail(request) {
        return axiosPostAuthorize(AddEditFinancialDetail, request)
    }
    async addEditEvenBookingData(request) {
        return axiosPostAuthorize(AddEditEvenBookingData, request)
    }
    async getAllCorporateSectionsById(request) {
        return axiosPostAuthorize(GetAllCorporateSectionsById, request)
    }
    async getSIISCorporateAccountInformationById(request) {
        return axiosGetAuthorize(GetSIISCorporateAccountInformationById, request)
    }
    async getOrganisationBasicInformationById(request) {
        return axiosGetAuthorize(GetOrganisationBasicInformationById, request)
    }
    async getChapterInformationById(request) {
        return axiosGetAuthorize(GetChapterInformationById, request)
    }
    async getOrganisationProfileById(request) {
        return axiosGetAuthorize(GetOrganisationProfileById, request)
    }
    async getOrganisationBussinessDetailsById(request) {
        return axiosGetAuthorize(GetOrganisationBussinessDetailsById, request)
    }
    async getCorporateMembershipSubscriptionById(request) {
        return axiosGetAuthorize(GetCorporateMembershipSubscriptionById, request)
    }
    async getProspectInformationById(request) {
        return axiosGetAuthorize(GetProspectInformationById, request)
    }
    async getSGTechIntrestById(request) {
        return axiosGetAuthorize(GetSGTechIntrestById, request)
    }
    async getCommunicationById(request) {
        return axiosGetAuthorize(GetCommunicationById, request)
    }
    async getGovernmentProgrammeDetailByProjectId(request) {
        return axiosGetAuthorize(GetGovernmentProgrammeDetailByProjectId, request);
    }

    async getCorporateFinancialInfo(request) {
        return axiosGetAuthorize(GetCorporateFinancialInfo, request);
    }

    async getCorporateFinanceReceiptInvoice(request) {
        return axiosPostFileAuthorizeblob(GetCorporateFinanceReceiptInvoice, request);
    }

    async renewCorporateMember(request) {
        return axiosPostAuthorize(RenewCorporateMember, request);
    }
    async searchIndividualMemberByEmailForOrgRole(request) {
        return axiosPostAuthorize(SearchIndividualMemberByEmailForOrgRole, request);
    }
    async markIndivisualMemberAsNonMember(request) {
        return axiosGetAuthorize(MarkIndivisualMemberAsNonMember, request);
    }
    async getPurchaseChapterDataById(request) {
        return axiosGetAuthorize(GetPurchaseChapterDataById, request)
    }
    async getAllAvailableChapterListForUpcomingTenure(request) {
        return axiosGetAuthorize(GetAllAvailableChapterListForUpcomingTenure, request);
    }
    getAllDropdownForCorporateDashboard() {
        return axiosGetAuthorize(GetAllDropdownsForCorporateMember);
    }
    async getCorporateMemberDirectoryList(request) {
        return axiosPostAuthorize(GetCorporateMemberDirectoryList, request);
    }
    async getContactInformationDetailById(request) {
        return axiosGetAuthorize(GetContactInformationDetailById, request)
    }
    async getEndorsersEmailDetailById(request) {
        return axiosPostAuthorize(GetEndorsersEmailDetailById, request);
    }
    async generatePaymentInvoice(request) {
        return axiosPostAuthorize(GeneratePaymentInvoice, request);
    }
    async getEndorsersDetailById(request) {
        return axiosGetAuthorize(GetEndorsersDetailById, request)
    }
    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }
    async saveImageToDatabase(request) {
        return axiosPostAuthorize(AddImagePathForMembers, request);
    }
    async getMtoInformationById(request) {
        return axiosGetAuthorize(GetMTOInformationById, request)
    }
    async updateMTOInformation(request) {
        return axiosPostAuthorize(UpdateMTOInformation, request)
    }

    //Upgrade Change
    async getAllUpgradeChangeRequestList(request) {
        return axiosPostAuthorize(GetAllUpgradeChangeRequestList, request)
    }

    async getUpgradeChangeRequestDetailById(request) {
        return axiosGetAuthorize(GetUpgradeChangeRequestDetailById, request)
    }

    async rejectUpgradeChangeRequestById(request) {
        return axiosGetAuthorize(RejectUpgradeChangeRequestById, request)
    }

    async approveUpgradeChangeRequest(request) {
        return axiosPostAuthorize(ApproveUpgradeChangeRequest, request)
    }
    async generateAllMemberTrackingReport(request) {
        return axiosPostAuthorize(GenerateAllMemberTrackingReport, request)
    }
    async changeRequestSendClarificationMail(request) {
        return axiosPostAuthorize(ChangeRequestSendClarificationMail, request);
    }
    async loginToIndividualMemberForCorporateMember(request) {
        return axiosPostAuthorize(LoginToIndividualMemberForCorporateMember, request);
    }

    //Renewal Dashboard
    async getAllRenewalList(request) {
        return axiosPostAuthorize(GetAllRenewalList, request)
    }


    async downloadCompanyRepresentativeReport() {
        return axiosPostFileAuthorizeblob(DownloadCompanyRepresentativeReport);
    }

    async downloadExcelForCompanyMemberReport() {
        return axiosPostFileAuthorizeblob(DownloadExcelForCompanyMemberReport);
    }
    async downloadExcelForRenewalMembersRecord(request) {
        return axiosPostFileAuthorizeblob(DownloadExcelForRenewalMembersRecord, request);
    }

    async addEditReinstatementMembershipDetails(request) {
        return axiosGetAuthorize(AddEditReinstatementMembershipDetails, request);
    }

    // SLA Report
    async getAllDropdownsForAllMemberReport(request) {
        return axiosGetAuthorize(GetAllDropdownsForAllMemberReport, request);
    }
    async getAllMembersReportsDetail(request) {
        return axiosPostFileAuthorizeblob(GetAllMembersReportsDetail, request);
    }

    async getAllMembershipTrackingReportList(request) {
        return axiosPostAuthorize(GetAllMembershipTrackingReportList, request)
    }

    async getAllSLAMembersReportList(request) {
        return axiosPostAuthorize(GetAllSLAMembersReportList, request)
    }
    // async downloadSheetForMembershipTrackingReport(request) {
    //     return axiosGetFileAuthorizeblob(DownloadSheetForMembershipTrackingReport, request)
    // }

    async downloadSheetForMembershipTrackingReport(request) {
        return axiosGetFileAuthorizeblob(DownloadSheetForMembershipTrackingReport, request);
    }
    async downloadSheetForMembershipReport(request) {
        return axiosGetFileAuthorizeblob(DownloadSheetForMembershipReport, request);
    }
   
}
