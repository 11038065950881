import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import moment from "moment/moment";
import { saveAs } from "file-saver";
import { getDate } from "date-fns";
import DropdownAction from "../../components/dropdown/DropdownAction";
import SearchBar from "../../components/membership/SearchBar";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DatePicker from "../../components/datePicker/DatePicker";
import SwalServices from "../../services/swalServices/SwalServices";
import MembershipCorporateServices from "../../services/axiosServices/apiServices/MembershipCorporateServices";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import { CommonSuccessMessages } from "../../utils/Messages";
import { encryptAES } from "../../utils/Encryption";
import CorporateMemberServices from "../../services/axiosServices/apiServices/CorporateMemberServices";
import RepresentativeDetailsModal from "../membership/membershipRenewalDashboard/RepresentativeDetailsModal";
//import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";

export default class ViewPosting extends Component {
  constructor(props) {
    super(props);
    this.membershipCorporateServices = new MembershipCorporateServices();
    this.corporateMemberServices = new CorporateMemberServices();
    //this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchByUENNumber: "",
        paymentStatusId: 0,
        formStatusId: 0,
        membershipTypeId: 0,
        renewalYear: 0,
      },
      isLoadingcancelInvoice: false,
      councilIndex: 0,
      setRepresentativeDetailsModal: false,
      receiverOrganisationName: "",
      renewalMemberList: [],
      totalMembersFullyRenewed: 0,
      totalMembersPaid: 0,
      totalMembersDeclared: 0,
      renewalYear: 0,
      dataGetting: false,
      represenativesData: [],
      totalResultes: 0,
      totalMembers: 0,

      paymentStatus: [
        { id: 1, name: "Pending" },
        { id: 3, name: "Submitted" },
        { id: 2, name: "Paid" },
      ],
      formStatus: [
        { id: 1, name: "Pending" },
        { id: 3, name: "Submitted" },
        { id: 2, name: "Updated" },
      ],
      membershipTypes: [],
      actionDropdown: ["Activate", "Deactivate", "Update", "Delete"],
    };
  }

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;

    if (drpIdentity === "MembershipType") {
      detail["membershipTypeId"] = id;
    }

    if (drpIdentity === "PaymentStatus") {
      detail["paymentStatusId"] = id;
    }

    if (drpIdentity === "FormStatus") {
      detail["formStatusId"] = id;
    }

    if (drpIdentity === "RenewalYear") {
      detail["renewalYear"] = id === null ? 0 : id;
    }

    this.setState({ pagination: { ...detail } });
    this.getAllRenewalList();
  };

  searchByName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "searchByCompanyName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    } else if (identity === "searchByUENNumber") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchByUENNumber = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllRenewalList();
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllRenewalList();
  };

  onClose = () => {
    this.setState({ setDropdownOpen: false });
  };

  actionClick = (index, value, option) => {
    if (value && value > 0 && option === "Deactivate") {
      this.deActivateCorporateMemnber(value);
    }
    if (value && value > 0 && option === "Activate") {
      this.activeInActiveCorporateMember(value);
    }
    if (value && value > 0 && option === "Update") {
      this.updateCorporateMemberUpdate(value);
    }
    if (value && value > 0 && option === "Delete") {
      this.deleteCorporateMember(value);
    }
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ setDropdownOpen: false });
    }
  }
  deActivateCorporateMemnber = (value) => {
    let request = {
      id: value,
      statusId: 7,
    };
    this.setState({ isLoading: true });
    this.membershipCorporateServices
      .activeInActiveCorporateMember(request)
      .then((response) => {
        if (response.statusCode === 200) {
          this.swalServices.Success(
            "Corporate member deactivated Successfully."
          );
          this.getAllRenewalList();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  activeInActiveCorporateMember = (value) => {
    let request = {
      id: value,
      statusId: 8,
    };
    this.setState({ isLoading: true });
    this.membershipCorporateServices
      .activeInActiveCorporateMember(request)
      .then((response) => {
        if (response.statusCode === 200) {
          this.swalServices.Success("Corporate member activated Successfully.");
          this.getAllRenewalList();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };
  cancelInvoiceAndRegenerateRenewalInvoice = (id, id2) => {
    if (id > 0 && id != null) {
      let request = [id, id2];
      this.setState({ isLoadingcancelInvoice: true });
      this.corporateMemberServices
        .cancelInvoiceAndRegenerateRenewalInvoice(request)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem.responseContent != null
          ) {
            this.swalServices.Success("Invoice regenerated Successfully");

            this.setState({ setRepresentativeDetailsModal: false });
            this.setState({ isLoadingcancelInvoice: false });
          } else {
            this.swalServices.Warning("Invoice not regenerated");
            this.setState({ setRepresentativeDetailsModal: false });
            this.setState({ isLoadingcancelInvoice: false });
          }
        });
    }
  };
  updateCorporateMemberUpdate = (value) => {
    if (value && value > 0) {
      this.setState({
        route:
          "/CreateCorporateMembers/CorporateMemberId?=" + encryptAES(value),
      });
    }
  };

  deleteCorporateMember = (value) => {
    let request = {
      id: value,
      deletedBy: 1,
      appId: 115,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Member"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Member"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.membershipCorporateServices
            .deleteCorporateMember(request)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success("Deleted");
                this.getAllRenewalList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  actions = (element, index, value) => {
    return element !== "corporateMemberId" ? null : (
      <td>
        <ActionContainer>
          <DropdownAction
            key={index}
            index={index}
            value={value}
            options={this.state.actionDropdown}
            align="right"
            onOptionClick={this.actionClick.bind(this)}
          />
        </ActionContainer>
      </td>
    );
  };

  actions2 = (element, index, value) => {
    return element !== "organisationName" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt break-all text-center">
        <button
          className="flex items-center justify-center"
          onClick={(e) => {
            e.preventDefault();
            this.setState({
              receiverOrganisationName:
                this.state.renewalMemberList[index].organisationName,
            });
            this.showRepresentativesDetails(e, value, index);
          }}
        >
          <span className="text-[#3e397c] underline font-bold mr-8 2xl:text-[15px]">
            {this.state.renewalMemberList[index].organisationName}
          </span>
        </button>
      </td>
    );
  };

  showRepresentativesDetails = (e, id, index) => {
    this.setState({ councilIndex: index });
    e.stopPropagation();
    this.setState({ setRepresentativeDetailsModal: true });
  };

  getAllCorporateStatus = () => {
    this.membershipCorporateServices
      .getAllCorporateStatus()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          //this.setState({ statuses: response.responseItem.responseContent }, () => this.getAllRenewalList());
          this.getAllRenewalList();
        } else {
          this.getAllRenewalList();
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getAllDropdownForCorporateMember = () => {
    this.corporateMemberServices
      .getAllDropdownForCorporateMember()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let membershipType = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "MembershipType"
          );
          this.setState({ membershipTypes: membershipType });
        }
      });
  };

  getAllRenewalList = (pagination = this.state.pagination) => {
    pagination.renewalYear =
      this.state.pagination.renewalYear === 0
        ? new Date().getFullYear()
        : this.state.pagination.renewalYear;
    this.setState({ isLoading: true });
    this.corporateMemberServices
      .getAllRenewalList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;

          let totalMembersFullyRenewed = 0;
          let totalMembersPaid = 0;
          let totalMembersDeclared = 0;
          let totalMembers = 0;

          let renewalYear = "";

          details.map((key, index) => {
            key.renewalDate =
              key.renewalDate === "Invalid date"
                ? "-"
                : moment(key.renewalDate).format("DD MMM YYYY");
            key.paidDate =
              key.paidDate === "Invalid date" ||
              !moment(key.paidDate, moment.ISO_8601, true).isValid()
                ? "-"
                : moment(key.paidDate).format("DD MMM YYYY");
            if (key.membershipTypeName === "Associate") {
              key.formDate = "NA";
            } else {
              key.formDate =
                key.formDate === "Invalid date" ||
                !moment(key.formDate, moment.ISO_8601, true).isValid()
                  ? "-"
                  : moment(key.formDate).format("DD MMM YYYY");
            }
            if (key.organisationName === null || key.organisationName === "") {
              key.organisationName = "-";
            }
            if (key.organisationUEN === null || key.organisationUEN === "") {
              key.organisationUEN = "-";
            }
            totalMembersFullyRenewed = key.totalMembersFullyRenewed || 0;
            totalMembersPaid = key.totalMembersPaid || 0;
            totalMembersDeclared = key.totalMembersDeclared || 0;
            totalMembers = key.totalMembers || 0;

            renewalYear = key.renewalYear;
            return key;
          });
          this.setState({
            totalMembersFullyRenewed,
            totalMembersPaid,
            totalMembersDeclared,
            renewalYear,
            totalMembers,
          });
          this.setState({ renewalMemberList: details }, () =>
            this.setState({ dataGetting: true })
          );
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    this.getAllCorporateStatus();
    this.getAllDropdownForCorporateMember();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  getDateForFileName() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  }

  downloadExcelForRenewalMembersRecord = () => {
    let request = { ...this.state.pagination };
    request.pageSize = 100000;
    this.corporateMemberServices
      .downloadExcelForRenewalMembersRecord(request)
      .then((response) => {
        if (response.status === 200 && response.data != null) {
          let blob = new Blob([response.data]);
          if (blob.size > 300) {
            saveAs(blob, `RenewalReport_${this.getDateForFileName()}.xlsx`);
          } else {
            this.swalServices.Error("No Records Found!");
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1 "></div>
        </div>
        <main className="pb-10">
          <div className="custom-card shadow-lg mb-4 rounded-md">
            <div className="  w-full ">
              <div className="grid lg:gap-4 gap-2 grid-cols-12">
                <div className="lg:col-span-6 md:col-span-12  col-span-12 ">
                  <div className="">
                    <h2 className="col-span-3 mobile-14 text-[#212529] font-bold 2xl:mb-2 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
                      Search by Title
                    </h2>
                    <div className="col-span-9 membership-searchbar">
                      <SearchBar
                      // handleSearchByName={props.handleSearchByName}
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 col-span-12 ">
                  <div className="">
                    <h2 className="col-span-3 mobile-14 text-[#212529] font-bold 2xl:mb-2 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
                      Search by Company
                    </h2>
                    <div className="col-span-9 membership-searchbar">
                      <SearchBar
                      // handleSearchByName={props.handleSearchByName}
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-6 md:col-span-6 sm:col-span-6 col-span-12 ">
                  <div className="">
                    <h2 className="col-span-3 mobile-14 text-[#212529] font-bold 2xl:mb-2 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
                      Collaboration Type
                    </h2>
                    <div className="col-span-9 membership-searchbar">
                      <DropdownSelect
                      // drpIdentity={"OrdinaryRepresentatives"}
                      // optionArray={props.ordinaryRepresentative}
                      // setFilterParameters={props.setFilterParameters}
                      // value={props.memberDetails.representativeId1}
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4 md:col-span-4 sm:col-span-4 col-span-12 ">
                  <div className="">
                    <h2 className="col-span-5 mobile-14 text-[#212529] font-bold 2xl:mb-2 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
                      Collaboration Deadline
                    </h2>
                    <div className="col-span-6 membership-searchbar">
                      <DatePicker
                      // placeholder=""
                      // name="startDate"
                      // value={props.contributionDetails.startDate}
                      // handleChange={(e) => props.handleChange(e)}
                      // validateField={() =>
                      //   props.validateField(
                      //     "startDate",
                      //     props.contributionDetails
                      //   )
                      // }
                      />
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-2 md:col-span-2 sm:col-span-2 col-span-12 ">
                  <div className="grid lg:gap-4 gap-2 grid-cols-12 mt-7">
                    <h2 className="col-span-3 mobile-14 text-[#212529] font-bold 2xl:mb-2 lg:mb-2 mb-0 member-directory-top-boxes lg:flex lg:items-center">
                      Till
                    </h2>
                    <div className="col-span-9 membership-searchbar">
                      <DatePicker
                      // placeholder=""
                      // name="startDate"
                      // value={props.contributionDetails.startDate}
                      // handleChange={(e) => props.handleChange(e)}
                      // validateField={() =>
                      //   props.validateField(
                      //     "startDate",
                      //     props.contributionDetails
                      //   )
                      // }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 ">
                  <div className="lg:flex 2xl:flex xl:flex md:flex sm:flex block lg:justify-end justify-between">
                    <div className="flex justify-end lg:mt-5 md:mt-5 sm:mt-3 mt-3">
                      <button
                        className="btn-red text-white sm:ml-10 ml-9 font-bold whitespace-nowrap  inline-flex items-center justify-center px-4 py-2 "
                        // onClick={(event) =>
                        //   props.getCorporateMemberDirectoryList(event)
                        // }
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 mb-4">
              <div className="custom-card posting-card card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                <div className="mr-4">
                  <p className="card-number">10</p>
                  <h3 className="card-title">
                    Number of Postings
                  </h3>
                </div>
              </div>

              <div className="custom-card posting-card border border-gray-300 rounded-md p-4 flex items-center shadow-sm">
                <div className="mr-4">
                  <p className="card-number">5</p>
                  <h3 className="card-title">
                    Responses
                  </h3>
                </div>
              </div>
            </div>

            <div className="flex justify-end mb-4">
              <button
                className="btn btn-red text-white flex items-center"
                onClick={() => this.downloadExcelForRenewalMembersRecord()}
              >
                <span className="text-xl font-semibold">Deadline</span>
              </button>
            </div>

            <div className="relative">
              <div>
                <div className="" onMouseLeave={() => this.onClose()}>
                  <Table
                    columns={[
                      { name: "membershipTypeName", title: "Company Name" },
                      { name: "mtoRegistry", title: "Collaboration Type" },
                      { name: "renewalDate", title: "Title" },
                      { name: "paymentStatus", title: "Deadline" },
                      { name: "paidDate", title: "Posted On" },
                      { name: "organisationName", title: "Action" },
                    ]}
                    rows={this.state.renewalMemberList}
                    isLoading={this.state.isLoading}
                    sortingColumns={[]}
                    pagination={this.state.pagination}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      // {
                      //     column: 'corporateMemberId',
                      //     renderTableData: this.actions.bind(this)
                      // },
                      {
                        column: "organisationName",
                        renderTableData: this.actions2.bind(this),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="members-modal-sec membersdirectoery">
              {this.state.dataGetting === true &&
              this.state.renewalMemberList.length > 0 ? (
                <RepresentativeDetailsModal
                  councilIndex={this.state.councilIndex}
                  getMemberDirectoryData={this.state.renewalMemberList}
                  setRepresentativeDetailsModal={
                    this.state.setRepresentativeDetailsModal
                  }
                  charLimit={this.state.charLimit}
                  setOpenModal={() =>
                    this.setState({ setRepresentativeDetailsModal: false })
                  }
                  onClose={(e) => {
                    e.stopPropagation();
                    this.setState({ setRepresentativeDetailsModal: false });
                  }}
                  cancelInvoiceAndRegenerateRenewalInvoice={this.cancelInvoiceAndRegenerateRenewalInvoice.bind(
                    this
                  )}
                  isLoadingcancelInvoice={this.state.isLoadingcancelInvoice}
                />
              ) : null}
            </div>
          </div>
        </main>
      </div>
    );
  }
}
