import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../../components/common/pageLoader/PageLoader";
import Selector from "../../../components/dropdown/commonDropdown/Selector";
import { CommonValidationMessages } from "../../../utils/Messages";
import {
  isValidForm,
  validate,
} from "../../../utils/validation/CommonValidator";
import ValidationText from "../../../utils/validation/ValidationText";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import MultiSelectDropdown from "../../../components/dropdown/MultiSelectDropdown";
import Option from "../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
export default class EngagementReports extends Component {
  constructor(props) {
    super(props);
    this.financeServices = new FinanceServices();
    this.corporateMemberServices = new CorporateMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      temp: {
        filterByEngagementType: []
      },
      engagementtype: [],
      companies: [],
      maxDate: "",
      minDate: "",
      options: [
        {
          value: 1,
          label: "Event",
        },
        // {
        //     value: 2,
        //     label: "Membership",
        // }
      ],
      engagementReportDetail: {
        filterByCompanyName: "",
        filterByEngagementType: "",
        filterByEngagementDateTo: "",
        filterByEngagementDateFrom: "",
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      voucherReportList: [],
      isLoading: false,
      totalCount: 0,
      validState: {
        isValid: true,
        error: {},
      },
      validationRule: {
        currentDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "date for voucher code report"
            ),
          },
        ],
        voucherCodeUsedFor: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace(
              "{0}",
              "type of voucher code usage"
            ),
          },
        ],
      },
    };
  }

  componentDidMount = () => {
    this.getAllDropdownsForAllMemberReport();
    this.getAllEngagementReportList();
    this.GetAllEngagementReportcreatedat();

  };
  handleChangeMultiDropdown(selected, identity) {
    let temp = { ...this.state.temp };
    if (identity === "Engagement") {
      const maxSelections = 30;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.filterByEngagementType = limitedSelections;

      this.setState({
        temp: temp,
        filterByEngagementType: limitedSelections,
      });

    }
  }
  getAllDropdownsForAllMemberReport = () => {
    this.corporateMemberServices
      .getAllDropdownsForAllMemberReport()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // let engagementtype = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "CorporateEngagementType"
          // );
          let engagementtype = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "CorporateEngagementType")
            .map((item) => ({ value: item.id, label: item.name }));
          let companies = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Companies"
          );

          this.setState({
            engagementtype: engagementtype,
            companies: companies,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  reset = () => {
    let resetDetails = this.state.voucherReportRequest;
    resetDetails.currentDate = "";
    resetDetails.voucherCodeUsedFor = "";
    this.setState({ voucherReportRequest: resetDetails });
  };

  // validateField = (key) => {
  //     const newValidState = validate(
  //         key,
  //         this.state.voucherReportRequest,
  //         this.state.validationRule,
  //         this.state.validState
  //     );
  //     this.setState({ validState: newValidState });
  // };

  // isValidateAllFields = () => {
  //     const newValidState = isValidForm(
  //         this.state.voucherReportRequest,
  //         this.state.validationRule,
  //         this.state.validState
  //     );
  //     this.setState({ validState: newValidState });
  //     return newValidState.isValid;
  // };

  GetAllEngagementReportcreatedat = () => {

    this.financeServices
      .GetAllEngagementReportcreatedat()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          this.setState({
            maxDate: details.maxDate,
            minDate: details.minDate,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };
  getAllEngagementReportList = () => {
    this.setState({ isLoading: true });
    let pagination = { ...this.state.pagination };
    this.financeServices
      .getAllEngagementReportList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          this.setState({
            voucherReportList: details,
            totalCount: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  handleChangeInDropdown(selected, drpIdentity) {

    if (drpIdentity === "OrganisationName") {
      let dataArr = { ...this.state.engagementReportDetail };
      let companyname = this.state.companies.find((x) => x.id === selected);
      if (companyname.id > 0) {
        dataArr.filterByCompanyName = companyname.id;
      } else {
        dataArr.filterByCompanyName = "";
      }
      this.setState({ engagementReportDetail: dataArr });
    }
    //  else if (drpIdentity === "Engagement") {
    //   let dataArr = { ...this.state.engagementReportDetail };
    //   let companyname = this.state.engagementtype.find(
    //     (x) => x.id === selected
    //   );
    //   if (companyname.id > 0) {
    //     dataArr.filterByEngagementType = companyname.id;
    //   } else {
    //     dataArr.filterByEngagementType = "";
    //   }
    //   this.setState({ engagementReportDetail: dataArr });
    // }
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let date = this.state.engagementReportDetail;
    date[name] = value;
    this.setState({ engagementReportDetail: { ...date } });
  };

  generateEngagementReport = () => {

    // let isValidateAllFields = this.isValidateAllFields()
    // if (isValidateAllFields) {
    let companiesList = [];
    let engagementList = [];
    let nameOfCompanies = [];
    let engagementTypeName = [];
    let listofallCompanies = "";
    let listofallengagementType = "";
    this.setState({ isLoading: true });
    let request = {};


    if (this.state.engagementReportDetail.filterByEngagementDateTo == '') {
      request.filterByEngagementDateTo = this.state.maxDate;
      request.isAllDates = true;
    }
    else {
      request.filterByEngagementDateTo = this.state.engagementReportDetail.filterByEngagementDateTo
      request.isAllDates = false;
    }


    if (this.state.engagementReportDetail.filterByEngagementDateFrom == '') {
      request.filterByEngagementDateFrom = this.state.minDate;
      request.isAllDates = true;
    }
    else {
      request.filterByEngagementDateFrom = this.state.engagementReportDetail.filterByEngagementDateFrom
      request.isAllDates = false;
    }

    if (this.state.engagementReportDetail.filterByCompanyName == '') {
      companiesList = this.state.companies;
      nameOfCompanies = companiesList.filter((drp) => drp.id).map((drp) => drp.id);
      listofallCompanies = nameOfCompanies.join(", ");
      request.filterByCompanyName = listofallCompanies;
      request.isAllCompanies = true;
    }
    else {
      let company = this.state.companies.find(
        (x) => x.id === this.state.engagementReportDetail.filterByCompanyName
      );
      request.filterByCompanyName = company ? company.id : ""; // Provide default value
      request.isAllCompanies = false;
    }

    if (this.state.temp.filterByEngagementType == '') {
      engagementList = this.state.engagementtype;
      engagementTypeName = engagementList.filter((drp) => drp.value).map((drp) => drp.value);
      listofallengagementType = engagementTypeName.join(", ");
      request.filterByEngagementType = listofallengagementType;
      request.IsAllEngagementType = true;
    }
    else {
      var arr1 = this.state.temp.filterByEngagementType.map(function (item) { delete item.bad; return item.value; });
      request.filterByEngagementType = arr1.join([","]);
      request.IsAllEngagementType = false;
    }
    //request.filterByEngagementType = engagement ? engagement.name : ""; // Provide default value

    this.financeServices.generateEngagementReport(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Engagement record generated successfully.");
        // this.getAllVoucheripReportList();
        window.location.reload();
        this.reset();
      } else if (response.statusCode === 409) {
        this.swalServices.Error(response.message);
        this.reset();
      } else {
        this.swalServices.Error(response.message);
        this.reset();
      }
      this.setState({ isLoading: false });
    });

    // }
  };

  //   handleChangeForDropdown(selected, drpIdentity) {
  //     if (drpIdentity === "OrganisationName") {
  //         let dataArr = this.state.engagementReportDetail
  //         if (selected.id > 0) {
  //             dataArr.filterByCompanyName = selected.name;
  //         } else {
  //             dataArr.filterByCompanyName = "";
  //         }
  //         this.setState({ engagementReportDetail: dataArr })
  //     }
  //    else if (drpIdentity === "Engagement") {
  //         let dataArr = this.state.engagementReportDetail
  //         if (selected.id > 0) {
  //             dataArr.filterByEngagementType = selected.name;
  //         } else {
  //             dataArr.filterByEngagementType = "";
  //         }
  //         this.setState({ engagementReportDetail: dataArr })
  //     }
  // }
  downloadSheetByEngagementReportId = (
    engagementReportId
  ) => {
    this.setState({ isLoading: true });
    let request = [];
    request.push(engagementReportId);
    this.financeServices
      .downloadSheetByEngagementReportId(request)
      .then((response) => {
        if (response.status === 200 && response.data != null) {

          let blob = new Blob([response.data]);
          // saveAs(blob, `${voucherCodeUsedFor}_VoucherReport_${month}_${year}` + ".xlsx");
          saveAs(
            blob,
            `EngagementReport_.xlsx`
          );
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white">
          <div className="px-8 pt-10">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <h2 className="text-xl theme-color mb-1  2xl:col-span-3 lg:col-span-3 col-span-3 w-full">
                Search by Company Name
              </h2>
              {/* <input
                id="default"
                name="filterByCompanyName"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.engagementReportDetail.filterByCompanyName}
                onChange={(event) => this.handleChange(event)}
              /> */}
              <div className=" 2xl:col-span-9 lg:col-span-9 col-span-9 w-full">
                <DropdownSelect
                  drpIdentity="OrganisationName"
                  optionArray={this.state.companies}
                  allowSelectAll={false}
                  setFilterParameters={this.handleChangeInDropdown.bind(this)}
                  value={this.state.engagementReportDetail.filterByCompanyName}
                />
              </div>
            </div>
          </div>
          <div className="px-8 pt-10">
            <div className="grid grid-cols-12 gap-6 w-full items-center">
              <h2 className="text-xl theme-color mb-1 2xl:col-span-3 lg:col-span-3 col-span-3 w-full">
                Search by Engagement Type
              </h2>
              {/* <input
                id="default"
                name="filterByEngagementType"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.engagementReportDetail.filterByEngagementType}
                onChange={(event) => this.handleChange(event)}
              /> */}
              <div className="2xl:col-span-9 lg:col-span-9 col-span-9 w-full">
                {/* <DropdownSelect
                  drpIdentity="Engagement"
                  optionArray={this.state.engagementtype}
                  allowSelectAll={false}
                  setFilterParameters={this.handleChangeInDropdown.bind(this)}
                  value={
                    this.state.engagementReportDetail.filterByEngagementType
                  }
                // value={this.state.engagementtype.filter(item => item.label === this.state.engagementReportDetail.filterByEngagementType)}
                /> */}
                <MultiSelectDropdown
                  drpIdentity="Engagement"
                  options={this.state.engagementtype}
                  isMulti
                  closeMenuOnSelect={false}
                  components={{ Option }}
                  hideSelectedOptions={false}
                  onChange={this.handleChangeMultiDropdown.bind(this)}
                  allowSelectAll={true}
                  // value={this.state.temp.memberStatus}
                  value={
                    this.state.temp.filterByEngagementType
                  }
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
            <h2 className="text-xl theme-color mb-1 col-span-3">
              Search by Engagement Date
            </h2>
            <div className="col-span-9 grid grid-cols-12 gap-6">
              <div className="col-span-6 grid grid-cols-12 gap-6 items-center">
                <label
                  className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 col-span-2"
                  htmlFor="default"
                >
                  To<span className="text-[#C00000]">*</span>
                </label>
                <div className="col-span-10">
                  <input
                    id="filterByEngagementDateTo"
                    name="filterByEngagementDateTo"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.engagementReportDetail.filterByEngagementDateTo
                    ).format("yyyy-MM-DD")}
                    onChange={(event) => this.handleChange(event)}
                  // onBlur={() => this.validateField("currentDate")}
                  //    disabled
                  />
                </div>
                <ValidationText
                  error={this.state.validState.error.currentDate}
                />
              </div>
              <div className="col-span-6 grid grid-cols-12 gap-6 items-center">
                <label
                  className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 col-span-2"
                  htmlFor="default"
                >
                  From<span className="text-[#C00000]">*</span>
                </label>
                <div className="col-span-10">
                  <input
                    id="filterByEngagementDateFrom"
                    name="filterByEngagementDateFrom"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.engagementReportDetail
                        .filterByEngagementDateFrom
                    ).format("yyyy-MM-DD")}
                    onChange={(event) => this.handleChange(event)}
                  // onBlur={() => this.validateField("currentDate")}
                  //    disabled
                  />
                </div>
                <ValidationText
                  error={this.state.validState.error.currentDate}
                />
              </div>
            </div>

            <div className="col-span-4">
              <button
                className="btn btn-red text-xl border text-white mt-8"
                onClick={() => this.generateEngagementReport()}
              >
                Generate Engagement Report
              </button>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-center justify-center px-8 py-10">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              {this.state.isLoading ? (
                <PageLoader />
              ) : (
                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Generate Date
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          EngagementDate To
                        </div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          EngagementDate From
                        </div>
                      </th>

                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Organisation Name
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[40%]">
                        <div className="font-bold tble-th-txt text-left">
                          Engagement Type
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-center">
                          Action
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="scrollit text-sm custom-tbody">
                    {this.state.voucherReportList.map((e, key) => {
                      return (
                        <tr
                          className="custom-tbl-td flex items-center"
                          key={key}
                        >
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%] ">
                            {moment(e.generationDate).format(
                              "DD MMM YYYY"
                            )}
                          </td>


                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.isAllDates == true ? 'All' : moment(e.filterByEngagementDateTo).format(
                              "DD MMM YYYY"
                            )}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[40%]">
                            {e.isAllDates == true ? 'All' : moment(e.filterByEngagementDateFrom).format(
                              "DD MMM YYYY"
                            )}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-normal break-words tble-td-txt text-[#757575] w-[40%] max-w-xs">
                            {e.isAllCompanies === true ? 'All' : e.filterByCompanyName}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-normal break-words tble-td-txt text-[#757575] w-[40%] max-w-xs">
                            {e.isAllEngagementType === true ? 'All' : e.filterByEngagementType}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                            <button
                              className="action-btn mx-auto flex items-center"
                              aria-controls="add-edit-modal"
                              onClick={() => {
                                this.downloadSheetByEngagementReportId(e.engagementReportId);
                              }}
                            >
                              <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                                Download
                              </p>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
