

import React, { Component } from 'react'
import DropdownSelect from '../../../components/dropdown/Dropdown'
import { Link, Navigate } from "react-router-dom";
import SwalServices from '../../../services/swalServices/SwalServices';
import LeadershipPositionsServices from '../../../services/axiosServices/apiServices/LeadershipPositionsServices';
import CommonServices from '../../../services/axiosServices/apiServices/CommonServices';
import Table from '../../../components/table/Table'
import CreateButton from '../../../components/UIComponent/Buttons/CreateButton';
import { CommonSuccessMessages } from '../../../utils/Messages';
import { SuccessMessage } from '../../../utils/Messages';
import ActionEditButton from '../../../components/UIComponent/ActionTableComponent/ActionEditButton';
import ActionContainer from '../../../components/UIComponent/ActionTableComponent/actionContainer';
import ActionToggleButton from '../../../components/UIComponent/ActionTableComponent/ActionToggleButton';
import ActionDeleteButton from '../../../components/UIComponent/ActionTableComponent/ActionDeleteButton';
import { encryptAES } from '../../../utils/Encryption';
import TextInput from '../../../components/UIComponent/TextInput';


export default class ManageCommitteeMembers extends Component {

  editUser = (value, index) => {
    <Link to='/AddEditCommitteemembers' />
  };

  constructor(props) {
    super(props)
    this.LeadershipPositionsServices = new LeadershipPositionsServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.onPageChange = null;
    this.state = {
      totalResultes: "",
      getCommitteeDetailsList: [],
      status: [],
      setToggle2: false,
      redirect: null,
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      },
      committeeDetails: {
        committeeTitle: "",
        description: "",
        arrangementPosition: 0,
        committeeDetailsId: 0,
      },

      addEditDropdown: false,
      dropdownId: 0,
      arrangementPosition: 0,
      length: 0,
      SearchBySGTechDesignation: [0],
      SearchByLeadershipStatus: [0]
    }
  }


  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.leadershipPosition;
    detail[name] = value;
    this.setState({ leadershipPosition: { ...detail } });
  }

  onEdit = (value, index) => {
    let editCommitteePosition = [...this.state.getCommitteeDetailsList];
    this.setState({ redirect: '/AddEditCommitteeMembers/committeeDetailsId?=' + encryptAES(editCommitteePosition[index].committeeDetailsId) });
  }



  onDelete = (value) => {

    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Committee Detail"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Committee Detail"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.LeadershipPositionsServices.deleteCommitteeDetails(request)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success(
                  SuccessMessage.Delete_Success.replace("{0}", "Committee Detail")
                );
                this.getCommitteeDetailsList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });

  };


  // getAllStatus = () => {
  //   this.CommonServices.getAllStatus().then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       this.setState({ status: response.responseItem.responseContent });
  //     }
  //     else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isLoading: false });
  //   })
  // }


  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.pagination;
    if (drpIdentity === "Statuses") {

      detail.SearchByLeadershipStatus = id;
      this.setState({ pagination: { ...detail } });
    }
    this.getLeadershipPositionsList();
  }


  // handleChangeEvent = (value) => {
  //   let detail = this.state.pagination;
  //   detail.searchText = "";
  //   if (value.length > 2) {
  //     detail.searchText = value;
  //     this.setState({ pagination: { ...detail } });

  //   }
  //   this.getLeadershipPositionsList();
  // }


  getCommitteeDetailsList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.LeadershipPositionsServices.getCommitteeDetailsList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ getCommitteeDetailsList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount })
        this.setState({
          length: response.responseItem.responseContent.totalCount + 1,
        });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getCommitteeDetailsList();
  };

  // setPagination = (newPagination) => {

  //   this.setState({ pagination: newPagination });
  //   // if (this.onPageChange) {
  //   //   this.onPageChange();
  //   // }
  //   this.getCommitteeDetailsList();
  // }
  //   actions = (element, index, value) => {
  //     return (element !== "leadershipPositionId") ? null
  //       :
  //       <td>
  //       <ActionContainer>
  //         {/* <ActionToggleButton
  //             className="mr-2"
  //             index = {index}
  //             /> */}

  //         <ActionToggleButton
  //           className="mr-2"
  //           index={index}
  //           // toggle={this.state.toggle}
  //           data={this.state.getLeadershipPositions}
  //           // onToggleChage={this.onToggleChage.bind(this)}
  //           onToggleChage=""
  //         />

  //         <ActionEditButton
  //           value={value}
  //           id={index}
  //           index={index}
  //           onActionClick={this.onEdit}
  //           text="Edit"
  //         />
  //         <ActionDeleteButton
  //           value={value}
  //           id={index}
  //           index={index}
  //           onActionClick={this.onDelete}
  //           text="Delete"
  //         />
  //       </ActionContainer>
  //       </td>

  //   }

  resetCommitteeMemberData() {
    let details = { ...this.state.committeeDetails };
    details.arrangementPosition = 0;
    details.committeeDetailsId = 0;
    details.committeeTitle = "";
    details.description = "";
    this.setState({ committeeDetails: details });
  }
  getCommitteeTableData = (Id) => {
    this.resetCommitteeMemberData();
    this.setState({ addEditDropdown: true });
    this.LeadershipPositionsServices.getCommitteeDetailsById(Id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let detail = { ...this.state.committeeDetails };
          detail.arrangementPosition =
            response.responseItem.responseContent.arrangementPosition;
          detail.committeeDetailsId =
            response.responseItem.responseContent.committeeDetailsId;
          detail.committeeTitle = response.responseItem.responseContent.committeeTitle;
          detail.description =
            response.responseItem.responseContent.description;
          this.setState({ committeeDetails: detail });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };


  actions = (element, index, value) => {
    return element !== "committeDetailsId" ? null : (
      <td>
        <ActionContainer>
          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEdit.bind(this)}
            text="Edit"
          />
          {/* {this.state.getResourcePagesList[index].pageTypeId === 3 ? ( */}
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
          {/* ) : null} */}
        </ActionContainer>
      </td>
    );
  };


  componentDidMount() {
    if (!this.onPageChange)
      this.onPageChange = this.getCommitteeDetailsList;
    this.getCommitteeDetailsList();
    // this.getAllStatus();

  }
  cancelAddEditDropdown() {
    this.setState({ addEditDropdown: false });
    this.resetCommitteeMemberData();
  }
  handleChangeForarrangementPosition(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.committeeDetails;
    if (name === "arrangementPosition") {
      let chcklength = this.state.length - 1;
      if (value > chcklength) {
        detail[name] = parseInt(chcklength);
      } else {
        detail[name] = parseInt(value);
      }
    } else {
      detail[name] = value;
    }
    this.setState({ committeeDetails: detail });
  }

  addEditCommitteeDetails = (committeeDetailsId) => {
    this.setState({ addEditDropdown: false });
    let request = { ...this.state.committeeDetails };

    request.arrangementPosition = this.state.committeeDetails.arrangementPosition;
    request.committeeTitle = this.state.committeeDetails.committeeTitle;
    request.description = this.state.committeeDetails.description;
    request.committeeDetailsId = committeeDetailsId;

    this.LeadershipPositionsServices.addEditCommitteeDetails(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
          this.swalServices.Success("Ordering Position Updated Successfully!");
          this.getCommitteeDetailsList();
          this.setState({ addEditDropdown: false });
        } else if (response.responseItem.responseContent.hasError === true) {
          this.swalServices.Alert(response.responseItem.responseContent.errorMessage);
        
        }
      });
  };

  

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className='main-body-section'>
        <div className="flex">
          <div className="relative flex flex-col flex-1 ">
            <main className='pb-10'>
              {/* <div className='custom-card shadow-lg mb-10'>
                <div className='filter-info grid grid-cols-12 gap-6'> */}
              {/* <div className='filter-types xl:col-span-4'>
                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Search by Title</label>
                    <input id="default" className="form-input rounded-none w-full shadow-red py-3 text-lg" type="text" onChange={(e) => this.handleChangeEvent(e.target.value)} />
                  </div> */}
              {/* <div className='filter-types xl:col-span-4'>
                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Category</label>
                    <DropdownSelect
                      value={this.state.SearchBySGTechDesignation} />
                  </div> */}
              {/* <div className='filter-types xl:col-span-4'>
                    <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Status</label>
                    <DropdownSelect
                      drpIdentity={"Statuses"}
                      optionArray={this.state.status}
                      setFilterParameters={this.setFilterParameters.bind(this)}
                      value={this.state.SearchByLeadershipStatus} />
                  </div> */}
              {/* 
                </div>
              </div> */}
              <div className="w-full mx-auto">
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  <div className="mb-4 sm:mb-0">
                    <h1 className="table-title-txt theme-color font-bold">
                      Total: {this.state.totalResultes}
                    </h1>
                  </div>
                  {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                    <Link to='/AddEditLeadershipPosition' >
                      <button className="btn btn-red text-white" >
                        <span className="text-xl text-white font-semibold tracking-normal"> Create New </span>
                      </button></Link>
                  </div> */}
                  <CreateButton
                    redirect='/AddEditCommitteeMembers'
                    text='Create New'
                  />
                </div>
                <div className="relative">
                  <div>
                    {/* Table */}
                    {/* <div className="">
                      <Table columns={[{ name: 'committeeTitle', title: 'Title' } ,{ name: 'arrangementPosition', title: 'Arrangement Postion' }, { name: "committeDetailsId", title: "Action" },]}
                        rows={this.state.getCommitteeDetailsList}
                        isLoading={this.state.isLoading}
                        sortingColumns={["leadershipName", "statusName"]}
                        pagination={this.state.pagination}
                        totalResultes={this.state.totalResultes}
                        totalCount={this.state.totalResultes}
                        setPagination={this.setPagination.bind(this)}
                        customScope={[
                          {
                            column: 'committeDetailsId',
                            renderTableData: this.actions.bind(this)
                          },
                        ]} />
                    </div> */}
                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                      <thead className="table-bg text-xs font-semibold capitalize text-white border-t border-b border-slate-200">
                        <tr>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                            <div className="font-bold tble-th-txt text-left">
                              Title
                            </div>
                          </th>
                          <th className="px-3 first:pl-3 last:pr-5 tbl-th whitespace-nowrap text-white w-[55%]">
                            <div className="font-bold tble-th-txt text-left flex items-center justify-center">
                              Arrangement Postion
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                            <div className="font-bold tble-th-txt text-left">
                              Action
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="scrollit text-sm custom-tbody">
                        {(this.state.getCommitteeDetailsList &&
                          this.state.getCommitteeDetailsList.length === 0) ||
                          this.state.getCommitteeDetailsList.length === null ? (
                          <tr className="">
                            <td
                              className="w-full text-lg text-center py-4 text-[#757575] "
                              colSpan="100%"
                            >
                              No Records Found
                            </td>
                          </tr>
                        ) : (this.state.getCommitteeDetailsList.map((e, key) => (
                          <tr key={key}>
                            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
                              {e.committeeTitle}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap flex justify-center mt-5 tble-td-txt pointer text-[#757575]">
                              <div className="flex flex-col">
                                {this.state.addEditDropdown === true &&
                                  this.state.arrangementPosition ===
                                  e.arrangementPosition &&
                                  this.state.dropdownId ===
                                  e.committeeDetailsId ? (
                                  <div
                                    key={key}
                                    className="flex flex-col relative"
                                  >
                                    <div
                                      className={`edit-field input-height-set center-text`}
                                    >
                                      <TextInput
                                        placeholder=""
                                        type="number"
                                        name="arrangementPosition"
                                        max={this.state.length - 1}
                                        value={
                                          this.state.committeeDetails
                                            .arrangementPosition === 0
                                            ? 0
                                            : this.state.committeeDetails
                                              .arrangementPosition
                                        }
                                        handleChange={(event) =>
                                          this.handleChangeForarrangementPosition(
                                            event
                                          )
                                        }
                                        // onBlur={() => props.validateField("arrangementPosition")}
                                        identity="DropdownarrangementPosition"
                                        min={0}
                                      />
                                    </div>
                                    <div className="absolute bottom-[-22px]">
                                      {/* <ValidationText
                                                    error={props.validState.error.arrangementPosition}
                                                    /> */}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center gap-3">
                                    <div className="current-text text-center">
                                      {e.arrangementPosition}
                                    </div>
                                    <button
                                      className="action-btn"
                                      aria-controls="add-edit-modal"
                                      // onClick={() => {
                                      //     props.getTableDataById(
                                      //     props.selectedDropdownId,
                                      //     dropdown.value
                                      //     );
                                      //     setDropdownId(dropdown.value);
                                      //     setarrangementPosition(dropdown.arrangementPosition);
                                      // }}

                                      onClick={() => {
                                        this.setState(
                                          {
                                            dropdownId:
                                              e.committeeDetailsId,
                                            arrangementPosition:
                                              e.arrangementPosition,
                                          },
                                          () => {
                                            // this.getFeaturedNewMembersList();
                                            this.getCommitteeTableData(
                                              e.committeeDetailsId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      <p className="ml-2 tool-tip-txt underline text-[#757575]">
                                        <svg
                                          className="menu-svg"
                                          width="24"
                                          height="24"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 88 88"
                                        >
                                          <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
                                            <path
                                              d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z"
                                              fill="#6e6e6e"
                                            />
                                          </g>
                                        </svg>
                                      </p>
                                    </button>

                                  </div>

                                )}
                              </div>
                              {/* {e.arrangementPosition} */}
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                              {this.state.addEditDropdown === true &&
                                this.state.dropdownId ===
                                e.committeeDetailsId ? (
                                <div className="flex items-center edit-field">
                                  <button
                                    className='action-btn mr-1 flex items-center'
                                    aria-controls="add-edit-modal"
                                    onClick={() => {
                                      this.addEditCommitteeDetails(
                                        e.committeeDetailsId
                                      );
                                    }}
                                  >
                                    <p className="ml-2 tool-tip-txt text-[#c00000] underline cursor-pointer">
                                      Save
                                    </p>
                                  </button>
                                  <button
                                    // className="action-btn mr-4 flex items-center"
                                    aria-controls="add-edit-modal"
                                    onClick={() => {
                                      this.cancelAddEditDropdown();
                                    }}
                                  >
                                    <img
                                      src="/cancel-icon.png"
                                      className="w-[22px] mx-auto my-0"
                                      alt=""
                                    ></img>
                                  </button>
                                </div>
                              ) : (<ActionContainer>
                                <ActionEditButton
                                  value={e.committeeDetailsId}
                                  id={key}
                                  index={key}
                                  onActionClick={this.onEdit}
                                  text="Edit"
                                />
                                <ActionDeleteButton
                                  value={e.committeeDetailsId}
                                  id={key}
                                  index={key}
                                  onActionClick={this.onDelete.bind(this)}
                                  text="Delete"
                                />

                              </ActionContainer>)
                              }
                            </td>
                          </tr>
                        )))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

      </div>
    )
  }

}
