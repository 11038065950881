import React from "react";
import ModalBasic from "../../../pages/component/ModalBasic";
import moment from "moment";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import TextInput from "../../../components/UIComponent/TextInput";

const ManagePostingModal = (props) => {
  const data = props.getMemberDirectoryData[props.councilIndex];

  return (
    <ModalBasic
      id="show-representative-details-modal"
      modalOpen={props.setRepresentativeDetailsModal}
      setModalOpen={props.setOpenModal}
      title={
        <div className="flex items-center  justify-space-between">
          <h2 className="text-xxl font-semibold modal-title">VIEW RESPONSE</h2>
        </div>
      }
    >
      <>
        <div className="p-6 bg-white rounded-lg shadow-lg">
          <div className="w-full">
            {data.firstRepresentativeName !== null &&
            data.firstRepresentativeEmail !== null &&
            data.firstRepresentativeNumber !== null ? (
              <div className="border-card">
                <div className="grid lg:gap-4 gap-2 grid-cols-12 items-center">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Company Name:
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8 ">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="name"
                      //   value={props.adminDetails.name}
                      //   identity="adminDetails"
                      //   handleChange={props.handleChange}
                      //   onBlur={(e) => props.ValidateAdminDetails("name")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>
                <div className="grid lg:gap-4 gap-2 grid-cols-12 items-center mt-4">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Title :<span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8 ">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="name"
                      //   value={props.adminDetails.name}
                      //   identity="adminDetails"
                      //   handleChange={props.handleChange}
                      //   onBlur={(e) => props.ValidateAdminDetails("name")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>

                <div className="grid lg:gap-4 gap-2 grid-cols-12  mt-4">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Description:
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8">
                    <textarea
                      className="w-full h-[120px]"
                      placeholder=""
                      id="meta_description"
                      name="metaDescription"
                      rows="2"
                      // value={props.categoryCampaignItem.metaDescription ? props.categoryCampaignItem.metaDescription : ""}
                      // onChange={(event) => props.handleChange(event)}
                      // onBlur={() => props.validateField("metaDescription")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>

                <div className="grid lg:gap-4 gap-2 grid-cols-12 items-center mt-4">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Supported Documents:
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8 ">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="name"
                      //   value={props.adminDetails.name}
                      //   identity="adminDetails"
                      //   handleChange={props.handleChange}
                      //   onBlur={(e) => props.ValidateAdminDetails("name")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>

                <div className="grid lg:gap-4 gap-2 grid-cols-12 items-center mt-4">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Deadline:
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8 ">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="name"
                      //   value={props.adminDetails.name}
                      //   identity="adminDetails"
                      //   handleChange={props.handleChange}
                      //   onBlur={(e) => props.ValidateAdminDetails("name")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>

                <div className="grid lg:gap-4 gap-2 grid-cols-12 items-center mt-4">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Posted On:
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8 ">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="name"
                      //   value={props.adminDetails.name}
                      //   identity="adminDetails"
                      //   handleChange={props.handleChange}
                      //   onBlur={(e) => props.ValidateAdminDetails("name")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>

                <div className="grid lg:gap-4 gap-2 grid-cols-12 items-center mt-4">
                  <h2 className="text-xl font-bold theme-color mb-1 2xl:col-span-4  lg:col-span-4 col-span-4">
                    Posted By:
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="2xl:col-span-8 lg:col-span-8 col-span-8 ">
                    <TextInput
                      placeholder=""
                      type="text"
                      name="name"
                      //   value={props.adminDetails.name}
                      //   identity="adminDetails"
                      //   handleChange={props.handleChange}
                      //   onBlur={(e) => props.ValidateAdminDetails("name")}
                    />
                  </div>
                  {/* <ValidationText
                        error={props.validState.error.name}
                      /> */}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    </ModalBasic>
  );
};

export default ManagePostingModal;
