import { Component } from "react-image-crop";
import CreateButton from "../../../components/UIComponent/Buttons/CreateButton";
import Table from "../../../components/table/Table";
import ActionEditButton from "../../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import LeadershipPositionsServices from "../../../services/axiosServices/apiServices/LeadershipPositionsServices";
import { encryptAES } from "../../../utils/Encryption";
import { Navigate } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import TextInput from "../../../components/UIComponent/TextInput";

export default class ManageSecretariatDetails extends Component {
    constructor(props) {
        super(props);
        this.LeadershipPositionsServices = new LeadershipPositionsServices();
        this.swalServices = new SwalServices();
        this.onPageChange = null;
        this.state = {
            route: "",
            isLoading: false,
            totalResultes: 0,
            SecretariatDetails: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            secretariatDetails: {
                secretariatDetailsId: 0,
                name: "",
                designation: "",
                description: "",
                positionId: 0,
                linkedin: "",
                updatedBy: 0,
                updatedAppId: 0,
            },
            addEditDropdown: false,
            positionId: 0,
            dropdownId: 0,
            length: 0,
        };
    }
    componentDidMount() {
        if (!this.onPageChange)
            this.onPageChange = this.getAllSecretariatDetailsList;
        this.getAllSecretariatDetailsList();
    }
    setPagination = (newPagination) => {

        this.setState({ pagination: newPagination });
        if (this.onPageChange) {
            this.onPageChange();
        }
        this.getAllSecretariatDetailsList();
    }
    actions = (element, index, value) => {
        return (element !== "SecretariatDetailsId") ? null
            : (
                <td>
                    <ActionContainer>

                        <ActionEditButton
                            value={value}
                            id={index}
                            index={index}
                            onActionClick={this.onEdit}
                            text="Edit"
                        />
                        <ActionDeleteButton
                            value={value}
                            id={index}
                            index={index}
                            onActionClick={this.onDelete}
                            text="Delete"
                        />
                    </ActionContainer>
                </td>
            )
    }
    onEdit = (id) => {
        this.setState({ route: '/AddEditSecretariatDetails/SecretariatDetailsId?=' + encryptAES(id) });
    }
    onDelete = (SecretariatDetailsId) => {
        let request = {
            id: SecretariatDetailsId,
            deletedBy: 115,
            appId: 15
        }

        this.LeadershipPositionsServices.deleteSecretariatDetails(request).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ status: response.responseItem.responseContent });
                this.swalServices.Success("Deleted");
                this.getAllSecretariatDetailsList();
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }
    getAllSecretariatDetailsList = (pagination = this.state.pagination) => {
        this.setState({ isLoading: true });
        this.LeadershipPositionsServices.getAllSecretariatDetailsList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                const SecretariatDetails = response.responseItem.responseContent.itemList.map(item => {
                    return {
                        ...item,
                        description: this.decodeHTMLEntities(item.description.replace(/<[^>]*>/g, ''))
                    };
                });
                this.setState({ SecretariatDetails: SecretariatDetails });
                this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
                this.setState({
                    length: response.responseItem.responseContent.totalCount + 1,
                });
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        })
    }

    decodeHTMLEntities = (text) => {
        const doc = new DOMParser().parseFromString(text, "text/html");
        return doc.documentElement.textContent;
    }
    resetSecretariatDetailData() {
        let details = { ...this.state.secretariatDetails };
        details.secretariatDetailsId = 0;
        details.name = "";
        details.designation = "";
        details.description = "";
        details.positionId = 0;
        details.linkedin = "";
        this.setState({ secretariatDetails: details });
    }
    getSecretariatTableData = (Id) => {
        this.resetSecretariatDetailData();
        this.setState({ addEditDropdown: true });
        this.LeadershipPositionsServices.getSecretariatDetailsById(Id)
            .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    let detail = { ...this.state.secretariatDetails };
                    detail.secretariatDetailsId =
                        response.responseItem.responseContent.secretariatDetailsId;
                    detail.name =
                        response.responseItem.responseContent.name;
                    detail.designation = response.responseItem.responseContent.designation;
                    detail.description =
                        response.responseItem.responseContent.description;
                    detail.positionId = response.responseItem.responseContent.positionId;
                    detail.linkedin =
                        response.responseItem.responseContent.linkedin;
                    this.setState({ secretariatDetails: detail });
                } else {
                    this.swalServices.Error(response.message);
                }
            });
    };

    handleChangeForPositionId(event) {
        const value = event.target.value;
        const inputName = event.target.name;
        let detail = { ...this.state.secretariatDetails };
        if (inputName === "positionId") {
            let chcklength = this.state.length - 1;
            if (value > chcklength) {
                detail[inputName] = parseInt(chcklength);
            } else {
                detail[inputName] = parseInt(value);
            }
        } else {
            detail[inputName] = value;
        }
        this.setState({ secretariatDetails: detail });
    }

    updateSecretariatDetails = (secretariatDetailsId) => {
        this.setState({ addEditDropdown: false });
        let request = { ...this.state.secretariatDetails };

        request.secretariatDetailsId = secretariatDetailsId;
        request.name = this.state.secretariatDetails.name;
        request.designation = this.state.secretariatDetails.designation;
        request.description = this.state.secretariatDetails.description;
        request.positionId = this.state.secretariatDetails.positionId;
        request.linkedin = this.state.secretariatDetails.linkedin;
        request.updatedBy = this.state.secretariatDetails.updatedBy;
        request.updatedAppId = this.state.secretariatDetails.updatedAppId;

        this.LeadershipPositionsServices.updateSecretariatDetails(request)
            .then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Ordering Position Updated Successfully!");
                    this.getAllSecretariatDetailsList();
                    this.setState({ addEditDropdown: false });
                } else {
                    this.swalServices.Error(response.message);
                }

            });
    };
    cancelAddEditDropdown() {
        this.setState({ addEditDropdown: false });
        this.resetSecretariatDetailData();
    }

    render() {
        if (this.state.route !== "") {
            return <Navigate to={this.state.route} />;
        }
        return (
            <div className="main-body-section">
                <div className="flex">
                    <div className="relative flex flex-col flex-1">
                        <main className="pb-10">
                            <div className="w-full mx-auto">
                                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="table-title-txt theme-color font-bold">
                                            {this.state.totalResultes} Secretariat Member
                                        </h1>
                                    </div>
                                    <CreateButton
                                        redirect="/AddEditSecretariatDetails"
                                        text="Create New"
                                    />
                                </div>
                                <div className="relative">
                                    {/* <Table
                                        columns={[
                                            { name: "name", title: "Name" },
                                            { name: "designation", title: "Designation" },
                                            { name: "description", title: "Description" },
                                            { name: "positionId", title: "Arrangement Position" },
                                            {
                                                name: "SecretariatDetailsId",
                                                title: "Action",
                                            },
                                        ]}
                                        rows={this.state.SecretariatDetails}
                                        isLoading={this.state.isLoading}
                                        pagination={this.state.pagination}
                                        sortingColumns={[]}
                                        totalResultes={this.state.totalResultes}
                                        totalCount={this.state.totalResultes}
                                        setPagination={this.setPagination.bind(this)}
                                        customScope={[
                                            {
                                                column: 'SecretariatDetailsId',
                                                renderTableData: this.actions.bind(this)
                                            },
                                        ]}
                                    /> */}

                                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                                        <thead className="table-bg text-xs font-semibold capitalize text-white border-t border-b border-slate-200">
                                            <tr>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Name
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Designation
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Description
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Arrangement Position
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Action
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="scrollit text-sm custom-tbody">
                                            {(this.state.SecretariatDetails &&
                                                this.state.SecretariatDetails.length === 0) ||
                                                this.state.SecretariatDetails.length === null ? (
                                                <tr className="">
                                                    <td
                                                        className="w-full text-lg text-center py-4 text-[#757575] "
                                                        colSpan="100%"
                                                    >
                                                        No Records Found
                                                    </td>
                                                </tr>
                                            ) :
                                                (this.state.SecretariatDetails.map((e, key) => (
                                                    <tr key={key}>
                                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                            {e.name}
                                                        </td>
                                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                            {e.designation}
                                                        </td>
                                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                            {e.description}
                                                        </td>
                                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                            <div className="flex flex-col">
                                                                {this.state.addEditDropdown === true &&
                                                                    this.state.positionId ===
                                                                    e.positionId &&
                                                                    this.state.dropdownId ===
                                                                    e.secretariatDetailsId ? (
                                                                    <div
                                                                        key={key}
                                                                        className="flex flex-col relative"
                                                                    >
                                                                        <div
                                                                            className={` edit-field input-height-set center-text`}
                                                                        >
                                                                            <TextInput
                                                                                placeholder=""
                                                                                type="number"
                                                                                name="positionId"
                                                                                max={this.state.length - 1}
                                                                                value={
                                                                                    this.state.secretariatDetails
                                                                                        .positionId === 0
                                                                                        ? 0
                                                                                        : this.state.secretariatDetails
                                                                                            .positionId
                                                                                }
                                                                                handleChange={this.handleChangeForPositionId.bind(
                                                                                    this
                                                                                )}
                                                                                // onBlur={() => props.validateField("positionId")}
                                                                                identity="DropdownpositionId"
                                                                                min={0}
                                                                            />
                                                                        </div>
                                                                        <div className="absolute bottom-[-22px]">
                                                                            {/* <ValidationText
                                                    error={props.validState.error.positionId}
                                                    /> */}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex items-center gap-3">
                                                                        <div className="current-text text-center">
                                                                            {e.positionId}
                                                                        </div>
                                                                        <button
                                                                            className="action-btn"
                                                                            aria-controls="add-edit-modal"
                                                                            // onClick={() => {
                                                                            //     props.getTableDataById(
                                                                            //     props.selectedDropdownId,
                                                                            //     dropdown.value
                                                                            //     );
                                                                            //     setDropdownId(dropdown.value);
                                                                            //     setpositionId(dropdown.positionId);
                                                                            // }}

                                                                            onClick={() => {
                                                                                this.setState(
                                                                                    {
                                                                                        dropdownId:
                                                                                            e.secretariatDetailsId,
                                                                                        positionId:
                                                                                            e.positionId,
                                                                                    },
                                                                                    () => {
                                                                                        // this.getFeaturedNewMembersList();
                                                                                        this.getSecretariatTableData(
                                                                                            e.secretariatDetailsId
                                                                                        );
                                                                                    }
                                                                                );
                                                                            }}
                                                                        >
                                                                            <p className="ml-2 tool-tip-txt underline text-[#757575]">
                                                                                <svg
                                                                                    className="menu-svg"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 88 88"
                                                                                >
                                                                                    <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
                                                                                        <path
                                                                                            d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z"
                                                                                            fill="#6e6e6e"
                                                                                        />
                                                                                    </g>
                                                                                </svg>
                                                                            </p>
                                                                        </button>

                                                                    </div>

                                                                )}
                                                            </div>
                                                            {/* {e.positionId} */}
                                                        </td>
                                                        <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                                                            {this.state.addEditDropdown === true &&
                                                                this.state.dropdownId ===
                                                                e.secretariatDetailsId ? (
                                                                <div className="flex items-center edit-field">
                                                                    <button
                                                                        className='action-btn mr-1 flex items-center'
                                                                        aria-controls="add-edit-modal"
                                                                        onClick={() => {
                                                                            this.updateSecretariatDetails(
                                                                                e.secretariatDetailsId
                                                                            );
                                                                        }}
                                                                    >
                                                                        <p className="ml-2 tool-tip-txt text-[#c00000] underline cursor-pointer">
                                                                            Save
                                                                        </p>
                                                                    </button>
                                                                    <button
                                                                        // className="action-btn mr-4 flex items-center"
                                                                        aria-controls="add-edit-modal"
                                                                        onClick={() => {
                                                                            this.cancelAddEditDropdown();
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="/cancel-icon.png"
                                                                            className="w-[22px] mx-auto my-0"
                                                                            alt=""
                                                                        ></img>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <ActionContainer>
                                                                    <ActionEditButton
                                                                        value={e.secretariatDetailsId}
                                                                        id={key}
                                                                        index={key}
                                                                        onActionClick={this.onEdit}
                                                                        text="Edit"
                                                                    />
                                                                    <ActionDeleteButton
                                                                        value={e.secretariatDetailsId}
                                                                        id={key}
                                                                        index={key}
                                                                        onActionClick={this.onDelete.bind(this)}
                                                                        text="Delete"
                                                                    />

                                                                </ActionContainer>
                                                            )}

                                                        </td>
                                                    </tr>
                                                )))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        );
    }
}
