import { Component } from "react";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import moment from "moment";
import Option from "../../../components/dropdown/multiSelectDropdownOption/MultiSelectOption";
import MultiSelectDropdown from "../../../components/inputs/MultiSelectDropdown";
import { validate, isValidForm } from '../../../utils/validation/CommonValidator'
import { CommonValidationMessages } from '../../../utils/Messages'
import { saveAs } from "file-saver";
import ValidationText from "../../../utils/validation/ValidationText"
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
export default class AllMemberReports extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      isLoading: false,
      validState: {
        isValid: true,
        error: {},
      },
      validationRule: {
        // countriesdata: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "Country"),
        //   },
        // ],
        // activities: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "Services"),
        //   },
        // ],
        // industriesServeddata: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "Industries"),
        //   },
        // ],
        memberStatus: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Country"),
          },
        ], memberType: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Member Type"),
          },
        ],
        // startDate: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "Admission From Date"),
        //   },
        // ], endDate: [
        //   {
        //     type: "require",
        //     message: CommonValidationMessages.SelectRequired.replace("{0}", "Admission To Date"),
        //   },
        // ],
      },
      startDate: "",
      endDate: "",
      //Dropdown Arrays
      status: [],
      membershipType: [],
      countries: [],
      serviceType: [],
      industriesServed: [],
      selectedFilters: {
        MemberStatus: null,
        MemberType: null,
        Country: null,
        Services: null,
        Industries: null,
      },
      temp: {
        memberType: [],
        memberStatus: [],
        countriesdata: [],
        activities: [],
        industriesServeddata: [],
        startDate: "",
        endDate: ""
      },
      companyInformation: {
        isAllCompanyInformation: false,
        companyName: false,
        membershipTypeId: false,
        registeredAddress: false,
        registeredStreetName: false,
        registeredBuildingName: false,
        registeredNoUnitNumber: false,
        registeredPostalCode: false,
        registeredCountryId: false,
        mailingStreetName: false,
        mailingBuildingName: false,
        mailingNoUnitNumber: false,
        mailingPostalCode: false,
        mailingCountryId: false,
        mailingAddress: false,
        postalCode: false,
        approvalDate: false,
        companyNumber: false,
        generalBusinessTelephone: false,
        // companyFax: false,
        incorporationDate: false,
        paidUpCapital: false,
        membershipRevenue: false,
        organisationWebsite: false,
        commencementDate: false,
        employmentSize: false,
        isMTORegistry: false,
        generalEmailAddress: false,
        uen: false,
        statusId: false,
        slaMembershipApprovalDate: false,
        mtoMembershipApprovalDate: false,
        aCRAFilePath: false,
        orgLogoFileName: false,
        subscriptionStartDate: false,
        subscriptionEndDate: false
      },

      compnanyPresence: {
        countriesPresenceId: false,
        industriesServedId: false,
        servicesTypeId: false,
        getToKnowId: false,
        associationBenefitsId: false,
      },

      companyContactInformation: {
        ceoName: false,
        ceoDesignation: false,
        ceoMobile: false,
        ceoEmail: false,
        ceoOfficeDID: false,
        ceoEmailSubscriptionId: false,
        hrName: false,
        hrDesignation: false,
        hrEmail: false,
        hrMobile: false,
        hrOfficeDID: false,
        hrEmailSubscriptionId: false,
        firstRepName: false,
        firstRepDesignation: false,
        firstRepMobile: false,
        firstRepEmail: false,

        firstRepOfficeDID: false,
        firstRepEmailSubscriptionId: false,
        secondRepName: false,
        secondRepDesignation: false,
        secondRepEmail: false,

        secondRepMobile: false,
        secondRepOfficeDID: false,
        secondRepEmailSubscriptionId: false,
        womenExecutiveName: false,
        womenExecutiveDesignation: false,
        womenExecutiveMobile: false,
        womenExecutiveEmail: false,
        womanexecutiveOfficeDID: false,
        womanexecutiveEmailSubscriptionId: false,
        youthExecutiveName: false,
        youthExecutiveDesignation: false,
        youthExecutiveEmail: false,
        youthexecutiveOfficeDID: false,
        youthexecutiveEmailSubscriptionId: false,
        youthExecutiveMobile: false,
        financeName: false,
        financeDesignation: false,
        finananceEmail: false,
        financeOfficeDID: false,
        financeEmailSubscriptionId: false,
        financeMobile: false,
        experienceYears: false,
        yearsId: false,
        // otherContactPersonName: false,
        // otherContactPersonDesignation: false,
        // otherContactPersonEmail: false,
        // otherContactPersonOffTel: false,
        // otherContactPersonMobile: false,
      },

      companyEndroserInfomation: {
        proposerName: false,
        proposerCompany: false,
        secondaryName: false,
        secondaryCompany: false,
        reinstatementCouncilApprovalDate: false,
        reinstatementDate: false,
        slaMembershipTerminationDate: false,
        mtoTerminationDate: false,
      },
    };
  }
  componentDidMount() {
    this.getAllDropdownsForAllMemberReport();
  }
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.temp,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };
  getAllMembersReportsDetail = () => {
    this.setState({ isLoading: true });
    let countriesList = [];
    let nameOfCountries = [];
    let listofallCountries = "";
    let activitiesServicesList = [];
    let nameOfActivitiesServices = [];
    let listofallActivitiesServices = "";
    let industriesServedList = [];
    let listofallIndustriesServed = [];
    let nameOfIndustriesServed = "";
    let request = {};
    // request.push(this.state.companyInformation)
    // request.push(this.state.compnanyPresence)
    // request.push(this.state.companyContactInformation)
    // if (this.state.selectedFilters.Country.length > 0) {
    //   request.countries = this.state.selectedFilters.Country.map(
    //     (item) => item.value
    //   ).join(",");
    // }
    // if (this.state.selectedFilters.Services.length > 0) {
    //   request.serviceType = this.state.selectedFilters.Services.map(
    //     (item) => item.value
    //   ).join(",");
    // }
    // if (this.state.selectedFilters.Services.length > 0) {
    //   request.industriesServed = this.state.selectedFilters.Industries.map(
    //     (item) => item.value
    //   ).join(",");
    // }
    if (this.state.temp.memberStatus.length > 0) {
      var arr1 = this.state.temp.memberStatus.map(function (item) { delete item.bad; return item.value; });
      request.memberStatus = arr1.join([","]);
    }
    if (this.state.temp.memberType.length > 0) {
      var arr1 = this.state.temp.memberType.map(function (item) { delete item.bad; return item.value; });
      request.memberType = arr1.join([","]);
    }
    // if (this.state.temp.countriesdata.length > 0) {
    //   var arr1 = this.state.temp.countriesdata.map(function (item) { delete item.bad; return item.value; });
    //   request.countries = arr1.join([","]);
    // }
    // if (this.state.temp.activities.length > 0) {
    //   var arr1 = this.state.temp.activities.map(function (item) { delete item.bad; return item.value; });
    //   request.serviceType = arr1.join([","]);
    // }
    // if (this.state.temp.industriesServeddata.length > 0) {
    //   var arr1 = this.state.temp.industriesServeddata.map(function (item) { delete item.bad; return item.value; });
    //   request.industriesServed = arr1.join([","]);
    // }
    if (this.state.temp.countriesdata.length === 0) {
      countriesList = this.state.countries;
      nameOfCountries = countriesList.filter((drp) => drp.value).map((drp) => drp.value);
      listofallCountries = nameOfCountries.join([","]);
      request.countries = listofallCountries;
    } else {
      var arr1 = this.state.temp.countriesdata.map(function (item) { delete item.bad; return item.value; });
      request.countries = arr1.join([","]);
    }

    if (this.state.temp.activities.length === 0) {
      activitiesServicesList = this.state.serviceType;
      nameOfActivitiesServices = activitiesServicesList.filter((drp) => drp.value).map((drp) => drp.value);
      listofallActivitiesServices = nameOfActivitiesServices.join([","]);
      request.serviceType = listofallActivitiesServices;
    } else {
      var arr1 = this.state.temp.activities.map(function (item) { delete item.bad; return item.value; });
      request.serviceType = arr1.join([","]);
    }

    if (this.state.temp.industriesServeddata.length === 0) {
      industriesServedList = this.state.industriesServed;
      nameOfIndustriesServed = industriesServedList.filter((drp) => drp.value).map((drp) => drp.value);
      listofallIndustriesServed = nameOfIndustriesServed.join([","]);
      request.industriesServed = listofallIndustriesServed;
    } else {
      var arr1 = this.state.temp.industriesServeddata.map(function (item) { delete item.bad; return item.value; });
      request.industriesServed = arr1.join([","]);
    }


    request.uen = this.state.companyInformation.uen === true ? "1" : "0";
    request.companyName =
      this.state.companyInformation.companyName === true ? "1" : "0";
    request.statusId = this.state.companyInformation.statusId === true ? 1 : 0;
    request.membershipTypeId =
      this.state.companyInformation.membershipTypeId === true ? 1 : 0;
    request.registeredStreetName =
      this.state.companyInformation.registeredStreetName === true ? "1" : "0";
    request.registeredBuildingName =
      this.state.companyInformation.registeredBuildingName === true ? "1" : "0";
    request.registeredNoUnitNumber =
      this.state.companyInformation.registeredNoUnitNumber === true
        ? true
        : false;
    request.registeredPostalCode =
      this.state.companyInformation.registeredPostalCode === true ? "1" : "0";
    request.registeredCountryId =
      this.state.companyInformation.registeredCountryId === true ? 1 : 0;
    request.mailingStreetName =
      this.state.companyInformation.mailingStreetName === true ? "1" : "0";
    request.mailingBuildingName =
      this.state.companyInformation.mailingBuildingName === true ? "1" : "0";
    request.mailingNoUnitNumber =
      this.state.companyInformation.mailingNoUnitNumber === true ? true : false;
    request.mailingPostalCode =
      this.state.companyInformation.mailingPostalCode === true ? "1" : "0";
    request.mailingCountryId =
      this.state.companyInformation.mailingCountryId === true ? 1 : 0;
    request.generalBusinessTelephone =
      this.state.companyInformation.generalBusinessTelephone === true ? 1 : 0;
    request.generalEmailAddress =
      this.state.companyInformation.generalEmailAddress === true ? "1" : "0";
    request.incorporationDate =
      this.state.companyInformation.incorporationDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.commencementDate =
      this.state.companyInformation.commencementDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.paidUpCapital =
      this.state.companyInformation.paidUpCapital === true ? "1" : "0";
    request.employmentSize =
      this.state.companyInformation.employmentSize === true ? 1 : 0;
    request.isMTORegistry =
      this.state.companyInformation.isMTORegistry === true ? true : false;
    request.slaMembershipApprovalDate =
      this.state.companyInformation.slaMembershipApprovalDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.mtoMembershipApprovalDate =
      this.state.companyInformation.mtoMembershipApprovalDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.membershipRevenue =
      this.state.companyInformation.membershipRevenue === true ? "1" : "0";
    request.organisationWebsite =
      this.state.companyInformation.organisationWebsite === true ? "1" : "0";
    request.aCRAFilePath =
      this.state.companyInformation.aCRAFilePath === true ? "1" : "0";
    request.orgLogoFileName =
      this.state.companyInformation.orgLogoFileName === true ? "1" : "0";
    request.servicesTypeId =
      this.state.compnanyPresence.servicesTypeId === true ? "1" : "0";
    request.industriesServedId =
      this.state.compnanyPresence.industriesServedId === true ? "1" : "0";
    request.countriesPresenceId =
      this.state.compnanyPresence.countriesPresenceId === true ? "1" : "0";
    request.associationBenefits =
      this.state.compnanyPresence.associationBenefitsId === true ? "1" : "0";

    request.getToKnowId =
      this.state.compnanyPresence.getToKnowId === true ? "1" : "0";
    request.membershipAdmissionFrom = this.state.temp.startDate === '' ? '1900-01-01 00:00:00.000' : this.state.temp.startDate; //"2024-11-01 12:05:10.000"
    request.membershipAdmissionTo = this.state.temp.endDate === '' ? '1900-01-01 00:00:00.000' : this.state.temp.endDate; //"2024-11-01 12:05:10.000"

    request.ceoName =
      this.state.companyContactInformation.ceoName === true ? "1" : "0";
    request.ceoDesignation =
      this.state.companyContactInformation.ceoDesignation === true ? "1" : "0";
    request.ceoMobile =
      this.state.companyContactInformation.ceoMobile === true ? 1 : 0;
    request.ceoEmail =
      this.state.companyContactInformation.ceoEmail === true ? "1" : "0";
    request.ceoOfficeDID =
      this.state.companyContactInformation.ceoOfficeDID === true ? "1" : "0";
    request.ceoEmailSubscription =
      this.state.companyContactInformation.ceoEmailSubscriptionId === true
        ? "1"
        : "0";

    request.firstRepName =
      this.state.companyContactInformation.firstRepName === true ? "1" : "0";
    request.firstRepDesignation =
      this.state.companyContactInformation.firstRepDesignation === true
        ? "1"
        : "0";
    request.firstRepMobile =
      this.state.companyContactInformation.firstRepMobile === true ? 1 : 0;
    request.firstRepEmail =
      this.state.companyContactInformation.firstRepEmail === true ? "1" : "0";
    request.firstRepOfficeDID =
      this.state.companyContactInformation.firstRepOfficeDID === true
        ? "1"
        : "0";
    request.firstRepEmailSubscription =
      this.state.companyContactInformation.firstRepEmailSubscriptionId === true
        ? "1"
        : "0";

    request.secondRepName =
      this.state.companyContactInformation.secondRepName === true ? "1" : "0";
    request.secondRepDesignation =
      this.state.companyContactInformation.secondRepDesignation === true
        ? "1"
        : "0";
    request.secondRepMobile =
      this.state.companyContactInformation.secondRepMobile === true ? 1 : 0;
    request.secondRepEmail =
      this.state.companyContactInformation.secondRepEmail === true ? "1" : "0";
    request.secondRepOfficeDID =
      this.state.companyContactInformation.secondRepOfficeDID === true
        ? "1"
        : "0";
    request.secondRepEmailSubscription =
      this.state.companyContactInformation.secondRepEmailSubscriptionId === true
        ? "1"
        : "0";

    request.hrName =
      this.state.companyContactInformation.hrName === true ? "1" : "0";
    request.hrDesignation =
      this.state.companyContactInformation.hrDesignation === true ? "1" : "0";
    request.hrMobile =
      this.state.companyContactInformation.hrMobile === true ? 1 : 0;
    request.hrEmail =
      this.state.companyContactInformation.hrEmail === true ? "1" : "0";
    request.hrOfficeDID =
      this.state.companyContactInformation.hrOfficeDID === true ? "1" : "0";
    request.hrEmailSubscription =
      this.state.companyContactInformation.hrEmailSubscriptionId === true
        ? "1"
        : "0";

    request.WomanexecutiveName =
      this.state.companyContactInformation.womenExecutiveName === true
        ? "1"
        : "0";
    request.WomanexecutiveDesignation =
      this.state.companyContactInformation.womenExecutiveDesignation === true
        ? "1"
        : "0";
    request.WomanexecutiveMobile =
      this.state.companyContactInformation.womenExecutiveMobile === true
        ? 1
        : 0;
    request.WomanexecutiveEmail =
      this.state.companyContactInformation.womenExecutiveEmail === true
        ? "1"
        : "0";
    request.WomanexecutiveOfficeDID =
      this.state.companyContactInformation.womanexecutiveOfficeDID === true
        ? "1"
        : "0";
    request.WomanexecutiveEmailSubscription =
      this.state.companyContactInformation.womanexecutiveEmailSubscriptionId ===
        true
        ? "1"
        : "0";

    request.youthexecutiveName =
      this.state.companyContactInformation.youthExecutiveName === true
        ? "1"
        : "0";
    request.youthexecutiveDesignation =
      this.state.companyContactInformation.youthExecutiveDesignation === true
        ? "1"
        : "0";
    request.youthexecutiveMobile =
      this.state.companyContactInformation.youthExecutiveMobile === true
        ? 1
        : 0;
    request.youthexecutiveEmail =
      this.state.companyContactInformation.youthExecutiveEmail === true
        ? "1"
        : "0";
    request.youthexecutiveOfficeDID =
      this.state.companyContactInformation.youthexecutiveOfficeDID === true
        ? "1"
        : "0";
    request.youthexecutiveEmailSubscription =
      this.state.companyContactInformation.youthexecutiveEmailSubscriptionId ===
        true
        ? "1"
        : "0";

    request.financeName =
      this.state.companyContactInformation.financeName === true ? "1" : "0";
    request.financeDesignation =
      this.state.companyContactInformation.financeDesignation === true
        ? "1"
        : "0";
    request.financeMobile =
      this.state.companyContactInformation.financeMobile === true ? 1 : 0;
    request.financeEmail =
      this.state.companyContactInformation.finananceEmail === true ? "1" : "0";
    request.financeOfficeDID =
      this.state.companyContactInformation.financeOfficeDID === true
        ? "1"
        : "0";
    request.financeEmailSubscription =
      this.state.companyContactInformation.financeEmailSubscriptionId === true
        ? "1"
        : "0";

    request.experienceYears =
      this.state.companyContactInformation.experienceYears === true ? "1" : "0";
    request.yearsId =
      this.state.companyContactInformation.yearsId === true ? "1" : "0";

    request.proposerCompany =
      this.state.companyEndroserInfomation.proposerCompany === true ? "1" : "0";
    request.seconderCompany =
      this.state.companyEndroserInfomation.secondaryCompany === true
        ? "1"
        : "0";
    request.proposerName =
      this.state.companyEndroserInfomation.proposerName === true ? "1" : "0";
    request.seconderName =
      this.state.companyEndroserInfomation.secondaryName === true ? "1" : "0";
    request.reinstatementCouncilApprovalDate =
      this.state.companyEndroserInfomation.reinstatementCouncilApprovalDate ===
        true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.reinstatementDate =
      this.state.companyEndroserInfomation.reinstatementDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.slaMembershipTerminationDate =
      this.state.companyEndroserInfomation.slaMembershipTerminationDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.mtoTerminationDate =
      this.state.companyEndroserInfomation.mtoTerminationDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.subscriptionStartDate =
      this.state.companyInformation.subscriptionStartDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    request.subscriptionEndDate =
      this.state.companyInformation.subscriptionEndDate === true
        ? "2024-11-01 12:05:10.000"
        : "2024-12-01 12:05:10.000";
    // request.memberType = this.state.selectedFilters.MemberType;
    ///request.memberStatus = this.state.selectedFilters.MemberStatus;

    // request = { ...this.state.companyInformation, ...this.state.compnanyPresence, ...this.state.companyContactInformation,...this.state.companyEndroserInfomation};
    let isValidateAllFields = this.isValidateAllFields()
    if (isValidateAllFields === true) {

      this.corporateMemberServices
        .getAllMembersReportsDetail(request)
        .then((response) => {
          if (response.status === 200 && (response.data != null && response.data.size !== 268)) {
            let blob = new Blob([response.data]);
            saveAs(blob, `AllMembershipReport_.xlsx`);
            this.setState({ isLoading: false });
          } else {
            this.swalServices.Error("No record found");
          }
        });
    }
    //   else{
    //     this.swalServices.Alert('Please select all fields');
    // }
  };
  getAllDropdownsForAllMemberReport = () => {
    this.corporateMemberServices
      .getAllDropdownsForAllMemberReport()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // let status = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "MemberReportStatus"
          // );
          // let membershipType = response.responseItem.responseContent.filter(
          //   (drp) => drp.dropdownName === "MembershipTypes"
          // );
          let status = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "MemberReportStatus")
            .map((item) => ({ value: item.id, label: item.name }));
          let membershipType = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "MembershipTypes")
            .map((item) => ({ value: item.id, label: item.name }));


          let countries = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "Countries")
            .map((item) => ({ value: item.id, label: item.name }));

          let serviceType = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "ServicesType")
            .map((item) => ({ value: item.id, label: item.name }));

          let industriesServed = response.responseItem.responseContent
            .filter((drp) => drp.dropdownName === "IndustriesServed")
            .map((item) => ({ value: item.id, label: item.name }));

          this.setState({
            status: status,
            membershipType: membershipType,
            countries: countries,
            serviceType: serviceType,
            industriesServed: industriesServed,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  handleChange = (id, drpIdentity) => {
    this.setState((prevState) => ({
      selectedFilters: {
        ...prevState.selectedFilters,
        [drpIdentity]: id,
      },
    }));
  };

  handleChangeForCompanyInformation(event) {
    const { name, checked } = event.target;
    let det = { ...this.state.companyInformation };

    if (name === "selectAll") {
      Object.keys(det).forEach((key) => {
        det[key] = checked;
      });
    } else {
      det[name] = checked;
    }
    this.setState({ companyInformation: det });
  }

  handleChangeForCompanyPresence(event) {
    const { name, checked } = event.target;
    let det = { ...this.state.compnanyPresence };

    if (name === "selectAll") {
      Object.keys(det).forEach((key) => {
        det[key] = checked;
      });
    } else {
      det[name] = checked;
    }
    this.setState({ compnanyPresence: det });
  }
  handleChangeForCompanyEndorserInformation(event) {
    const { name, checked } = event.target;
    let det = { ...this.state.companyEndroserInfomation };

    if (name === "selectAll") {
      Object.keys(det).forEach((key) => {
        det[key] = checked;
      });
    } else {
      det[name] = checked;
    }
    this.setState({ companyEndroserInfomation: det });
  }
  handleChangeForCompanyContactInformation(event) {
    const { name, checked } = event.target;
    let det = { ...this.state.companyContactInformation };

    if (name === "selectAll") {
      Object.keys(det).forEach((key) => {
        det[key] = checked;
      });
    } else {
      det[name] = checked;
    }
    this.setState({ companyContactInformation: det });
  }

  // handleChangeDate = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (name === "startDate") {
  //     this.setState({ startDate: value });
  //   }
  //   if (name === "endDate") {
  //     this.setState({ endDate: value });
  //   }
  // };
  handleChangeDate = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "startDate") {
      // Update both the main state and temp state
      this.setState(prevState => ({
        startDate: value,
        temp: {
          ...prevState.temp,
          startDate: value
        }
      }));
    }

    if (name === "endDate") {
      // Update both the main state and temp state
      this.setState(prevState => ({
        endDate: value,
        temp: {
          ...prevState.temp,
          endDate: value
        }
      }));
    }
  };


  // handleChangeMultiDropdown(selected, identity) {
  //   if (identity === "Country") {
  //     this.setState({
  //       selectedFilters: {
  //         ...this.state.selectedFilters,
  //         Country: selected,
  //       },
  //     });
  //   } else if (identity === "Services") {
  //     this.setState({
  //       selectedFilters: {
  //         ...this.state.selectedFilters,
  //         Services: selected,
  //       },
  //     });
  //   } else if (identity === "Industries") {
  //     this.setState({
  //       selectedFilters: {
  //         ...this.state.selectedFilters,
  //         Industries: selected,
  //       },
  //     });
  //   }
  //   else if (identity === "MemberStatus") {
  //     this.setState({
  //       selectedFilters: {
  //         ...this.state.selectedFilters,
  //         MemberStatus: selected,
  //       },
  //     });
  //   }
  //   else if (identity === "MemberType") {
  //     this.setState({
  //       selectedFilters: {
  //         ...this.state.selectedFilters,
  //         MemberType: selected,
  //       },
  //     });
  //   }
  // }
  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.temp,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  handleChangeMultiDropdown(selected, identity) {
    let temp = { ...this.state.temp };
    if (identity === "MemberType") {
      const maxSelections = 30;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.memberType = limitedSelections;

      this.setState({
        temp: temp,
        memberType: limitedSelections,
      }, () => {
        this.validateField("memberType");
      });

    }
    else if (identity === "MemberStatus") {
      const maxSelections = 30;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.memberStatus = limitedSelections;

      this.setState({
        temp: temp,
        memberStatus: limitedSelections,
      }, () => {
        this.validateField("memberStatus");
      });

    }
    else if (identity === "Industries") {
      const maxSelections = 30;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.industriesServeddata = limitedSelections;

      this.setState({
        temp: temp,
        industriesServeddata: limitedSelections,
      }
        // , () => {
        //   this.validateField("industriesServeddata");
        // }
      );

    }
    else if (identity === "Services") {
      const maxSelections = 200;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.activities = limitedSelections;

      this.setState({
        temp: temp,
        activities: limitedSelections,
      }
        // , () => {
        //   this.validateField("activities");
        // }
      );

    }
    else if (identity === "Country") {
      const maxSelections = 200;
      const limitedSelections = selected.slice(0, maxSelections);

      temp.countriesdata = limitedSelections;

      this.setState({
        temp: temp,
        countriesdata: limitedSelections,
      }
        // , () => {
        //   this.validateField("countriesdata");
        // }
      );

    }

  }


  render() {
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Member Information Report
              </h4>
            </div>
          </div>
          <div className="custom-card  mb-4 rounded-md">
            <div className="space-y-8 ">
              <div className="filter-info grid grid-cols-12 gap-6">
                <div className="filter-types col-span-12 lg:col-span-6">
                  <div className="pl-8 items-center border-gray-400">
                    <h2 className="text-xl font-medium theme-color whitespace-nowrap mr-4">
                      Member Status<span className="text-[#C00000]">*</span>
                    </h2>
                    <div className="w-full">
                      {/* <DropdownSelect
                        drpIdentity="MemberStatus"
                        optionArray={this.state.status}
                        allowSelectAll={false}
                        setFilterParameters={this.handleChange}
                        value={this.state.selectedFilters.MemberStatus}
                      /> */}

                      <MultiSelectDropdown
                        drpIdentity="MemberStatus"
                        options={this.state.status}
                        isMulti
                        closeMenuOnSelect={false}
                        components={{ Option }}
                        hideSelectedOptions={false}
                        onChange={this.handleChangeMultiDropdown.bind(this)}
                        allowSelectAll={true}
                        value={this.state.temp.memberStatus}
                      />
                    </div>
                    <ValidationText error={this.state.validState.error.memberStatus} />
                  </div>
                </div>

                <div className="filter-types col-span-12 lg:col-span-6 ">
                  <div className="pl-8 items-center border-gray-400">
                    <h2 className="text-xl font-medium theme-color whitespace-nowrap mr-4">
                      Member Type<span className="text-[#C00000]">*</span>
                    </h2>
                    <div className="w-full">
                      {/* <DropdownSelect
                        drpIdentity="MemberType"
                        optionArray={this.state.membershipType}
                        allowSelectAll={false}
                        setFilterParameters={this.handleChange}
                        value={this.state.selectedFilters.MemberType}
                      /> */}
                      <MultiSelectDropdown
                        drpIdentity="MemberType"
                        options={this.state.membershipType}
                        isMulti
                        closeMenuOnSelect={false}
                        components={{ Option }}
                        hideSelectedOptions={false}
                        onChange={this.handleChangeMultiDropdown.bind(this)}
                        allowSelectAll={true}
                        value={this.state.temp.memberType}
                      />
                    </div>
                    <ValidationText error={this.state.validState.error.memberType} />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">Filters</h4>
            </div>
          </div>
          <div className="custom-card  mb-4 rounded-md">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-6">
                <div className="pl-8 border-[#757575]">
                  <h2 className="text-xl font-medium theme-color whitespace-nowrap mr-4">
                    Country
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="Country"
                    options={this.state.countries}
                    isMulti
                    closeMenuOnSelect={false}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    onChange={this.handleChangeMultiDropdown.bind(this)}
                    allowSelectAll={true}
                    value={this.state.temp.countriesdata}
                  />
                </div>
                {/* <ValidationText error={this.state.validState.error.countriesdata} /> */}
              </div>

              <div className="col-span-6">
                <div className="pl-8 border-[#757575]">
                  <h2 className="text-xl font-medium theme-color whitespace-nowrap mr-4">
                    Activities & Services
                  </h2>
                  <MultiSelectDropdown
                    drpIdentity="Services"
                    options={this.state.serviceType}
                    isMulti
                    closeMenuOnSelect={false}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    onChange={this.handleChangeMultiDropdown.bind(this)}
                    allowSelectAll={true}
                    value={this.state.temp.activities}
                  />
                </div>
                {/* <ValidationText error={this.state.validState.error.activities} /> */}
              </div>

              <div className="col-span-6">
                <div className="pl-8 border-[#757575]">
                  <h2 className="text-xl font-medium theme-color whitespace-nowrap mr-4">
                    Industries
                  </h2>

                  <MultiSelectDropdown
                    drpIdentity="Industries"
                    options={this.state.industriesServed}
                    isMulti
                    closeMenuOnSelect={false}
                    components={{ Option }}
                    hideSelectedOptions={false}
                    onChange={this.handleChangeMultiDropdown.bind(this)}
                    allowSelectAll={true}
                    value={this.state.temp.industriesServeddata}
                  />
                </div>
                {/* <ValidationText error={this.state.validState.error.industriesServeddata} /> */}
              </div>
              <div className="col-span-6">
                <div className="pl-8 items-center border-gray-400">
                  <h2 className="text-xl font-medium theme-color ">
                    Membership Admission From
                  </h2>
                  <div className="w-full">
                    <input
                      id="startDate"
                      name="startDate"
                      className="date-pickers form-input w-full rounded-none shadow-red py-3 text-lg"
                      value={moment(this.state.startDate).format("yyyy-MM-DD")}
                      onChange={(event) => this.handleChangeDate(event)}
                      type="date"
                    // onBlur={() => this.validateField("startDate")}

                    />
                  </div>
                  {/* <ValidationText error={this.state.validState.error.startDate} /> */}
                </div>
              </div>

              <div className="col-span-6">
                <div className="pl-8 items-center border-gray-400">
                  <h2 className="text-xl font-medium theme-color">
                    Membership Admission To
                  </h2>
                  <div className="w-full">
                    <input
                      id="endDate"
                      name="endDate"
                      className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                      value={moment(this.state.endDate).format("yyyy-MM-DD")}
                      onChange={(event) => this.handleChangeDate(event)}
                      type="date"
                    // onBlur={() => this.validateField("endDate")}

                    />
                  </div>
                  {/* <ValidationText error={this.state.validState.error.endDate} /> */}
                </div>
              </div>


            </div>
          </div>
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                Selected Filters
              </h4>
            </div>
          </div>

          <div className="filter-types custom-card mb-4 rounded-md">
            <div className="grid grid-cols-12 gap-4">
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="selectAll"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      value={
                        this.state.companyInformation.isAllCompanyInformation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Select All (Company Information)
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="companyName"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.companyName}
                    />
                    <span className="text-xl theme-color ml-4">
                      Company Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="uen"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.uen}
                    />
                    <span className="text-xl theme-color ml-4">UEN</span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="statusId"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.statusId}
                    />
                    <span className="text-xl theme-color ml-4">
                      Membership Status
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="mailingAddress"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.mailingAddress}
                    />
                    <span className="text-xl theme-color ml-4">
                      Mailing Address
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="registeredAddress"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.registeredAddress}
                    />
                    <span className="text-xl theme-color ml-4">
                      Registered Address
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="membershipTypeId"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.membershipTypeId}
                    />
                    <span className="text-xl theme-color ml-4">
                      Membership Type
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="postalCode"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.postalCode}
                    />
                    <span className="text-xl theme-color ml-4">
                      Postal Code
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="slaMembershipApprovalDate"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.slaMembershipApprovalDate}
                    />
                    <span className="text-xl theme-color ml-4">
                      Approval Date
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="generalBusinessTelephone"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={
                        this.state.companyInformation.generalBusinessTelephone
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Company Tel
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="generalEmailAddress"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={
                        this.state.companyInformation.generalEmailAddress
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Company Email
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                                <div className="m-3">
                                    <label className="flex items-center">
                                        <input type="checkbox" className="form-checkbox"
                                            name="companyFax"
                                            onChange={(event) => this.handleChangeForCompanyInformation(event)}
                                            checked={this.state.companyInformation.companyFax} />
                                        <span className="text-xl theme-color ml-4">Company Fax</span>
                                    </label>
                                </div>
                            </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="incorporationDate"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.incorporationDate}
                    />
                    <span className="text-xl theme-color ml-4">
                      Date Of Incorporation
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="paidUpCapital"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.paidUpCapital}
                    />
                    <span className="text-xl theme-color ml-4">
                      Paidup Capital
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="membershipRevenue"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.membershipRevenue}
                    />
                    <span className="text-xl theme-color ml-4">
                      Annual Turnover
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="organisationWebsite"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={
                        this.state.companyInformation.organisationWebsite
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Company Website
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="commencementDate"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.commencementDate}
                    />
                    <span className="text-xl theme-color ml-4">
                      Commencement Date Of Logistices Services
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="employmentSize"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.employmentSize}
                    />
                    <span className="text-xl theme-color ml-4">
                      No Of Employees
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="isMTORegistry"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.isMTORegistry}
                    />
                    <span className="text-xl theme-color ml-4">
                      MTO Registry
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="subscriptionStartDate"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.subscriptionStartDate}
                    />
                    <span className="text-xl theme-color ml-4">
                      Subscription Start Date
                    </span>
                  </label>
                </div>
              </div>  <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="subscriptionStartDate"
                      onChange={(event) =>
                        this.handleChangeForCompanyInformation(event)
                      }
                      checked={this.state.companyInformation.subscriptionEndDate}
                    />
                    <span className="text-xl theme-color ml-4">
                      Subscription End Date
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-[3rem] grid grid-cols-12 gap-4">
              <div className="col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="selectAll"
                      onChange={(event) =>
                        this.handleChangeForCompanyPresence(event)
                      }
                      value={this.state.companyInformation.companyName}
                    />
                    <span className="text-xl theme-color ml-4">
                      Select All (Company Presence)
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="countriesPresenceId"
                      onChange={(event) =>
                        this.handleChangeForCompanyPresence(event)
                      }
                      checked={this.state.compnanyPresence.countriesPresenceId}
                    />
                    <span className="text-xl theme-color ml-4">Countries</span>
                  </label>
                </div>
              </div>
              <div className="col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="industriesServedId"
                      onChange={(event) =>
                        this.handleChangeForCompanyPresence(event)
                      }
                      checked={this.state.compnanyPresence.industriesServedId}
                    />
                    <span className="text-xl theme-color ml-4">Industries</span>
                  </label>
                </div>
              </div>
              <div className="col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="servicesTypeId"
                      onChange={(event) =>
                        this.handleChangeForCompanyPresence(event)
                      }
                      checked={this.state.compnanyPresence.servicesTypeId}
                    />
                    <span className="text-xl theme-color ml-4">Services</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="filter-types custom-card mb-4 rounded-md">
            <div className="grid grid-cols-12 gap-4">
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="selectAll"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                    // value={this.state.companyInformation.companyName}
                    />
                    <span className="text-xl theme-color ml-4">
                      Select All (Company Contact Information)
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="ceoName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.ceoName}
                    />
                    <span className="text-xl theme-color ml-4">CEO Name</span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="ceoDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.ceoDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      CEO Designation
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="ceoTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.ceoTel} />
                                    <span className="text-xl theme-color ml-4">CEO Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="ceoMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.ceoMobile}
                    />
                    <span className="text-xl theme-color ml-4">CEO Mobile</span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="ceoEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.ceoEmail}
                    />
                    <span className="text-xl theme-color ml-4">CEO Email</span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="hrName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.hrName}
                    />
                    <span className="text-xl theme-color ml-4">HR Name</span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="hrEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.hrEmail}
                    />
                    <span className="text-xl theme-color ml-4">HR Email</span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="hrDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.hrDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      HR Designation
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="hrTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.hrTel} />
                                    <span className="text-xl theme-color ml-4">HR Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="hrMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.hrMobile}
                    />
                    <span className="text-xl theme-color ml-4">HR Mobile</span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="remarks"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.remarks} />
                                    <span className="text-xl theme-color ml-4">Remarks</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="firstRepName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.firstRepName
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      First Rep Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="firstRepDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.firstRepDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      First Rep Designation
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="firstRepEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.firstRepEmail
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      First Rep Email
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="firstRepAltEmail"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.firstRepAltEmail} />
                                    <span className="text-xl theme-color ml-4">First Rep Alt Email</span>
                                </label>
                            </div>
                        </div> */}
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="firstRepOffTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.firstRepOffTel} />
                                    <span className="text-xl theme-color ml-4">First Rep Off Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="firstRepMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.firstRepMobile
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      First Rep Mobile
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="secondRepName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.secondRepName
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Second Rep Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="secondRepDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation
                          .secondRepDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Second Rep Designation
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="secondRepEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.secondRepEmail
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Second Rep Email
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="secondRepAltEmail"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.secondRepAltEmail} />
                                    <span className="text-xl theme-color ml-4">Second Rep Alt Email</span>
                                </label>
                            </div>
                        </div> */}
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="secondRepOffTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.secondRepOffTel} />
                                    <span className="text-xl theme-color ml-4">Second Rep Off Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="secondRepMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.secondRepMobile
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Second Rep Mobile
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="womenExecutiveName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.womenExecutiveName
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Women Executive Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="womenExecutiveDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation
                          .womenExecutiveDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Women Executive Designation
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="womenExecutiveEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.womenExecutiveEmail
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Women Executive Email
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="womenExecutiveOffTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.womenExecutiveOffTel} />
                                    <span className="text-xl theme-color ml-4">Women Executive Off Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="womenExecutiveMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation
                          .womenExecutiveMobile
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Women Executive Mobile
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="youthExecutiveName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.youthExecutiveName
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Youth Executive Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="youthExecutiveDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation
                          .youthExecutiveDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Youth Executive Designation
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="youthExecutiveEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.youthExecutiveEmail
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Youth Executive Email
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="youthExecutiveOffTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.youthExecutiveOffTel} />
                                    <span className="text-xl theme-color ml-4">Youth Executive Off Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="youthExecutiveMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation
                          .youthExecutiveMobile
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Youth Executive Mobile
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="financeExecutiveName"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={this.state.companyContactInformation.financeName}
                    />
                    <span className="text-xl theme-color ml-4">
                      Finance Executive Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="financeExecutiveDesignation"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.financeDesignation
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Finance Executive Designation
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="finananceExecutiveEmail"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.finananceEmail
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Finance Executive Email
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="financeExecutiveOffTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.financeExecutiveOffTel} />
                                    <span className="text-xl theme-color ml-4">Finance Executive Off Tel</span>
                                </label>
                            </div>
                        </div> */}
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="financeExecutiveMobile"
                      onChange={(event) =>
                        this.handleChangeForCompanyContactInformation(event)
                      }
                      checked={
                        this.state.companyContactInformation.financeMobile
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Finance Executive Mobile
                    </span>
                  </label>
                </div>
              </div>
              {/* <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="otherContactPersonName"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.otherContactPersonName} />
                                    <span className="text-xl theme-color ml-4">Other Contact Person Name</span>
                                </label>
                            </div>
                        </div>
                        <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="otherContactPersonDesignation"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.otherContactPersonDesignation} />
                                    <span className="text-xl theme-color ml-4">Other Contact Person Designation</span>
                                </label>
                            </div>
                        </div>
                        <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="otherContactPersonEmail"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.otherContactPersonEmail} />
                                    <span className="text-xl theme-color ml-4">Other Contact Person Email</span>
                                </label>
                            </div>
                        </div>
                        <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="otherContactPersonOffTel"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.otherContactPersonOffTel} />
                                    <span className="text-xl theme-color ml-4">Other Contact Person Off Tel</span>
                                </label>
                            </div>
                        </div>
                        <div className=' col-span-4'>
                            <div className="m-3">
                                <label className="flex items-center">
                                    <input type="checkbox" className="form-checkbox"
                                        name="otherContactPersonMobile"
                                        onChange={(event) => this.handleChangeForCompanyContactInformation(event)}
                                        checked={this.state.companyContactInformation.otherContactPersonMobile} />
                                    <span className="text-xl theme-color ml-4">Other Contact Person Mobile</span>
                                </label>
                            </div>
                        </div> */}
            </div>
          </div>
          <div className="filter-types custom-card mb-4 rounded-md">
            <div className="grid grid-cols-12 gap-4">
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="selectAll"
                      onChange={(event) =>
                        this.handleChangeForCompanyEndorserInformation(event)
                      }
                    // value={this.state.companyInformation.companyName}
                    />
                    <span className="text-xl theme-color ml-4">
                      Select All (Company Endorser Information)
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="proposerName"
                      onChange={(event) =>
                        this.handleChangeForCompanyEndorserInformation(event)
                      }
                      checked={
                        this.state.companyEndroserInfomation.proposerName
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Proposer Name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="proposerCompany"
                      onChange={(event) =>
                        this.handleChangeForCompanyEndorserInformation(event)
                      }
                      checked={
                        this.state.companyEndroserInfomation.proposerCompany
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Proposer Company
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="secondaryName"
                      onChange={(event) =>
                        this.handleChangeForCompanyEndorserInformation(event)
                      }
                      checked={
                        this.state.companyEndroserInfomation.secondaryName
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Secondary name
                    </span>
                  </label>
                </div>
              </div>
              <div className=" col-span-4">
                <div className="m-3">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      name="secondaryCompany"
                      onChange={(event) =>
                        this.handleChangeForCompanyEndorserInformation(event)
                      }
                      checked={
                        this.state.companyEndroserInfomation.secondaryCompany
                      }
                    />
                    <span className="text-xl theme-color ml-4">
                      Secondary Company
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:col-span-2 lg:col-span-2 col-span-12">
          <div className="flex items-end justify-end h-full pb-1">
            {this.state.isLoading ? (
              <ButtonLoader />
            ) : (
              <button
                className="btn btn-red text-white"
                onClick={() => this.getAllMembersReportsDetail()}
              >
                <span className="text-xl text-white font-semibold tracking-normal">
                  Export
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
