import { axiosGetAuthorize, axiosPost, axiosPostAuthorize } from '../AxiosRequests';

import { DeleteAdminDetailsById, GetFeaturedNewMemberWelcomeTitle, UpdateFeaturedNewMemberWelcomeTitle, AddFeaturedNewMemberLogoToWeb, GetFeaturedNewMembersList, DeleteFeaturedNewMemberLogoById, GetPublishedSpotlightList, ActiveInActiveSpotlights, DeleteSpotlightById, GetFeaturedNewMembersById } from '../ApiEndPoints';

export default class WebsiteCmsServices {

    async getFeaturedNewMemberWelcomeTitle(request) {
        return axiosGetAuthorize(GetFeaturedNewMemberWelcomeTitle, request);
    }

    async updateFeaturedNewMemberWelcomeTitle(request) {
        return axiosGetAuthorize(UpdateFeaturedNewMemberWelcomeTitle, request);
    }

    async addFeaturedNewMemberLogoToWeb(request) {
        return axiosPostAuthorize(AddFeaturedNewMemberLogoToWeb, request);
    }

    async getFeaturedNewMembersList(request) {
        return axiosPostAuthorize(GetFeaturedNewMembersList, request);
    }

    async deleteFeaturedNewMemberLogoById(request) {
        return axiosPostAuthorize(DeleteFeaturedNewMemberLogoById, request);
    }

    async deleteSpotlightById(request) {
        return axiosPostAuthorize(DeleteSpotlightById, request);
    }
   
    async getPublishedSpotlightList(request) {
        return axiosPostAuthorize(GetPublishedSpotlightList, request);
    }

    async activeInActiveSpotlights(request) {
        return axiosPostAuthorize(ActiveInActiveSpotlights, request);
    }

    async getFeaturedNewMembersById(request) {
        return axiosGetAuthorize(GetFeaturedNewMembersById, request);
    }

}