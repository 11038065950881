import React, { Component } from "react";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { Navigate } from "react-router-dom";
import AuthenticationServices from "../../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from "../../../services/swalServices/SwalServices";
import { APIURL, GetImage } from "../../../services/axiosServices/ApiEndPoints";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import PageLoader from "../../../components/common/pageLoader/PageLoader";

import { encryptAES } from "../../../utils/Encryption";
import CreateButton from "../../../components/UIComponent/Buttons/CreateButton";
import WebsiteCmsServices from "../../../services/axiosServices/apiServices/WebsiteCmsServices";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import TextInput from "../../../components/UIComponent/TextInput";
import { DeleteButtonIcon } from "../../../components/SVGConstants";

export default class ManageFeaturedNewMembers extends Component {
  constructor(props) {
    super(props);
    this.websiteCmsServices = new WebsiteCmsServices();
    this.swalServices = new SwalServices();
    this.state = {
      route: "",
      isLoading: false,
      isUpdateLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 50,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      },
      addEditDropdown: false,
      dropdownId: 0,
      valueOrdering: 0,
      totalResultes: 0,
      featuredMemberDetailsList: [],
      FeaturesDetails: {
        featuredNewMembersId: 0,
        memberName: "",
        logoImagePath: "",
        valueOrdering: 0,
      },
      deletedBy: 1,
      deletedAppId: 1,
      featuredWelcomeTitle: "",
      length: 0,
    };
  }
  componentDidMount() {
    this.getFeaturedNewMemberWelcomeTitle();
    this.getFeaturedNewMembersList();
  }

  getFeaturedNewMemberWelcomeTitle = () => {
    this.websiteCmsServices
      .getFeaturedNewMemberWelcomeTitle()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            featuredWelcomeTitle: response.responseItem.responseContent,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  updateFeaturedNewMemberWelcomeTitle = (featuredWelcomeTitle) => {
    this.setState({ isUpdateLoading: true });
    this.websiteCmsServices
      .updateFeaturedNewMemberWelcomeTitle(featuredWelcomeTitle)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success(
            "Featured Welcome Title Updated Successfully!"
          );
          this.setState({ isUpdateLoading: false });
          this.getFeaturedNewMemberWelcomeTitle();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isUpdateLoading: false });
      });
  };

  getFeaturedTableData = (featuredId) => {
    this.resetFeaturedMemberData();
    this.setState({ addEditDropdown: true });
    this.websiteCmsServices
      .getFeaturedNewMembersById(featuredId)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let detail = { ...this.state.FeaturesDetails };
          detail.valueOrdering =
            response.responseItem.responseContent.orderingPosition;
          detail.featuredNewMembersId =
            response.responseItem.responseContent.featuredNewMembersId;
          detail.memberName = response.responseItem.responseContent.memberName;
          detail.logoImagePath =
            response.responseItem.responseContent.logoImagePath;
          this.setState({ FeaturesDetails: detail });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  addEditFeaturedMemberData = (featuredId) => {
    this.setState({ addEditDropdown: false });
    let request = { ...this.state.FeaturesDetails };

    request.orderingPosition = this.state.FeaturesDetails.valueOrdering;
    request.logoImagePath = this.state.FeaturesDetails.logoImagePath;
    request.memberName = this.state.FeaturesDetails.memberName;
    request.featuredNewMembersId = featuredId;

    this.websiteCmsServices
      .addFeaturedNewMemberLogoToWeb(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Ordering Position Updated Successfully!");
          this.getFeaturedNewMembersList();
          this.setState({ addEditDropdown: false });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  getFeaturedNewMembersList = (pagination = this.state.pagination) => {
    this.setState({ addEditDropdown: true });
    this.setState({ isLoading: true });
    this.websiteCmsServices
      .getFeaturedNewMembersList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let featuresMembers = response.responseItem.responseContent.itemList;
          featuresMembers = featuresMembers.map((p) => ({
            featuredNewMembersId: p.featuredNewMembersId,
            logoImagePath: p.logoImagePath,
            memberName: p.memberName,
            orderingPosition: p.orderingPosition,
          }));
          this.setState({ featuredMemberDetailsList: featuresMembers });
          this.setState({
            totalResultes: response.responseItem.responseContent.totalCount,
          });
          this.setState({
            length: response.responseItem.responseContent.totalCount + 1,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  onDelete = (featuredNewMembersId) => {
    if (featuredNewMembersId !== null) {
      this.swalServices
        .Confirm(
          CommonSuccessMessages.Confirm_Question.replace(
            "{0}",
            "Featured New Member"
          ),
          CommonSuccessMessages.Confirm_Delete.replace("{0}", "member details"),
          CommonSuccessMessages.Yes_Text,
          CommonSuccessMessages.No_Text
        )
        .then((response) => {
          if (response) {
            let request = {};
            request.id = featuredNewMembersId;
            request.deletedBy = this.state.deletedBy;
            this.websiteCmsServices
              .deleteFeaturedNewMemberLogoById(request)
              .then((response) => {
                if (
                  response.statusCode === 200 &&
                  response.responseItem != null
                ) {
                  this.swalServices.Success(
                    "Featured New Member details deleted successfully."
                  );
                  this.getFeaturedNewMembersList();
                } else {
                  this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
              });
          }
        });
    } else {
      this.swalServices.Error("Sorry! You can't delete this Member.");
    }
  };

  handleChange(event) {
    const value = event.target.value;
    this.setState({ featuredWelcomeTitle: value });
  }

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });
    this.getFeaturedNewMembersList();
  };
  onEdit = (id) => {
    this.setState({
      route: "/AddFeaturedNewMember/featuredNewMembersId?=" + encryptAES(id),
    });
  };

  handleChangeForOrderingPosition(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.FeaturesDetails;
    if (name === "valueOrdering") {
      let chcklength = this.state.length - 1;
      if (value > chcklength) {
        detail[name] = parseInt(chcklength);
      } else {
        detail[name] = parseInt(value);
      }
    } else {
      detail[name] = value;
    }
    this.setState({ FeaturesDetails: detail });
  }
  // FeaturedNewMembersId : 0,
  // MemberName : "",
  // LogoImagePath : "",

  resetFeaturedMemberData() {
    let details = { ...this.state.FeaturesDetails };
    details.valueOrdering = 0;
    details.featuredNewMembersId = 0;
    details.memberName = "";
    details.logoImagePath = "";
    this.setState({ FeaturesDetails: details });
  }

  cancelAddEditDropdown() {
    this.setState({ addEditDropdown: false });
    this.resetFeaturedMemberData();
  }


  

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1">
            <main className="pb-10">
              {/* Title and Update Section */}
              <div className="custom-card shadow-lg mb-10">
                <div className="flex items-center gap-4 mb-3">
                  <div className="flex-1">
                    <input
                      id="title"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="text"
                      name="featuredWelcomeTitle"
                      onChange={(event) => this.handleChange(event)}
                      value={this.state.featuredWelcomeTitle}
                      placeholder="Enter title"
                    />
                  </div>
                  {this.state.isUpdateLoading ? (
                    <ButtonLoader className="btn btn-red text-white py-3 px-6 text-lg font-semibold" />
                  ) : (
                    <button
                      className="btn btn-red text-white py-3 px-6 text-lg font-semibold"
                      onClick={() =>
                        this.updateFeaturedNewMemberWelcomeTitle(
                          this.state.featuredWelcomeTitle
                        )
                      }
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>

              {/* Table Section */}
              <div className="w-full mx-auto">
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  <div className="mb-4 sm:mb-0">
                    <h1 className="table-title-txt theme-color font-bold">
                      {this.state.totalResultes} Featured Members
                    </h1>
                  </div>
                  <CreateButton
                    redirect="/AddFeaturedNewMember"
                    text="Create New"
                  />
                </div>
                <div className="relative">
                  {/* Table */}
                  {/* <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                                        <thead className="table-bg text-xs font-semibold capitalize text-white border-t border-b border-slate-200">
                                            <tr>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Member Name
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Logo
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Ordering Position
                                                    </div>
                                                </th>
                                                <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                                                    <div className="font-bold tble-th-txt text-left">
                                                        Action
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="scrollit text-sm custom-tbody">
                                            {this.state.featuredMemberDetailsList.map((e, key) => (
                                                <tr key={key}>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                        {e.memberName}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%]">
                                                        <img
                                                            className="w-32 border border-[#dfdfdf]"
                                                            alt=""
                                                            src={
                                                                APIURL +
                                                                GetImage +
                                                                "?type=featuredMemberLogo&&fileName=" +
                                                                e.logoImagePath
                                                            }
                                                        />
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[25%]">
                                                        {e.orderingPosition}
                                                    </td>
                                                    <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[15%]">
                                                        <ActionContainer>
                                                            <ActionEditButton
                                                                value={e.featuredNewMembersId}
                                                                id={key}
                                                                index={key}
                                                                onActionClick={this.onEdit}
                                                                text="Edit"
                                                            />
                                                            <ActionDeleteButton
                                                                value={e.featuredNewMembersId}
                                                                id={key}
                                                                index={key}
                                                                onActionClick={this.onDelete.bind(this)}
                                                                text="Delete"
                                                            />

                                                        </ActionContainer>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table> */}

                  {/*  */}
                  <table className="w-full bg-white shadow-lg rounded-sm ">
                    {/* Table header */}
                    <thead className="table-bg text-xs font-semibold capitalize text-[#757575]  ">
                      <tr>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white ">
                          <div className="font-bold tble-th-txt text-left">
                            Member Name
                          </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                          <div className="font-bold tble-th-txt text-center flex items-center justify-center">
                            Logo
                          </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                          <div className="font-bold tble-th-txt text-left flex items-center justify-center">
                            Ordering Position
                          </div>
                        </th>
                        <th className="px-3 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white  w-[15%]">
                          <div className="font-bold tble-th-txt text-left flex items-center justify-center">
                            Actions
                          </div>
                        </th>
                      </tr>
                    </thead>
                    {/* Table body */}
                    <tbody className="text-sm custom-tbody">
                      {(this.state.featuredMemberDetailsList &&
                        this.state.featuredMemberDetailsList.length === 0) ||
                      this.state.featuredMemberDetailsList.length === null ? (
                        <tr className="">
                          <td
                            className="w-full text-lg text-center py-4 text-[#757575] "
                            colSpan="100%"
                          >
                            No Records Found
                          </td>
                        </tr>
                      ) : (
                        this.state.featuredMemberDetailsList.map(
                          (dropdown, key) => (
                            <tr key={key}>
                              <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                <div className="flex flex-col">
                                  {/* { this.state.addEditDropdown === true &&
                                                this.state.dropdownId === dropdown.value ? (
                                                <div key={key} className="flex flex-col relative">
                                                <div className={`edit-field input-height-set`}>
                                                    <TextInput
                                                    placeholder=""
                                                    type="text"
                                                    name="name"
                                                    value={props.dropdownDetails.name}
                                                    handleChange={props.handleChange}
                                                    onBlur={() => props.validateField("name")}
                                                    identity="DropdownName"
                                                    />
                                                </div>
                                                <div className="absolute bottom-[-22px]">
                                                    <ValidationText error={props.validState.error.name} />
                                                </div>
                                                </div>
                                            ) : ( */}
                                  <div className="current-text">
                                    {dropdown.memberName}
                                  </div>
                                  {/* )} */}
                                </div>
                              </td>
                              <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                <div className="flex flex-col">
                                  <img
                                    className="w-32 border border-[#dfdfdf]"
                                    alt=""
                                    src={
                                      APIURL +
                                      GetImage +
                                      "?type=featuredMemberLogo&&fileName=" +
                                      dropdown.logoImagePath
                                    }
                                  />
                                </div>
                              </td>
                              <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap flex justify-center mt-5 tble-td-txt pointer text-[#757575] ">
                                <div className="flex flex-col">
                                  {this.state.addEditDropdown === true &&
                                  this.state.valueOrdering ===
                                    dropdown.orderingPosition &&
                                  this.state.dropdownId ===
                                    dropdown.featuredNewMembersId ? (
                                    <div
                                      key={key}
                                      className="flex flex-col relative"
                                    >
                                      <div
                                        className={`edit-field input-height-set center-text`}
                                      >
                                        <TextInput
                                          placeholder=""
                                          type="number"
                                          name="valueOrdering"
                                          max={this.state.length - 1}
                                          value={
                                            this.state.FeaturesDetails
                                              .valueOrdering === 0
                                              ? 0
                                              : this.state.FeaturesDetails
                                                  .valueOrdering
                                          }
                                          handleChange={this.handleChangeForOrderingPosition.bind(
                                            this
                                          )}
                                          // onBlur={() => props.validateField("valueOrdering")}
                                          identity="DropdownValueOrdering"
                                          min={0}
                                        />
                                      </div>
                                      <div className="absolute bottom-[-22px]">
                                        {/* <ValidationText
                                                    error={props.validState.error.valueOrdering}
                                                    /> */}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex items-center gap-3">
                                    <div className="current-text text-center">
                                      {dropdown.orderingPosition}
                                    </div>
                                    <button
                                      className="action-btn"
                                      aria-controls="add-edit-modal"
                                      // onClick={() => {
                                      //     props.getTableDataById(
                                      //     props.selectedDropdownId,
                                      //     dropdown.value
                                      //     );
                                      //     setDropdownId(dropdown.value);
                                      //     setValueOrdering(dropdown.valueOrdering);
                                      // }}

                                      onClick={() => {
                                        this.setState(
                                          {
                                            dropdownId:
                                              dropdown.featuredNewMembersId,
                                            valueOrdering:
                                              dropdown.orderingPosition,
                                          },
                                          () => {
                                            // this.getFeaturedNewMembersList();
                                            this.getFeaturedTableData(
                                              dropdown.featuredNewMembersId
                                            );
                                          }
                                        );
                                      }}
                                    >
                                      <p className="ml-2 tool-tip-txt underline text-[#757575]">
                                        <svg
                                          className="menu-svg"
                                          width="24"
                                          height="24"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 88 88"
                                        >
                                          <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
                                            <path
                                              d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z"
                                              fill="#6e6e6e"
                                            />
                                          </g>
                                        </svg>
                                      </p>
                                    </button>

                                    </div>
                                    
                                  )}
                                </div>
                              </td>
                              <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                {this.state.addEditDropdown === true &&
                                this.state.dropdownId ===
                                  dropdown.featuredNewMembersId ? (
                                  <div className="flex items-center justify-center edit-field">
                                    <button
                                      className="action-btn mr-4 flex items-center"
                                      aria-controls="add-edit-modal"
                                      onClick={() => {
                                        this.addEditFeaturedMemberData(
                                          dropdown.featuredNewMembersId
                                        );
                                      }}
                                    >
                                      <p className="ml-2 tool-tip-txt text-[#c00000] underline cursor-pointer">
                                        Save
                                      </p>
                                    </button>
                                    <button
                                      className="action-btn mr-4 flex items-center"
                                      aria-controls="add-edit-modal"
                                      onClick={() => {
                                        this.cancelAddEditDropdown();
                                      }}
                                    >
                                      <img
                                        src="/cancel-icon.png"
                                        className="w-[22px] mx-auto my-0"
                                        alt=""
                                      ></img>
                                    </button>
                                  </div>
                                ) : (
                                  <div className="flex items-center justify-center">
                                    <button
                                      className="action-btn right-icon"
                                      onClick={() =>
                                        this.onDelete(
                                          dropdown.featuredNewMembersId
                                        )
                                      }
                                    >
                                      <DeleteButtonIcon />
                                    </button>

                                    <button
                                    className="ml-2"
                                      onClick={() => {
                                        this.setState({
                                          route:
                                            "/AddFeaturedNewMember/featuredNewMembersId?=" +
                                            encryptAES(
                                              dropdown.featuredNewMembersId
                                            ),
                                        });
                                      }}
                                    >
                                      <p>Edit</p>
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
