import React, { Component } from "react";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import { Link, Navigate } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import LeadershipPositionsServices from "../../../services/axiosServices/apiServices/LeadershipPositionsServices";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import Table from "../../../components/table/Table";
import CreateButton from "../../../components/UIComponent/Buttons/CreateButton";
import ActionEditButton from "../../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionContainer from "../../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionToggleButton from "../../../components/UIComponent/ActionTableComponent/ActionToggleButton";
import ActionDeleteButton from "../../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import TextInput from "../../../components/UIComponent/TextInput";
import { DeleteButtonIcon } from "../../../components/SVGConstants";
import { encryptAES } from "../../../utils/Encryption";

export default class ManageCouncilDetails extends Component {
  editUser = (value, index) => {
    <Link to="/AddEditCouncilDetails" />;
  };

  constructor(props) {
    super(props);
    this.LeadershipPositionsServices = new LeadershipPositionsServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.onPageChange = null;
    this.state = {
      totalResultes: "",
      getCouncilDetails: [],

      status: [],
      setToggle2: false,
      redirect: null,
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 50,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        searchByName: "",
      },

      councilMemberDetail: null,
      length: 0,
      addEditField: false,
      valueOrdering: 0,
      dropdownId: 0,
      getCouncilDetailsList: [],
      SearchBySGTechDesignation: [0],
      SearchByLeadershipStatus: [0],
    };
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.leadershipPosition;
    detail[name] = value;
    this.setState({ leadershipPosition: { ...detail } });
  }

  onEdit = (value, index) => {
    let editCouncilDetails = [...this.state.getCouncilDetails];
    this.setState({
      redirect:
        "/AddEditCouncilDetails/councilDetailsId?=" +
        editCouncilDetails[index].councilDetailsId,
    });
  };

  updateCouncilDetails = (index) => {
    this.LeadershipPositionsServices.updateCouncilDetails(index).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Success");
        } else {
          this.swalServices.Error(response.message);
        }
        this.getCouncilDetails();
      }
    );
  };

  onDelete = (CouncilDetailsId) => {
    let request = {
      id: CouncilDetailsId,
      deletedBy: 115,
      appId: 15,
    };
    this.LeadershipPositionsServices.deleteCouncilDetails(request).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({ status: response.responseItem.responseContent });
          this.swalServices.Success("Deleted");
          this.getCouncilDetails();
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  // setFilterParameters = (id, drpIdentity) => {
  //   let detail = this.state.pagination;
  //   if (drpIdentity === "Statuses") {

  //     detail.SearchByLeadershipStatus = id;
  //     this.setState({ pagination: { ...detail } });
  //   }
  //   this.getCouncilDetails();
  // }

  handleChangeEvent = (value) => {
    let detail = this.state.pagination;
    detail.searchText = "";
    if (value.length > 2) {
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getCouncilDetails();
  };

  getCouncilDetails = (pagination = this.state.pagination) => {
    this.setState({ addEditField: true });
    this.LeadershipPositionsServices.getCouncilDetails(pagination).then(
      (response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          // Concatenating firstName and lastName

          let councilDetails = response.responseItem.responseContent.itemList;
          councilDetails.map((council) => ({
            companyURL: council.companyURL,
            councilDetailsId: council.councilDetailsId,
            name: council.name,
            positionId: council.positionId,
            designation: council.designation,
          }));

          this.setState({
            getCouncilDetailsList: councilDetails,
            totalResultes: response.responseItem.responseContent.totalCount,
          });
          this.setState({
            length: response.responseItem.responseContent.totalCount + 1,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      }
    );
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    if (this.onPageChange) {
      this.onPageChange();
    }
    this.getCouncilDetails();
  };
  actions = (element, index, value) => {
    return element !== "CouncilDetailsId" ? null : (
      <td>
        <ActionContainer>
          {/* <ActionToggleButton
            className="mr-2"
            index = {index}
            /> */}

          {/* <ActionToggleButton
            className="mr-2"
            index={index}
            // toggle={this.state.toggle}
            data={this.state.getCouncilDetails}
            // onToggleChage={this.onToggleChage.bind(this)}
            onToggleChage=""
          /> */}

          <ActionEditButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onEdit}
            text="Edit"
          />
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        </ActionContainer>
      </td>
    );
  };

  componentDidMount() {
    // if (!this.onPageChange) this.onPageChange = this.getCouncilDetails;
    this.getCouncilDetails();
  }

  getCouncilDetailsById = (id) => {
    this.resetCouncilDetailsData();
    this.setState({ addEditField: true });
    this.LeadershipPositionsServices.getCouncilDetailsById(id).then(
      (response) => {
        
        if (response.statusCode === 200 && response.responseItem != null) {
          const councilDetails = response.responseItem.responseContent;
          this.setState({ councilMemberDetail: councilDetails });
        } else {
          console.error("Error response:", response.message);
          this.swalServices.Error(response.message);
        }
      }
    );
  };

  handleChangeForPositionId(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.councilMemberDetail;
    if (name === "positionId") {
      let chcklength = this.state.length - 1;
      if (value > chcklength) {
        detail[name] = parseInt(chcklength);
      } else {
        detail[name] = parseInt(value);
      }
    } else {
      detail[name] = value;
    }
    this.setState({ councilMemberDetail: detail });
  }

  // public long CouncilDetailsId { get; set; }
  // public string? Name { get; set; }
  // public string? Designation { get; set; }
  // public string? CompanyURL { get; set; }
  // public string? ImageUrl { get; set; }
  // public string? Description { get; set; }
  // //public int? PositionId { get; set; }
  // public string? Linkedin { get; set; }
  // public int? CreatedBy { get; set; }
  // public int? CreatedAppId { get; set; }


  updateCouncilDetails = (councilMemberId) => {
    
    this.setState({addEditField : false})
    let request = {...this.state.councilMemberDetail};

    request.councilDetailsId =  councilMemberId;
    request.name =  this.state.councilMemberDetail.name;
    request.designation =  this.state.councilMemberDetail.designation;
    request.companyURL =  this.state.councilMemberDetail.companyURL;
    request.imageUrl =  this.state.councilMemberDetail.imageUrl;
    request.description =  this.state.councilMemberDetail.description;
    request.positionId =  this.state.councilMemberDetail.positionId;
    request.linkedin =  this.state.councilMemberDetail.linkedin;
    request.updatedBy =  1;
    request.updatedAppId =  114;

    this.LeadershipPositionsServices.updateCouncilDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
            this.swalServices.Success("Council Details inserted successfully.");
            this.getCouncilDetails();
        } else if (response.responseItem.responseContent.hasError === true && response.responseItem.responseContent.errorType === "EXISTS") {
            this.swalServices.Error(" " + response.responseItem.responseContent.errorMessage);
        }
        else {
            this.swalServices.Error(response.message);
        }
        this.setState({ addEditField: false });

    })
}



resetCouncilDetailsData() {
  let details = { ...this.state.councilMemberDetail };
  details.councilDetailsId = 0;
  details.name = "";
  details.designation = "";
  details.companyURL = "";
  details.imageUrl = "";
  details.description = "";
  details.positionId = 0;
  details.linkedin = "";
  details.updatedBy = 0;
  this.setState({ councilMemberDetail: details });
}


cancelAddEditField() {
  this.setState({ addEditField: false });
  this.resetCouncilDetailsData();
}

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="flex">
          <div className="relative flex flex-col flex-1 ">
            <main className="pb-10">
              <div className="custom-card shadow-lg mb-10">
                <div className="filter-info grid grid-cols-12 gap-6">
                  <div className="filter-types xl:col-span-4">
                    <label
                      className="filter-lable block font-medium mb-3 theme-color min-h-30"
                      htmlFor="default"
                    >
                      Search by Name
                    </label>
                    <input
                      id="default"
                      className="form-input rounded-none w-full shadow-red py-3 text-lg"
                      type="text"
                      onChange={(e) => this.handleChangeEvent(e.target.value)}
                    />
                  </div>
                  {/* <div className='filter-types xl:col-span-4'>
                    <label className="filter-lable block font-medium mb-3 theme-color min-h-30" htmlFor="default">Category</label>
                    <DropdownSelect
                      value={this.state.SearchBySGTechDesignation} />
                  </div> */}
                  {/* <div className='filter-types xl:col-span-4'>
                    <label className="block text-xl font-medium  mb-3 theme-color min-h-30" htmlFor="default">Status</label>
                    <DropdownSelect
                      drpIdentity={"Statuses"}
                      optionArray={this.state.status}
                      setFilterParameters={this.setFilterParameters.bind(this)}
                      value={this.state.SearchByLeadershipStatus} />
                  </div> */}
                </div>
              </div>
              <div className="w-full mx-auto">
                <div className="sm:flex sm:justify-between sm:items-center mb-8">
                  <div className="mb-4 sm:mb-0">
                    <h1 className="table-title-txt theme-color font-bold">
                      {this.state.totalResultes} Positions
                    </h1>
                  </div>
                  {/* <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
                    <Link to='/AddEditLeadershipPosition' >
                      <button className="btn btn-red text-white" >
                        <span className="text-xl text-white font-semibold tracking-normal"> Create New </span>
                      </button></Link>
                  </div> */}
                  <CreateButton
                    redirect="/addEditCouncilDetails"
                    text="Create New"
                  />
                </div>
                <div className="relative">
                  <div>
                    {/* Table */}
                    {/* <div className="">
                      <Table columns={[{ name: 'Name', title: 'Full Name' }, { name: 'Designation', title: 'Designation' }, { name: 'CompanyURL', title: 'Company' }, { name: 'positionId', title: 'Position' }, { name: 'CouncilDetailsId', title: 'Action' }]}
                        rows={this.state.getCouncilDetails}
                        isLoading={this.state.isLoading}
                        sortingColumns={["Name"]}
                        pagination={this.state.pagination}
                        totalResultes={this.state.totalResultes}
                        totalCount={this.state.totalResultes}
                        setPagination={this.setPagination.bind(this)}
                        customScope={[
                          {
                            column: 'CouncilDetailsId',
                            renderTableData: this.actions.bind(this)
                          },
                        ]} />
                    </div> */}
                    <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200">
                      <thead className="table-bg text-xs font-semibold capitalize text-white border-t border-b border-slate-200">
                        <tr>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                            <div className="font-bold tble-th-txt text-left">
                              Full Name
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                            <div className="font-bold tble-th-txt text-left">
                              Designation
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                            <div className="font-bold tble-th-txt text-left">
                              Company
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                            <div className="font-bold tble-th-txt text-left">
                              Position
                            </div>
                          </th>
                          <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                            <div className="font-bold tble-th-txt text-left">
                              Action
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm custom-tbody">
                        {(this.state.getCouncilDetailsList &&
                          this.state.getCouncilDetailsList.length === 0) ||
                        this.state.getCouncilDetailsList.length === null ? (
                          <tr className="">
                            <td
                              className="w-full text-lg text-center py-4 text-[#757575] "
                              colSpan="100%"
                            >
                              No Records Found
                            </td>
                          </tr>
                        ) : (
                          this.state.getCouncilDetailsList.map(
                            (dropdown, key) => (
                              <tr key={key}>
                                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                  <div className="flex flex-col">
                                    <div className="current-text">
                                      {dropdown.name}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                  <div className="flex flex-col">
                                    <div className="current-text">
                                      {dropdown.designation}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                  <div className="flex flex-col">
                                    <div className="current-text">
                                      {dropdown.companyURL}
                                    </div>
                                  </div>
                                </td>
                                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                  <div className="flex flex-col">
                                    {this.state.addEditField === true &&
                                    this.state.valueOrdering ===
                                      dropdown.positionId &&
                                    this.state.dropdownId ===
                                      dropdown.councilDetailsId ? (
                                      <div
                                        key={key}
                                        className="flex flex-col relative"
                                      >
                                        <div
                                          className={`edit-field input-height-set center-text`}
                                        >
                                          <TextInput
                                            placeholder=""
                                            type="number"
                                            name="positionId"
                                            max={this.state.length - 1}
                                            value={
                                              this.state.councilMemberDetail
                                                ?.positionId === 0
                                                ? 0
                                                : this.state.councilMemberDetail
                                                    ?.positionId
                                            }
                                            handleChange={(event) =>
                                              this.handleChangeForPositionId(
                                                event
                                              )
                                            }
                                            identity="DropdownValueOrdering"
                                            min={0}
                                          />
                                        </div>
                                        <div className="absolute bottom-[-22px]"></div>
                                      </div>
                                    ) : (
                                      <div className="flex items-center gap-3">
                                        <div className="current-text text-center">
                                          {dropdown.positionId}
                                        </div>
                                        <button
                                          className="action-btn mr-4 flex items-center"
                                          aria-controls="add-edit-modal"
                                          onClick={() => {
                                            this.setState(
                                              {
                                                dropdownId:
                                                  dropdown.councilDetailsId,
                                                valueOrdering:
                                                  dropdown.positionId,
                                              },
                                              () => {
                                                this.getCouncilDetailsById(
                                                  dropdown.councilDetailsId
                                                );
                                              }
                                            );
                                          }}
                                        >
                                          <p className="ml-2 tool-tip-txt underline text-[#757575]">
                                            <svg
                                              className="menu-svg"
                                              width="24"
                                              height="24"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 88 88"
                                            >
                                              <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
                                                <path
                                                  d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z"
                                                  fill="#6e6e6e"
                                                />
                                              </g>
                                            </svg>
                                          </p>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </td>

                                <td className="px-3 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt pointer text-[#757575] ">
                                  {this.state.addEditField === true &&
                                  this.state.dropdownId ===
                                    dropdown.councilDetailsId ? (
                                    <div className="flex items-center justify-center edit-field">
                                      <button
                                        className="action-btn mr-4 flex items-center"
                                        aria-controls="add-edit-modal"
                                        onClick={() => {
                                          this.updateCouncilDetails(
                                            dropdown.councilDetailsId
                                          );
                                        }}
                                      >
                                        <p className="ml-2 tool-tip-txt text-[#c00000] underline cursor-pointer">
                                          Save
                                        </p>
                                      </button>
                                      <button
                                        className="action-btn mr-4 flex items-center pr-5 min-w-[60px]"
                                        aria-controls="add-edit-modal"
                                        onClick={() => {
                                          this.cancelAddEditField();
                                        }}
                                      >
                                      
                                        <img
                                          src="/cancel-icon.png"
                                          className="w-[22px] mx-auto my-0"
                                          alt=""
                                        ></img>
                                        
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-center">
                                      <button
                                        className="action-btn right-icon"
                                        onClick={() =>
                                          this.onDelete(
                                            dropdown.councilDetailsId
                                          )
                                        }
                                      >
                                        <DeleteButtonIcon />
                                      </button>

                                      <button
                                        className="ml-2"
                                        onClick={() => {
                                          this.setState({
                                            redirect:
                                              "/AddEditCouncilDetails/councilDetailsId?=" +
                                              encryptAES(
                                                dropdown.councilDetailsId
                                              ),
                                          });
                                        }}
                                      >
                                        <p>Edit</p>
                                      </button>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}
