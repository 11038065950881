import moment from "moment";
import React from "react";
import TextInput from "../../../components/UIComponent/TextInput";
import { APIURL } from "../../../services/axiosServices/ApiEndPoints";
import DatePicker from "../../datePicker/DatePicker";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";


function MTOInformation(props) {
  return (
    <div>
      <div className="w-full">
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
          <div className="grid grid-cols-12 gap-6 w-full ">
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Insurance Company Name
                    <span className="text-[#c00000]">*</span>
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="insuranceCompanyName"
                    value={props.mtoInformation.insuranceCompanyName}
                    identity="MTOInformation"
                    handleChange={props.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Policy Number<span className="text-[#c00000]">*</span>
                  </h2>
                  <TextInput
                    placeholder=""
                    type="text"
                    name="policyNumber"
                    value={props.mtoInformation.policyNumber}
                    identity="MTOInformation"
                    handleChange={props.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Expiry Date<span className="text-[#c00000]">*</span>
                  </h2>
                  <DatePicker
                    name="expiryDate"
                    handleChange={props.handleChange}
                    identity="MTOInformation"
                    value={moment(props.mtoInformation.expiryDate).format(
                      "yyyy-MM-DD"
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#757575] font-bold 2xl:mb-1">
                    Copy of Liability Insurance Policy* (Please Upload in PDF
                    Format Only) <span className="text-[#c00000]">*</span>
                  </h2>
                  <div className="flex items-center">
                    <div className="w-full outline-none appearance-none">
                      <form className="custom-uploader custom-file-upload-btn flex">
                        <div className="btn btn-red border-[#f3f3f3] btn btn-blue cursor-pointer">
                          <label
                            htmlFor={`upload_File_MTO`}
                            className="text-uplod block lg:text-[18px] text-[16px] text-[#ffffff]"
                          >
                            Upload PDF File
                          </label>
                          <input
                            className="sr-only"
                            id={`upload_File_MTO`}
                            type="file"
                            onChange={(event) => props.onFileChangePDFForMTO(event)}
                          />
                        </div>
                      </form>

                      {props.mtoInformation.insurancePolicyFilePath ? (
                          <div className="mt-5 file-uploaded-txt flex items-center">
                            <img
                              src="/checked-icon.svg"
                              className="w-[25px] mr-3"
                              alt=""
                            />
                            <span className="text-[#757575] big-bullet-points font-bold">
                              {props.mtoInformation.insurancePolicyFilePath}
                            </span>


                            <button
                              className="2xl:px-7 lg:px-3 2xl:py-2 lg:py-0 w-50 px-7 py-3 text-xl text-[#C00000]"
                              onClick={() => window.open(APIURL + "Common/GetPDF?type=INSURANCE&&fileName=" + props.mtoInformation.insurancePolicyFilePath, '_blank')}

                            >
                              <span className="text-xl text-[#c00000] font-semibold tracking-normal underline">
                              View Copy of Liability Insurance Policy                              </span>
                            </button>
                            <button
                              className="underline text-[#e74c3c] font-[500] ml-3 min-w-[100px]"
                            >
                              <img
                                src="/delete-icon.png"
                                className="w-[25px] mr-3"
                                alt=""
                              />
                            </button>
                          </div>
                        ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Save Button */}
        <div className="grid grid-cols-12 gap-6 pb-10">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
            <a
              className="flex items-center justify-end"
            >
              {
                props && props.isMTOInformationLoading === true ?
                  (
                    <ButtonLoader className="buttonload group relative rounded-md btn btn-red text-xl text-white focus:outline-none" />
                  )
                  :
                  (
                    <button
                      className="w-32 px-7 py-3 btn-sm btn-red text-xl text-white font-semibold"
                      onClick={() => props.onSave()}
                    >
                      Save
                    </button>
                  )
              }
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MTOInformation;
