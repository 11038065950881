import React, { Component } from "react";
import SwalServices from "../../../services/swalServices/SwalServices";
import ValidationText from "../../../utils/validation/ValidationText";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import CKClassicEditor from "../../../components/ckEditor/CKClassicEditor";
import { checkParameters, decryptAES } from "../../../utils/Encryption";
import { Navigate } from "react-router-dom";
import LeadershipPositionsServices from "../../../services/axiosServices/apiServices/LeadershipPositionsServices";
import { CommonValidationMessages } from "../../../utils/Messages";
import { isValidForm } from "../../../utils/validation/CommonValidator";

export default class AddEditSecretariatDetails extends Component {
  constructor(props) {
    super(props);
    this.LeadershipPositionsServices = new LeadershipPositionsServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      redirect: null,
      secretariatDetails: {
        secretariatDetailsId: 0,
        name: "",
        // lastName: "",
        designation: "",
        description: "",
        positionId: 0,
        linkedin: "",
        updatedBy: 0,
        updatedAppId: 0,
        createdBy: 0,
        createdAppId: 0,
      },
      validationRule: {
        name: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'name'),
          },
        ],
        designation: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'designation')
          }
        ],
        positionId: [
          {
            type: 'require',
            message: CommonValidationMessages.FieldRequired.replace('{0}', 'positionId')
          }
        ],
        // linkedin: [
        //   {
        //     type: 'require',
        //     message: CommonValidationMessages.FieldRequired.replace('{0}', ' linkedin')
        //   }
        // ],
      },
      validState: {
        isValid: true,
        error: {}
      },
    }
  }

  isValidateAllFields = () => {
    if(this.state.secretariatDetails.secretariatDetailsId  === 0){
      delete this.state.validationRule.positionId;
    }
    const newValidState = isValidForm(this.state.secretariatDetails, this.state.validationRule, this.state.validState);
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  }

  addNew = (value) => {
    let detail = this.state.secretariatDetails;
    if (this.isValidateAllFields()) {
      detail.createdBy = 115;
      detail.createdAppId = 15;
      this.setState({ secretariatDetails: { ...detail } });
      this.addSecretariatDetails();
      this.setState({ redirect: "/ManageSecretariatDetails" });
    }
  }

  addSecretariatDetails = () => {
    this.LeadershipPositionsServices.addSecretariatDetails(this.state.secretariatDetails).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
        this.swalServices.Success("Secretariat Details inserted successfully.");
      } else if (response.responseItem.responseContent.hasError === true && response.responseItem.responseContent.errorType === "Arrangement") {
        this.swalServices.Error(" " + response.responseItem.responseContent.errorMessage);
      }
    });
  };

  updateSecretariatDetails = () => {
    this.LeadershipPositionsServices.updateSecretariatDetails(this.state.secretariatDetails).then((response) => {

      if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.hasError !== true) {
        this.swalServices.Success("Secretariat Details updated successfully");
      } else if (response.responseItem.responseContent.hasError === true && response.responseItem.responseContent.errorType === "Duplicate Arrangement") {
        this.swalServices.Error(" " + response.responseItem.responseContent.errorMessage);
      }
      else {
        this.swalServices.Error(response.message);
      }

    })
  }

  getSecretariatDetailsById = (secretariatDetailsId) => {
    this.LeadershipPositionsServices.getSecretariatDetailsById(secretariatDetailsId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (response.responseItem.responseContent != null) {
          this.setState({
            secretariatDetails: response.responseItem.responseContent,
          });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    })
  }

  handleChange(event) {
    let value, name;
    if (typeof event === 'string') {
      value = event;
      name = 'description';
    } else if (event.target) {
      value = event.target.value;
      name = event.target.name;
    } else {
      return;
    }

    let detail = this.state.secretariatDetails;
    detail[name] = value;
    this.setState({ secretariatDetails: { ...detail } });
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let secretariatDetailsId = params.get("");
    if (secretariatDetailsId && (secretariatDetailsId = checkParameters(secretariatDetailsId, "Number"))) {
      if (secretariatDetailsId != null && secretariatDetailsId > 0) {
        this.getSecretariatDetailsById(secretariatDetailsId);
      }
    }
  }

  save = (value) => {
    let detail = this.state.secretariatDetails;
    if (this.isValidateAllFields()) {
    detail.updatedBy = 115;
    detail.updatedAppId = 15
    this.setState({ secretariatDetails: { ...detail } });
    this.updateSecretariatDetails();
    this.setState({ redirect: "/ManageSecretariatDetails" });
  }
}

  back = () => {
    this.setState({ redirect: "/ManageSecretariatDetails" });
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div className="main-body-section">
        <div className="bg-white">
          <div className="heading-part px-7 py-4 border-b flex items-center justify-between">
            <h4 className="small-title font-bold theme-color">
              Secretariat Member Details
            </h4>
          </div>
          <div className="px-7 py-4 pt-10">
            <div className="mb-16">
              <div className="grid grid-cols-12 gap-6">
                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 mb-2 input-sec-texts">
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="title"
                        >
                          Name <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="name"
                          className="form-input rounded-none w-full mb-4 shadow-red py-3 text-lg"
                          name="name"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.secretariatDetails.name}
                        />
                        <div>
                          <ValidationText
                            error={this.state.validState.error.name}
                          />
                        </div>
                      </div>
                      {/* <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="title"
                        >
                          Last Name <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="lastName"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="lastName"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.secretariatDetails.lastName}
                        />
                        <div>
                            <ValidationText
                              error={this.state.validState.error.lastName}
                            />
                          </div>
                      </div> */}
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="title"
                        >
                          Designation <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="designation"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="designation"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.secretariatDetails.designation}
                        />
                        <div>
                          <ValidationText
                            error={this.state.validState.error.designation}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="col-span-12 mb-2 input-sec-texts" style={{ paddingLeft: 0, paddingRight: 40 }}>
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="description"
                        >
                            Description
                        </label>
                        <div className="ck-editor-sec">
                          <CKClassicEditor
                            key={"description"}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              this.handleChange(data);
                            }}
                            data={this.state.secretariatDetails.description}
                            // onBlur={(event, editor) => {
                            //   this.validateField("description");
                            // }}
                          />
                          <div>
                            {/* <ValidationText
                              error={this.state.validState.error.description}
                            /> */}
                          </div>
                        </div>
                      </div>

                    </div>

                    {this.state.secretariatDetails.secretariatDetailsId > 0 && (
                    <div className="col-span-12 mb-2 input-sec-texts" style={{ paddingLeft: 0, paddingRight: 40 }}>
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="positionId"
                        >
                          Arrangement Position <span className="text-[#C00000]">*</span>
                        </label>
                        <input
                          id="positionId"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="positionId"
                          type="number"
                          onChange={(e) => this.handleChange(e)}
                          value={
                            this.state.secretariatDetails.positionId
                          }
                        />
                        <div>
                          <ValidationText
                            error={this.state.validState.error.positionId}
                          />
                        </div>
                      </div>
                      
                    </div>
                    )}
                    <div className="col-span-12 mb-2 input-sec-texts" style={{ paddingLeft: 0, paddingRight: 40 }}>
                      <div className="filter-types">
                        <label
                          className="filter-lable block font-medium mb-3 theme-color min-h-30"
                          htmlFor="title"
                        >
                          Linkedin
                        </label>
                        <input
                          id="linkedin"
                          className="form-input rounded-none w-full shadow-red py-3 text-lg"
                          name="linkedin"
                          type="text"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.secretariatDetails.linkedin ? this.state.secretariatDetails.linkedin : ""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-between space-x-10 mt-12">
          <div>
            <button className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white" onClick={() => this.back()}>
              Back
            </button>
          </div>
          <div>
            {this.state.secretariatDetails.secretariatDetailsId ?
              (
                <div>
                  <button
                    className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                    onClick={() => this.save(5)}>
                    Save
                  </button>
                </div>
              )
              :
              (
                <div>
                  <button
                    className="w-32 px-7 mr-16 py-2 btn-sm btn-lighblue text-lg text-white"
                    onClick={() => this.addNew(5)}>
                    AddNew
                  </button>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

